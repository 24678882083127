import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Table } from 'reactstrap'

import { Title2, Title3 } from '../../components/Font'

import { toCurrency } from '../../libs/currency'
import { formatDateTime } from '../../libs/date'

import { getCronjobDetail } from '../../redux/actions/action_jobs'
import JobStatus from './JobStatus'

class CronJobsTable extends React.Component {

  goToJobs(event) {
    let id = event.currentTarget.dataset.id
    this.props.history.push(`${this.props.customRoute ? this.props.customRoute : '/jobs'}/${id}`)
  }

  getPrivilegeListName = (id) => {
    let { store_admin: { privilegeList } } = this.props
    let index = privilegeList && privilegeList.findIndex(index => index.user_id === id)
    let name = (index > -1 && privilegeList && privilegeList[index])
      ? Object.assign({}, {
        display_name: privilegeList[index].display_name,
        fullname: privilegeList[index].fullname,
        first_name: privilegeList[index].fullname && privilegeList[index].fullname.split(' ')[0],
        last_name: privilegeList[index].fullname && privilegeList[index].fullname.split(' ')[1],
      })
      : { id: id }
    return name
  }

  render() {
    let { store_jobs, store_language: { dictionary } } = this.props
    return (
      <Table striped responsive>
        <thead id='cronjob-header'>
          <tr>
            <th width="20%" className="text-center">
              <Title2 bold secondary>{dictionary.job_id}</Title2>
            </th>
            <th width="40%">
              <Title2 bold secondary>{dictionary.last_modified}</Title2>
            </th>
            <th width="20%" className="text-center d-none d-md-table-cell">
              <Title2 bold secondary>{dictionary.status}</Title2>
            </th>
            <th width="20%" className="text-center">
              <Title2 bold secondary>{dictionary.record}</Title2>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            store_jobs.cronjobs.length > 0
            && store_jobs.cronjobs.map((val, key) => {
              let name = this.getPrivilegeListName(val.modified_by)
              return <tr key={key} data-id={val.id} style={{ cursor: 'pointer' }} onClick={this.goToJobs.bind(this)}>
                <td className="text-center" style={{ cursor: 'pointer' }} data-id={val.id} color="link" onClick={this.goToJobs.bind(this)} >
                  <Title2 bold link>{val.modified_id}</Title2>
                </td>
                <td>
                  <div className='d-block d-md-none d-flex' >
                    {
                      val.status !== 'Stop'
                        ? <JobStatus status={val.status} />
                        : '-'
                    }
                  </div>
                  <Title2 bold>
                    {name.display_name ? `${name.display_name} (${name.first_name})` : (name.first_name || name.id)}
                  </Title2>
                  <Title3 secondary> {dictionary.modified_on} {formatDateTime(val.modified_date)} </Title3>
                </td>
                <td className='text-center d-none d-md-table-cell'>
                  {
                    val.status !== 'Stop'
                      ? <JobStatus status={val.status} />
                      : '-'
                  }
                </td>
                <td className="text-center"> <Title2> {toCurrency(val.total)} </Title2> </td>
              </tr>
            })
          }
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs,
    store_language: state.language,
    store_admin: state.admin
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getCronjobDetail
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CronJobsTable))