import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { Title2, Title3 } from "../../../components/Font";
import { IconInfo } from "../../../components/CardHeaderFilter/CardHeaderFilter.style";
import SwitchToggle from "../../../components/SwitchToggle";
import { ToastBar } from "../../../components/ToastComponent/Toast.style";
import { DEBUG } from "../../../libs/utils";
import { updateAutomationMemberCardConfig } from "../../../redux/actions/action_business";
import { SwitchToggleBar } from "../../Settings/Settings.style";
import DemotionSetting from "./DemotionSetting";
import PromotionSetting from "./PromotionSetting";

const this_menu = 'automation-member-card'

export default function AutomationMembercardSetting({ business }) {
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state.language.dictionary);
  const permission = useSelector((state) => state.business.permission);
  const [isEnabled, setEnabled] = useState(business.member_card_automation);
  const [isLoading, setLoading] = useState(false);

  const goToManual = () => {
    window.open('https://manual.pointspot.co/automation/member-card')
  };

  const toggle = () => {
    setLoading(true);
    dispatch(updateAutomationMemberCardConfig(business.business_code, !isEnabled, (err, data) => {
      DEBUG("updateAutomationMemberCardConfig", err, data);
      setLoading(false);
      if (err) {
        toastShow('error', dictionary.error_occurred);
      } else {
        setEnabled(data.member_card_automation);
        toastShow('success', dictionary.member_card_automation_status_success);
      }
    }));
  };

  const toastShow = (type, message) => {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  return (
    <Container>
      <div className="my-3">
        <SwitchToggleBar>
          <div>
            <div className="d-flex align-items-baseline">
              <Title2 bold>{dictionary.member_card_automation_setting}</Title2>
              <IconInfo className="px-2 fas fa-info-circle" style={{ cursor: 'pointer' }} onClick={goToManual}></IconInfo>
            </div>
            <Title3 secondary>{dictionary.member_card_automation_setting_description}</Title3>
          </div>
          <div className="ml-2" style={{ minWidth: 46 }}>
            <SwitchToggle
              disabled={!permission[this_menu].permission_create}
              active={isEnabled}
              isLoading={isLoading}
              toggle={toggle}
              opacity={!permission[this_menu].permission_create ? 0.25 : 1}
            />
          </div>
        </SwitchToggleBar>
      </div>
      {
        isEnabled && <PromotionSetting dictionary={dictionary} permission={permission[this_menu].permission_create} business={business} toastShow={toastShow} />
      }
      {
        isEnabled && <DemotionSetting dictionary={dictionary} permission={permission[this_menu].permission_create} business={business} toastShow={toastShow} />
      }
    </Container>
  )
}