const TH = {
	locale: "th",
	language_thailand: "ภาษาไทย",
	language_english: "English",
	language_thai: "ภาษาไทย",
	language_eng: "ภาษาอังกฤษ",
	hello: "สวัสดีครับ!",
	continue_with_google: "เข้าสู่ระบบด้วย Google",
	continue_with_facebook: "เข้าสู่ระบบด้วย Facebook",
	signup: "สมัครสมาชิก",
	signup_business: "สร้างบัญชีร้านค้า/ธุรกิจบน Pointspot",
	signin: "เข้าสู่ระบบ",
	goto_admin: "เข้าสู่ระบบ",
	email: "อีเมล",
	password: "รหัสผ่าน",
	forget_password: "ลืมรหัสผ่าน",
	singin_with_readyplanet_account: "เข้าสู่ระบบด้วยบัญชี Readyplanet",
	email_or_password_is_incorrect: "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
	too_many_failed_login_attempts: "คุณลงชื่อเข้าใช้ผิดพลาดเกินจำนวนครั้งที่กำหนด กรุณาลองใหม่ภายหลัง",
	an_error_has_occurred: "พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง",
	name: "ชื่อ",
	f_name: "ชื่อ",
	first_name: "ชื่อจริง",
	last_name: "นามสกุล",
	surname: "นามสกุล",
	phone: "เบอร์โทรศัพท์",
	mobile_phone_number_for_receiving_otp: "เบอร์โทรศัพท์สำหรับรับ OTP",
	mobile_phone_number:"เบอร์โทรศัพท์",
	phone_number_not_found: "ไม่พบเบอร์โทรศัพท์",
	edit_phone_number_at_admin_menu: "กรุณาเพิ่มหรือแก้ไขเบอร์โทรศัพท์ที่เมนูผู้ดูแลระบบ",
	confirm_password: "ยืนยันรหัสผ่าน",
	confirm: "ยืนยัน",
	apply: "ใช้งาน",
	submit: "ยืนยัน",
	cancel: "ยกเลิก",
	cancelled: "ยกเลิกแล้ว",
	back: "ย้อนกลับ",
	reset_password: "รีเซ็ตรหัสผ่าน",
	set_password: "ตั้งค่ารหัสผ่าน",
	or: "หรือ",
	to: "ถึง",
	as: "เท่ากับ",
	email_incorrect: "อีเมลไม่ถูกต้อง",
	email_already_in_use: "อีเมลมีผู้ใช้แล้ว",
	weak_password: "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร",
	password_not_match: "รหัสผ่านไม่เหมือนกัน",
	logout: "ออกจากระบบ",
	profile: "โปรไฟล์",
	wallet_id: "Wallet ID",
	check_email_for_reset_password: "ลิ้งสำหรับการรีเซ็ตรหัสผ่านถูกส่งเข้าไปยัง",
	from: "จาก",
	no_results_found: "ไม่พบรายการ",
	no_user_found: "ไม่พบข้อมูลสมาชิก",
	wait: "รอ",
	second: "วินาที",
	seconds: "วินาที",
	minute: "นาที",
	minutes: "นาที",
	hour: "ชั่วโมง",
	hours: "ชั่วโมง",
	save: "บันทึก",
	dont_save: "ไม่บันทึก",
	delete: "ลบ",
	publish: "ลงประกาศ",
	unpublish: "ปิดประกาศ",
	edit: "แก้ไข",
	confirm_logout: "คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ ?",
	earn: "ได้รับ",
	search: "ค้นหา",
	close: "ปิด",
	support: "ช่วยเหลือ",
	feature_request: "ขอเพิ่มฟีเจอร์",
	goto_manual: "คู่มือใช้งานระบบ",
	announce_messages: "ข้อความประกาศ",
	change_language: "Change language",
	account_profile: "ข้อมูลส่วนตัว",
	edit_profile: "แก้ไขข้อมูลส่วนตัว",
	billing_payment: "การชำระเงิน",
	edit_password: "แก้ไขรหัสผ่าน",
	select_photo: "เลือกรูปภาพ",
	remove_image: "ลบรูปภาพ",
	crop: "ตัด",
	hide: "ซ่อน",
	show: "แสดง",
	copy: "คัดลอก",
	copied: "คัดลอกแล้ว",
	show_more: "แสดงเพิ่มเติม",
	more: "เพิ่มเติม",
	show_less: "แสดงน้อยลง",
	rewards: "ของรางวัล",
	expired: "หมดอายุ",
	type: "ประเภท",
	active: "ใช้งานอยู่",
	inactive: "ไม่ได้ใช้งาน",
	received: "ได้รับแล้ว",
	completed: "เสร็จสมบูรณ์",
	and: "และ",
	items: "ครั้ง",
	day: "วัน",
	notification: "แจ้งเตือน",
	by: "โดย",
	create: "สร้าง",
	times: "ครั้ง",
	anouncement_date: "ประกาศ ณ วันที่",
	data_not_found: "ไม่พบข้อมูล",
	number: "จำนวน",
	data_range: "ช่วงข้อมูล",
	percent_of_data_all: "เปอร์เซ็นต์ของข้อมูลทั้งหมด",
	saved: "บันทึกข้อมูลเรียบร้อย",
	created_successfully: "สร้างข้อมูลเรียบร้อย",
	saved_successfully: "บันทึกข้อมูลเรียบร้อย",
	deleted_successfully: "ลบข้อมูลเรียบร้อย",
	sent_points_successfully: "ส่งพอยท์เรียบร้อยแล้ว",
	pulled_points_successfully: "ดึงพอยท์เรียบร้อยแล้ว",
	archived_successfully: "ลบธุรกิจเรียบร้อยแล้ว",
	month: "เดือน",
	year: "ปี",
	caution: "คำเตือน",
	terms_conditions: "เงื่อนไขการใช้บริการ",
	accept_terms: "การล็อคอินเข้าสู่ระบบนี้ ท่านยอมรับ",
	accept_terms_conditions: "ยอมรับเงื่อนไขการใช้บริการ",
	accept_terms_create: "การสร้างบัญชีผู้ใช้นี้ ท่านยอมรับ",
	privacy_policy: "นโยบายข้อมูลส่วนบุคคล",
	thb: "บาท",
	accept: "ยอมรับ",
	skip: "ข้าม",
	next: "ถัดไป",
	recommend: "แนะนำ",
	terms_payment: "เงื่อนไขการชำระเงิน",
	terms_payment_list: [
		{
			title: "1. รูปแบบการชำระเงิน",
			description:
				"การชำระเงินค่าบริการของ Pointspot จะชำระผ่านบัตรเครดิตในระบบออนไลน์ โดยระบบจะสรุปยอดค่าใช้จ่ายในแต่ละเดือนในวันสุดท้ายของเดือน และทำการตัดชำระเงินจากบัตรที่ได้ตั้งค่าไว้ในระบบ ซึ่งกรณี ระบบไม่สามารถตัดชำระเงินผ่านบัตรได้ ด้วยเหตุผลต่างๆ เช่นบัตรหมดอายุ หรือ วงเงินในบัตรไม่พอ อาจส่งผลทำให้ไม่สามารถใช้บริการได้ จนกว่าจะมีการชำระเงินคงค้างนั้น",
		},
		{
			title: "2. บริษัทผู้ให้บริการระบบ Pointspot",
			description:
				"บริษัทที่เป็นผู้ให้บริการ Pointspot คือ บริษัท เรดดี้แพลนเน็ต จำกัด ซึ่งจดทะเบียนและตั้งอยู่ที่ เลขที่ 51 อาคารเมเจอร์ ทาวเวอร์ พระราม 9 - รามคำแหง ชั้น 17 ห้อง 1701 - 1706 ถนนพระราม 9 แขวงหัวหมาก เขตบางกะปิ กรุงเทพมหานคร 10240 ราคาค่าบริการที่คิดเป็นราคาที่รวมภาษีมูลค่าเพิ่มแล้ว",
		},
		{
			title: "3. ระบบรักษาความปลอดภัยของการชำระเงิน",
			description:
				"Pointspot ใช้ระบบชำระเงินออนไลน์จากบริษัท 2c2p ซึ่งเป็นผู้ให้บริการด้านรับชำระเงินออนไลน์ระดับโลก โดยข้อมูลหมายเลขบัตรเครดิตไม่ได้เก็บรักษาไว้ที่ในระบบ Pointspot แต่เก็บรักษาไว้ที่บริษัทผู้ให้บริการชำระเงิน เพื่อเหตุผลด้านความปลอดภัย",
		},
	],
	not_do_transaction: "ไม่สามารถทำรายการได้",
	not_do_transaction_payment: "ชำระเงินไม่สำเร็จ ไม่สามารถทำรายการได้",
	all: "ทั้งหมด",
	valid: "ตั้งแต่",
	select_all: "เลือกทั้งหมด",
	cancel_all: "ยกเลิกทั้งหมด",
	you_can_learn: "สามารถศึกษาวิธีการใช้งาน",
	get_from_here: "ได้จากที่นี่",
	dashboard: "แดชบอร์ด",
	in_business: "ของธุรกิจ",
	setting_menu: "เมนูตั้งค่า",
	filter: "ตัวกรอง",
	duration: "ระยะเวลา",
	refresh: "รีเฟรช",
	available: "พร้อมใช้",
	option: "ตัวเลือก",
	wait_check_info: "กำลังตรวจสอบข้อมูลกรุณารอสักครู่",
	updated_data: "อัปเดทข้อมูลเมื่อ",
	updating_data: "การอัปเดตข้อมูล",
	latest_update: "อัปเดตข้อมูลล่าสุด",
	update_period: "ช่วงเวลาอัปเดทข้อมูล",
	every_time: "ตลอดเวลา",
	every_hour: "รายชั่วโมง",
	every_day: "รายวัน",
	get_started: "เริ่มต้นใช้งาน",
	privilege: "สิทธิประโยชน์",
	enabled: "เปิดใช้งาน",
	i_agree: "ยอมรับเงี่อนไข",
	create_by: "สร้างโดย",
	edit_by: "แก้ไขโดย",
	message: "ข้อความ",
	notification_message: "ข้อความแจ้งเตือน",
	details: "รายละเอียด",
	details_more: "รายละเอียดเพิ่มเติม",
	not_specified: "ไม่ระบุ",
	created_on: "สร้างเมื่อ",
	order_by: "จัดเรียงตาม",
	choose: "เลือก",
	upgrade: "อัปเกรด",
	new: "ใหม่",
	need_verify: "เปิดใช้งานธุรกิจ",
	process_success: "การดำเนินการสำเร็จ",
	boardcast: "บอร์ดแคสต์",
	send_coupon: "ส่งคูปอง",
	can_not_scan_qr: "ไม่สามารถอ่านคิวอาร์โค้ตนี้ได้",
	detail_more_click: "รายละเอียดเพิ่มเติมคลิก",
	menu: "เมนู",
	member: "สมาชิก",
	view: "มุมมอง",
	by_product: "แบ่งตามสินค้า",
	by_member: "แบ่งตามสมาชิก",
	language: "ภาษา",
	exporting: "กำลังดำเนินการ...",
	exported: "ดำเนินการสำเร็จ",
	report_will_send_to_email:'รายงานจะถูกส่งไปยังอีเมลของคุณ',
	send_to_my_email: "ส่งไปที่อีเมลของฉัน",
	advance_setting: "ตั้งค่าขั้นสูง",
	addtional_setting: "ตั้งค่าเพิ่มเติม",
	date_of_birth: "วันเกิด",
	automation: "Automation",
	swipe_by_client: "Swipe by client",
	start_date: "วันเริ่มต้น",
	end_date: "วันสิ้นสุด",

	singin_with_pointspot_account: "เข้าสู่ระบบด้วยบัญชี Pointspot",
	firstname: "ชื่อจริง",
	lastname: "นามสกุล",
	contact_phone: "เบอร์โทรศัพท์ที่สามารถติดต่อได้",
	resend_email: "ส่งอีเมลอีกครั้ง",
	dont_receive_email: "ไม่ได้รับอีเมลใช่ไหม ?",
	please_verify_email: "กรุณายืนยันอีเมล",
	please_check_email: "โปรดตรวจสอบอีเมลของท่าน",
	please_check_email2: "และกดยืนยันจากอีเมลที่ทาง Pointspot ส่งให้ที่",
	require_email_reset: "รีเซ็ตรหัสผ่านสำหรับอีเมลนี้",
	check_email: "กรุณาตรวจสอบอีเมล",
	new_password: " รหัสผ่านใหม่",
	password_changed: "รหัสผ่านถูกเปลี่ยนแล้ว",
	signin_with_new: "ผู้ใช้สามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่",

	// Business Type List
	home_decoration: "บ้าน และอุปกรณ์ตกแต่ง",
	business_service: "บริการทางธุรกิจ",
	sport: "กีฬา",
	automotive: "ยานยนต์",
	catering_ceremonies: "บริการจัดเลี้ยง และงานพิธีต่างๆ",
	education: "การศึกษา",
	mom_child: "แม่ และเด็ก",
	entertainment: "บันเทิง นันทนาการ และงานอดิเรก",
	travel: "ท่องเที่ยว",
	industry: "อุตสาหกรรม",
	health_beauty: "สุขภาพ และความงาม",
	fashion: "แฟชั่น",
	foods: "อาหาร เครื่องดื่ม และวัตถุดิบ",
	technology: "เทคโนโลยี และไอที",
	construction: "ก่อสร้าง และวัสดุ",
	government_sector: "หน่วยงานราชการ และองค์กร",
	financial: "ธนาคาร และสถาบันการเงิน",
	shopping_mall: "ร้านขายของชำ และห้างสรรพสินค้า",
	energy: "พลังงาน",
	real_estate: "อสังหาริมทรัพย์",
	publications: "สื่อ และสิ่งพิมพ์",
	office: "อุปกรณ์ และเครื่องใช้สำนักงาน",
	agriculture: "การเกษตร",
	other: "อื่นๆ...",

	emergency_service: "บริการฉุกเฉิน",
	kitchen: "ครัว",
	sanitary_ware: "สุขภัณฑ์",
	floor_wall_ceiling: "พื้น ผนัง และเพดาน",
	home_equipment: "อุปกรณ์และเครื่องใช้ในบ้าน",
	electrical: "เครื่องใช้ไฟฟ้า",
	garden: "สวน และสนาม",
	repair_service: "ซ่อมแซม และบริการอื่นๆ",
	furniture: "เฟอร์นิเจอร์ และตกแต่ง",
	office_decoration: "ตกแต่งสำนักงาน",
	office_supplies: "วัสดุสำนักงาน",
	foreign_trade: "การค้าต่างประเทศ",
	consultant: "ที่ปรึกษา",
	paper_packaging: "ธุรกิจกระดาษ และบรรจุภัณฑ์",
	packaging_transportation: "บรรจุหีบห่อ และขนส่ง",
	management_service: "บริหาร การจัดการ และบริการ",
	accounting_tax: "บัญชี และภาษีอากร",
	advertising_social_media: "โฆษณา ประชาสัมพันธ์ สิ่งพิมพ์ และสื่อออนไลน์",
	sports_venue: "สถานที่เล่นกีฬาและฝึกสอน",
	sports_equipment: "อุปกรณ์กีฬา",
	buy_sell: "ซื้อ ขาย แลกเปลี่ยน และเช่า",
	tool_equipment: "เครื่องมือ อุปกรณ์ และอะไหล่",
	auto_accessories: "ประดับยนต์",
	car_wash: "ล้าง ขัด เคลือบ",
	garage: "ซ่อมแซม",
	insurance: "ประกันภัย และบริการอื่นๆ",
	vehicles: "ยานพาหนะ และบริการอื่นๆ",
	catering_socializing: "การจัดเลี้ยง และสังสรรค์",
	event_location: "การจัดงาน และสถานที่",
	charity_items: "ของใช้ในงานบุญ",
	funeral_items: "ของใช้ในงานศพ",
	wedding_items: "ของใช้ในงานหมั้น และงานวิวาห์",
	graduation_items: "ของใช้ในวันรับปริญญา",
	charity_engagement: "ทำบุญงานหมั้น และงานแต่งงาน",
	education_institutions: "การศึกษา และสถาบัน",
	educational_equipment: "อุปกรณ์การศึกษา",
	research_experiment: "ค้นคว้า ทดลอง และบริการอื่นๆ",
	mothers: "เริ่มเป็นคุณแม่",
	nursery: "สถานรับเลี้ยง",
	children_item: "อุปกรณ์ และของใช้สำหรับเด็ก และเด็กอ่อน",
	entertaining: "บันเทิง และเริงรมย์",
	home_entertainment: "ความบันเทิงในบ้าน",
	hobby: "งานอดิเรก",
	pets: "สัตว์เลี้ยง",
	travel_accommodation: "ท่องเที่ยว และที่พัก",
	traveling: "การเดินทาง",
	travel_supplies: "ของใช้ในการท่องเที่ยว และบริการอื่นๆ",
	industrial_appliances: "เครื่องจักร เครื่องมือ อุปกรณ์ และเครื่องใช้ทางอุตสาหกรรม",
	metal_business: "ธุรกิจโลหะ เหล็ก สแตนเลส อลูมิเนียม",
	land_factory: "ธุรกิจที่ดิน อาคาร โรงงาน และธุรกิจบริการ",
	consumer_goods: "ธุรกิจด้านสินค้าอุปโภค",
	plastic_industry: "ธุรกิจด้านอุตสาหกรรมพลาสติก",
	sercives: "ธุรกิจบริการ",
	doctor_treatment: "แพทย์ และการรักษา",
	medicine: "ยา อุปกรณ์ และเครื่องใช้ในการรักษา",
	beauty_salon: "เสริมสวย และศูนย์สุขภาพ",
	cosmetic: "ธุรกิจเครื่องสำอาง",
	clothing_jewelry: "ธุรกิจเสื้อผ้า เครื่องหนัง สิ่งทอ อัญมณี",
	entertainment_venue: "สถานบันเทิง",
	food_equipment_service: "อุปกรณ์ และบริการทางด้านอาหาร",
	food_beverage: "ธุรกิจด้านอาหาร เครื่องดื่ม เครื่องปรุง",
	food_beverage_service: "สถานที่ให้บริการด้านอาหาร และเครื่องดื่ม",
	communication_tech: "การสื่อสาร และเทคโนโลยี",
	it_equipment: "อุปกรณ์ไอที",
	internet: "อินเตอร์เน็ต",
	contractors_designer: "ผู้รับเหมา ออกแบบ และช่าง",
	construction_equipment: "วัสดุ อุปกรณ์ก่อสร้าง",
	foundation_organization: "มูลนิธิ องค์กร สมาคม",
	banking: "การเงินการธนาคาร",
	grocery_stores: "ร้านขายของชำ",
	department_stores: "ห้างสรรพสินค้า",
	energy_environment: "ธุรกิจด้านพลังงาน และสิ่งแวดล้อม",
	accommodation: "ที่พักอาศัย",
	home_land: "ธุรกิจบ้าน และที่ดิน",
	service: "บริการ",
	factory_warehouse: "อาคาร โรงงาน คลังสินค้า",
	equipment_appliance: "อุปกรณ์ และเครื่องใช้",
	agribusiness: "ธุรกิจทางการเกษตร ประมง ปศุสัตว์ เหมืองแร่",

	// Sidebar Menu
	report_demographic_transactions: "รายการ",
	reports: "รายงาน",
	settings: "ตั้งค่า",
	points_transactions: "ประวัติการทำรายการ",
	history_transactions: "ประวัติ",
	points_send_points: "ส่งพอยท์",
	points_pull_points: "ดึงพอยท์",
	collect_point: "แลกรับพอยท์",
	points_transfer_points: "โอนพอยท์ให้เพื่อน",
	points_activate_ecoupon: "ใช้งานคูปอง/ของรางวัล",
	report_members: "สมาชิก",
	report_birthday: "เดือนเกิด",
	report_demographic: "ข้อมูลประชากร",
	report_transactions: "ประวัติรายการพอยท์",
	report_frequency: "ลูกค้าประจำ",
	setting_business: "ตั้งค่าธุรกิจ",
	setting_redeem_rewards: "ของรางวัลสะสมแต้ม",
	setting_terms_conditions: "เงื่อนไขการให้บริการ",
	setting_privacy_policy: "นโยบายข้อมูลส่วนบุคคล",
	setting_subscribe: "การรับข่าวสาร",
	setting_admin: "ผู้ดูแลระบบ",
	setting_credential: "API Credential",
	setting_line_liff: "LINE ล็อกอิน",
	setting_ecard_group_reward: "ของรางวัล",
	setting_ecard_group_coupon: "คูปอง",
	setting_additional_register: "แบบฟอร์มลงทะเบียน",
	pt: "พอยท์",

	//Alert Message
	cannot_completed_request: "ไม่สามารถทำตามที่ร้องขอได้\r\nกรุณาลองใหม่อีกคร้ง",

	// Point Board
	create_business: "สร้างธุรกิจ",
	limited_business: "จำกัดธุรกิจ",
	create_x_business: "สร้างธุรกิจ (สามารถสร้างได้ $1 ธุรกิจ)",
	limited_x_business: "จำกัดโควต้า (คุณเป็นเจ้าของ $1 ธุรกิจ)",
	points_summary: "สรุปพอยท์ประจำวัน",
	points_send: "ส่ง",
	points_pull: "ดึง",
	points_pay: "จ่าย",
	transfer_to_business: "โอนให้ธุรกิจ",
	points_redeem: "แลก",
	points_transfers: "โอน",
	pay_points: "จ่ายพอย์",
	receive_points: "ได้รับพอยท์",
	game_points: "เกมพอยท์",
	redeem_points: "แลกพอยท์",

	// Collect Point
	approved: "อนุมัติ",
	approve: "อนุมัติ",
	approver: "ผู้อนุมัติ",
	reject: "ปฏิเสธ",
	rejected_request: "ปฏิเสธ",
	pending_approval: "รออนุมัติ",
	back_comment: "กลับ",
	check: "ตรวจสอบ",
	check_collet: "ตรวจสอบการแลกรับพอยท์",
	total_amount_baht: "มูลค่าที่ชำระ (บาท)",
	amount_baht: "มูลค่า (บาท)",
	amount_no_baht: "มูลค่า",
	approve_point: "ดำเนินการส่งพอยท์ให้สมาชิก",
	reject_point: "ปฎิเสธการส่งพอยท์ให้สมาชิก",
	photo_attachment: "รูปภาพที่แนบมาด้วย",
	comment_reject_label: "กรุณาระบุเหตุผลในการปฏิเสธ",
	request_approve_successfully: "อนุมัติรายการแลกรับพอยท์แล้ว",
	request_reject_successfully: "ปฏิเสธรายการแลกรับพอยท์แล้ว",
	setting_request_points_type_on_off: "ตั้งค่าการเปิด / ปิด แลกรับพอยท์",
	setting_by_product_type_on_off: "เปิด / ปิด การระบุสินค้า",
	setting_by_product_description: "ต้องมีรายการสินค้าอย่างน้อย 1 ชิ้นเพื่อเปิดใช้งาน",
	setting_by_product_link: "ไปที่หน้าจัดการสินค้า",
	enable_setting_request_points: "เปิดการใช้งานแลกรับพอยท์",
	disable_setting_request_points: "ปิดการใช้งานแลกรับพอยท์",
	enable_setting_collect_points_by_product: "เปิดการใช้งานการระบุสินค้า",
	disable_setting_collect_points_by_product: "ปิดการใช้งานการระบุสินค้า",
	all_product: "สินค้าทั้งหมด",
	no_product: "ไม่มีสินค้า",
	collect_point_report: "รายงานแลกรับพอยท์",
	comment_reject: "ความคิดเห็น",
	admin_comment: "ความคิดเห็นของผู้ดูแลระบบ",
	member_comment: "ความคิดเห็นของสมาชิก",
	from_collect_points: "พอยท์จากแลกรับพอยท์",
	last_update: "อัพเดทล่าสุด",
	an_error_has_occurred2: "พบข้อผิดพลาด, รายการนี้ถูกแก้ไขไปโดย admin ท่านอื่นแล้ว",
	setting_notify_to_members: "แจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธไปยังสมาชิก",
	caution_setting_notify_to_members: "คำเตือน : การตั้งค่าแจ้งเตือน จะคิดค่าบริการการแจ้งเตือนเป็นจำนวนตามการใช้จริง",
	enable_setting_rejected_noti: "เปิดการใช้งานการแจ้งเตือน",
	disable_setting_rejected_noti: "ปิดการใช้งานการแจ้งเตือน",
	collect_point_noti: "แจ้งเตือนรายการแลกรับพอยท์",

	// Transactions
	transactions_history: "ประวัติการทำรายการ",
	transactions: "รายการ",
	basic_transactions: "Basic Transactions",
	promotional_transactions: "Promotional Transactions",
	no_transactions: "ไม่มีประวัติการทำรายการ",
	mobile_number: "เบอร์โทรศัพท์",
	mobile_no: "เบอร์โทรศัพท์",
	activity: "กิจกรรม",
	activity_detail: "รายละเอียดกิจกรรม",
	noactivity: "ไม่มีรายการ",
	points: "พอยท์",
	extra_points: "พอยท์พิเศษ",
	points_total: "พอยท์สะสม",
	action: "การกระทำ",
	actionBy: "ผู้กระทำ",
	send: "ส่ง",
	pull: "ดึง",
	redeem: "แลก",
	transfers: "โอน",
	transfers_points: "โอนพอยท์",
	receive_transfer: "รับโอน",
	receive: "ได้รับ",
	activate: "ใช้งาน",
	activated: "ใช้งานแล้ว",
	activate_code: "ใช้งานรหัส",
	activated_successfully: "ใช้งานเรียบร้อย",
	created: "สร้าง",
	bySystem: "ระบบ",
	byPersonal: "ส่วนบุคคล",
	can_not_activate_coupon: "ไม่สามารถใช้งานคูปองได้ แนะนำให้ลูกค้าสมัครสมาชิก",

	// Coupon
	search_ecoupon: "ค้นหาคูปอง",
	ecoupon_description: "กรุณาสแกน QR Code หรือกรอกรหัส ตัวอย่างรหัส : 12345678",
	personal_detail: "รายละเอียดสมาชิก",
	coupon_detail: "รายละเอียดคูปอง",
	reward_detail: "รายละเอียดของรางวัล",
	coupon_code: "รหัสคูปอง",
	coupon_status: "สถานะ",
	coupon_created: "สร้างเมื่อ",
	available: "พร้อมใช้งาน",
	unavailable: "ไม่พร้อมใช้งาน",
	ready: "พร้อมใช้งาน",
	not_ready: "ไม่พร้อมใช้งาน",
	expires_in: "หมดอายุในอีก",
	activated_date: "ใช้งานเมื่อ",
	confirm_activate_coupon: "กรุณายืนยันการใช้งานคูปอง",
	confirm_change_status_coupon: "คุณแน่ใจที่ต้องการจะเปลี่ยนสถานะคูปอง ?",
	show_redeem_code: "แสดงรหัส",
	redeem_code: "รหัสคูปอง",
	truly_verified: "กรุณาตรวจสอบข้อมูลให้ถูกต้อง",
	ecoupon_member: "คูปองสมาชิก",
	coupon_display_options: "ตัวเลือกการแสดงคูปอง",
	reward_display_options: "ตัวเลือกการแสดงของรางวัล",
	reward_display_options_info: [
		{ title: "ไม่แสดงของรางวัล", description: "เมื่อเลือกสมาชิกจะไม่เห็นของรางวัลนี้" },
		{
			title: "แสดงเฉพาะบัตรสมาชิก",
			description: "เมื่อเลือกมีเพียงสมาชิกที่ถือบัตรสมาชิกประเภทที่เลือกเท่านั้นที่สามารถเห็นของรางวัลนี้",
		},
		{
			title: "แสดงเฉพาะสมาชิกเหล่านี้",
			description:
				"เมื่อเลือกมีเพียงสมาชิกที่มีป้ายกำกับสมาชิกตรงกับที่เลือกอย่างน้อยหนึ่งป้ายกำกับเท่านั้นที่เห็นของรางวัลนี้",
		},
		{
			title: "ไม่แสดงต่อสมาชิกเหล่านี้",
			description: "เมื่อเลือกสมาชิกที่มีป้ายกำกับสมาชิกตรงกับที่เลือกอย่างน้อยหนึ่งป้ายกำกับจะไม่เห็นของรางวัลนี้",
		},
	],
	coupon_display_options_info: [
		{ title: "ไม่แสดงคูปอง", description: "เมื่อเลือกสมาชิกจะไม่เห็นคูปองนี้" },
		{
			title: "แสดงเฉพาะบัตรสมาชิก",
			description: "เมื่อเลือกมีเพียงสมาชิกที่ถือบัตรสมาชิกประเภทที่เลือกเท่านั้นที่สามารถเห็นคูปองนี้",
		},
		{
			title: "แสดงเฉพาะสมาชิกเหล่านี้",
			description:
				"เมื่อเลือกมีเพียงสมาชิกที่มีป้ายกำกับสมาชิกตรงกับที่เลือกอย่างน้อยหนึ่งป้ายกำกับเท่านั้นที่เห็นคูปองนี้",
		},
		{
			title: "ไม่แสดงต่อสมาชิกเหล่านี้",
			description: "เมื่อเลือกสมาชิกที่มีป้ายกำกับสมาชิกตรงกับที่เลือกอย่างน้อยหนึ่งป้ายกำกับจะไม่เห็นคูปองนี้",
		},
	],
	hide_visibility_coupon: "ไม่แสดงคูปอง",
	hide_visibility_reward: "ไม่แสดงของรางวัล",
	member_card_visibility: "แสดงเฉพาะบัตรสมาชิก",
	member_label_visibility: "แสดงเฉพาะสมาชิกเหล่านี้",
	member_label_visibility_hidden: "ไม่แสดงต่อสมาชิกเหล่านี้",
	show_for_action_value: "สมาชิกที่มีพอยท์",
	sorting: "เรียงลำดับ",
	sortToTop: "ย้ายไปบนสุด",
	sortUp: "ย้ายขึ้น",
	sortToBottom: "ย้ายไปล่างสุด",
	sortDown: "ย้ายลง",
	sortCancel: "ยกเลิกเรียบร้อย",
	sortingNotFound: "ไม่พบคูปอง",

	// Send Point and Pull Point
	initialization: "กำลังสร้างธุรกิจ กรุณารอสักครู่...",
	pointspot_id: "Pointspot ID",
	mobile: "เบอร์โทรศัพท์",
	user_duplicated: "ผู้ใช้ซ้ำ",
	user_invalid: "รูปแบบผู้ใช้ผิดพลาด",
	send_points_description: "กรุณากรอกเบอร์โทรศัพท์ หรือ Pointspot ID",
	please_insert_number: "กรุณากรอกเบอร์โทรศัพท์ หรือ Pointspot ID",
	new_member: "สมาชิกใหม่",
	non_member: "ยังไม่เป็นสมาชิก",
	send_points: "ส่งพอยท์",
	send_points_remain: "ส่งพอยท์ เหลือ",
	send_points_exchange_rate: "อัตราแปลงพอยท์เท่ากับ",
	For_example: "ตัวอย่าง",
	ex: "เช่น",
	example: "ตัวอย่าง",
	amount: "จำนวน",
	price: "ราคา",
	limit: "จำกัด",
	attachment: "แนบไฟล์",
	image_attachment: "แนบรูปภาพ",
	attach_img_or_coupon: "แนบรูปภาพ / คูปอง",
	receipt: "ใบเสร็จรับเงิน",
	photo: "รูปภาพ",
	image: "รูปภาพ",
	remove: "นำออก",
	remark: "หมายเหตุ",
	reference: "ข้อความอ้างอิง",
	campaign_name: "ชื่อแคมเปญ",
	receipt_number: "เลขที่ใบเสร็จรับเงิน",
	send_points_confirmation: "ยืนยันการส่งพอยท์",
	recent_transactions: "รายการล่าสุด",
	recent_transactions_of: "รายการล่าสุดของ",
	no_recent_transactions: "ไม่มีประวัติการทำรายการ",
	pull_points: "ดึงพอยท์",
	pull_points_from: "ดึงพอยท์จาก",
	point_not_enough: "พอยท์ไม่เพียงพอ",
	pull_points_confirmation: "ยืนยันการดึงพอยท์",
	tip_register: ` Tip : สำหรับลูกค้าใหม่ \n ควรแนะนำให้ลูกค้าสมัครสมาชิกของธุรกิจก่อนส่งพอยท์`,
	tip_register_gift_voucher: ` Tip : สำหรับลูกค้าใหม่ \n ควรแนะนำให้ลูกค้าสมัครสมาชิกของธุรกิจก่อนส่งบัตรกำนัล`,
	customer_subscribe_business: "ลูกค้ามีความประสงค์จะสมัครสมาชิกธุรกิจของคุณ",
	customer_unsubscribe_business: "สมาชิกเลิกติดตามธุรกิจ",
	sender_name_warning: "การส่งข้อความในช่วงทดลองใช้บริการ อาจส่งไม่ถึงในกรณีหมายเลขนั้นระงับการรับข้อความโฆษณา",
	member_will_not_receive_sms: "สมาชิกจะไม่ได้รับข้อความแจ้งเตือนได้รับพอยท์",
	tell_customer_register_receive_sms: "แนะนำให้ลูกค้าสมัครสมาชิก เพื่อที่จะได้รับข้อความแจ้งเตือน",
	credit_to_be_used: "เครดิตที่จะใช้งานในครั้งนี้",

	// Quick send points
	quick_send_points: "ส่งพอยท์ด่วน",
	send_points_detail: "รายละเอียดการส่งพอยท์",
	exchange_rate: "อัตราแลกเปลี่ยน",
	go_to_send_points: "ไปยังหน้าส่งพอยท์บนเดสก์ท็อป",

	//Report Dashboard
	dashboard_report: "Summary",
	dashboard_report_desc: "สรุปรวมรายงานทั้งหมด",
	dashboard_transaction: "ประวัติรายการ",
	dashboard_reviews: "สรุปความพึงพอใจ",
	dashboard_point: "พอยท์",
	dashboard_coupon: "คูปอง",
	dashboard_broadcast: "บรอดแคสต์",
	dashboard_member_card: "บัตรสมาชิก",
	dashboard_point_expiry_notification: "การแจ้งเตือนพอยท์หมดอายุล่วงหน้า",
	dashboard_collect_point_notification: "การแจ้งเตือนปฏิเสธการแลกรับพอยท์",
	dashboard_member_card_expiry_notification: "การแจ้งเตือนบัตรสมาชิกหมดอายุ",
	dashboard_members: "สมาชิก",
	dashboard_coupon_reward: "การใช้งานคูปองและของรางวัล",
	dashboard_total_transactions: "จำนวนการทำรายการ",
	dashboard_recent_review: "ความคิดเห็นล่าสุด",
	dashboard_promotional_coupon: "โปรโมชั่น",
	dashboard_welcome_coupon: "ต้อนรับ",
	dashboard_attached_coupon: "แนบท้าย",
	dashboard_giveaway_coupon: "แจกฟรี",
	dashboard_gift_voucher_coupon: "บัตรกำนัล",
	dashboard_reward: "ของรางวัล",
	dashboard_most_activated_coupon: "5 อันดับสูงสุด",
	dashboard_coupon_name: "ชื่อคูปอง",
	dashboard_total_activated: "จำนวนการใช้คูปอง",
	dashboard_total_members: "สมาชิกทั้งหมด",
	dashboard_new_members: "สมาชิกใหม่",
	dashboard_return_members: "สมาชิกเดิม",
	dashboard_active_members: "สมาชิกที่มีการใช้งาน",
	dashboard_not_found_coupons: "ไม่พบรายการใช้งานคูปอง",
	dashboard_not_found_review: "ไม่พบความคิดเห็น",
	dashboard_view_details: "ดูรายละเอียด",
	dashboard_activated: "ถูกใช้งาน",
	view_details_less: "ดูน้อยลง",
	view_member_details: "ดูข้อมูลสมาชิก",
	top_five_products: "5 อันดับสินค้าที่มีการใช้งานสูงสุด",
	view_product_details: "ดูข้อมูลสินค้า",
	product_transaction: "จำนวนรายการ",
	view_transaction_details: "ดูประวัติการทำรายการ",
	dashboard_create_product: "สร้างรายการสินค้า",
	dashboard_upgrade_product: "อัปเกรดเพื่อใช้การส่งพอยท์รายสินค้า",
	total_usage_transactions: "รวมรายการ",
	dashboard_no_data: "ไม่พบข้อมูล",
	dashboard_no_product: 'ยังไม่มีรายการสินค้า',
	dashboard_no_product_transaction: 'ไม่มีการใช้งานสินค้า',

	// Report Member
	members_report: "รายงานสมาชิก",
	members: "สมาชิก",
	members_infomation: "ข้อมูลสมาชิก",
	pending_members: "ผู้ใช้ที่รอสมัครสมาชิก",
	pending_member: "ผู้ใช้ที่รอสมัครสมาชิก",
	members_detail: "รายละเอียดสมาชิก",
	members_contact: "ติดต่อสมาชิก",
	subscribed: "สมัครสมาชิกเมื่อ",
	pending_members_description: "ลูกค้าที่ได้รับพอยท์หรือบัตรสมาชิก แต่ยังไม่ได้สมัครเป็นสมาชิกกับธุรกิจ",

	// Report birthday
	birthday_report: "รายงานเดือนเกิด",
	birthday_report_data: "ข้อมูลรายงานเดือนเกิด",
	January: "มกราคม",
	February: "กุมภาพันธ์",
	March: "มีนาคม",
	April: "เมษายน",
	May: "พฤษภาคม",
	June: "มิถุนายน",
	July: "กรกฏาคม",
	August: "สิงหาคม",
	September: "กันยายน",
	October: "ตุลาคม",
	November: "พฤศจิกายน",
	December: "ธันวาคม",
	date_of_birth_in: "สมาชิกที่เกิดในเดือน",
	select_date: "เลือกเดือนเกิด",
	export_to_xls: "ส่งออกข้อมูล (.xls)",
	export_to_mail: "ส่งออกข้อมูลไปยังอีเมล",
	export_member_name: "นำออกรายชื่อสมาชิก",
	data: "ข้อมูล",
	name_lastname: "ชื่อ นามสกุล",
	member_name: "ชื่อสมาชิก",
	age: "อายุ",
	subscribe_date: "วันที่สมัครสมาชิก",
	create_date: "วันที่สร้าง",
	business: "ธุรกิจ",
	data_not_found_in: "ไม่พบข้อมูลในเดือน",

	// Report Demographic
	demographic_report: "รายงานข้อมูลประชากร",
	report_demographic_description: "กลุ่มประชากร : เพศ (ชาย และ หญิง), ช่วงอายุ",
	male: "ชาย",
	female: "หญิง",
	unspecified: "ไม่ระบุ",
	independent_variables: "รายการ",
	percent: "เปอร์เซ็นต์",
	age_range: "ช่วงอายุ",
	total: "ทั้งหมด",
	gender: "เพศ",
	date: "วันที่",
	person: "คน",

	// Report Transactions
	transactions_report: "รายงานประวัติรายการพอยท์",
	activity_points_in_business: "ประวัติรายการพอยท์ของธุรกิจ",
	select_date_range: "เลือกช่วงเวลา",
	date_range: "ช่วงเวลา",
	date_on: "ตั้งแต่วันที่",
	data_not_found_on: "ไม่พบข้อมูลตั้งแต่วันที่",
	today: "วันนี้",
	yesterday: "เมื่อวานนี้",
	this_week: "สัปดาห์นี้",
	last_week: "สัปดาห์ที่แล้ว",
	this_month: "เดือนนี้",
	last_month: "เดือนที่แล้ว",
	last_30_days: "30 วันล่าสุด",
	this_quarter: "ไตรมาสนี้",
	last_quarter: "ไตรมาสที่แล้ว",
	last_3_month: "3 เดือนที่แล้ว",
	last_6_month: "6 เดือนที่แล้ว",
	last_12_month: "12 เดือนที่แล้ว",
	this_year: "ปีนี้",
	last_year: "ปีที่แล้ว",
	all_time: "ทั้งหมด",
	custom: "กำหนดเอง",
	past_1_month: "1 เดือนล่าสุด",
	past_3_month: "3 เดือนล่าสุด",
	past_6_month: "6 เดือนล่าสุด",
	past_12_month: "12 เดือนล่าสุด",
	past_24_month: "24 เดือนล่าสุด",
	month_1: "ทุกเดือน",
	month_3: "ทุกไตรมาส",
	month_6: "ทุกครึ่งปี",
	month_12: "ทุกปี",
	report_will_send_to_email_after_processed: "รายงานจะถูกส่งไปยังอีเมลของคุณเมื่อดำเนินการสำเร็จแล้ว",
	report_sent_coupon_history: "รายงานประวัติการส่งคูปอง",
	report_large_date_export_to_email: "เนื่องจากมีข้อมูลส่งออกจำนวนมาก ระบบจะดำเนินการส่งให้ทางอีเมล",
	report_to_email: "ระบบจะดำเนินการส่งรายงานให้ทางอีเมล",
	
	// Report Points Flow
	points_flow: "Points flow",
	report_point_flow: "Points flow",
	report_point_flow_sub: "รายงานความเคลื่อนไหวของพอยท์",
	points_earned: "พอยท์ที่ได้รับ",
	points_spent: "พอยท์ที่ใช้ไป",
	view_chart: "เลือกมุมมองแผนภูมิ",
	recommendation: "ข้อเสนอแนะ",
	conditions_recommend: "ข้อเสนอแนะ (ประมวลผลจากข้อมูลสถิติ 30 วันล่าสุด)",
	recommend_within_thirty: "ข้อเสนอแนะจากรายงาน (ในช่วงเวลา 30 วันที่ผ่านมา)",
	point_flow_description: {
		title: "คำอธิบายการใช้งาน Points Flow",
		sub_title: "เปรียบเทียบพอยท์เข้าและพอยท์ออก",
		list: [
			{ description: "แกนแนวนอน : แสดงช่วงเวลา" },
			{ description: "แกนแนวตั้ง : แสดงจำนวนพอยท์" },
			{ description: "พอยท์ที่ได้รับ : แท่งสีเขียวบนกราฟ แสดงจำนวนพอยท์ที่ได้รับกลับมาจากสมาชิกในแต่ละช่วงเวลา" },
			{ description: "พอยท์ที่ใช้ไป : แท่งสีแดงบนกราฟ แสดงจำนวนพอยท์ที่ส่งให้สมาชิกในแต่ละช่วงเวลา" },
		],
	},
	point_flow_recommend: {
		total_conditon: {
			earn_less_than_spent: "ปรับของรางวัลให้น่าสนใจ หรือใช้พอยท์ในการแลกน้อยลง",
			earn_more_than_spent: "ธุรกิจของคุณมีลูกค้าประจำกลับมาใช้บริการและใช้พอยท์แลกของรางวัลอย่างต่อเนื่อง",
			earn_between_spent: "ชักชวนให้ลูกค้าใช้พอยท์แลกของรางวัลมากขึ้น",
		},
		frequent_condition: {
			spent_less_than_half: "ส่งพอยท์ให้บ่อยขึ้น และควรส่งทันทีที่ลูกค้ามาซื้อสินค้าหรือใช้บริการ",
			spent_more_than_half:
				"หมั่นส่งพอยท์ให้กับลูกค้าอย่างสม่ำเสมอ ช่วยเพิ่มโอกาสในการเปลี่ยนลูกค้าใหม่เป็นลูกค้าประจำมากขึ้น",
		},
		special_condition: {
			average_spent_less_twenty: "ปรับอัตราการแลกพอยท์ เพื่อสร้างแรงจูงใจให้ลูกค้าอยากกลับมาสะสมพอยท์บ่อยขึ้น",
		},
	},

	// Frequency Report
	frequency: "Frequency",
	frequency_report: "รายงานลูกค้าประจำ",
	frequency_report_data: "ข้อมูลรายงานลูกค้าประจำ",
	frequency_detail: "รายงานลูกค้าประจำของธุรกิจ",
	loyalty_member_report: "รายงานรายละเอียดลูกค้าประจำ",
	frequency_range: "จำนวนการใช้บริการ",
	frequency_of_services: "จำนวนการใช้บริการ",
	frequency_member: "จำนวนสมาชิก",
	export_member_name_keep_secret: "การนำออกรายชื่อสมาชิกเป็นข้อมูลส่วนบุคคล\nกรุณาเก็บเป็นความลับ",
	document_has_sent_to_email: "เอกสารจะถูกส่งไปยังอีเมลของคุณเมื่อดำเนินการสำเร็จแล้ว",
	sent_to_your_email: "Su",
	percentile: "เปอร์เซ็นต์",

	// Recency Report
	recency: "Recency",
	recency_report: "Recency",
	recency_detail: "รายงาน Recency ของธุรกิจ",
	daylastseen_range: "ช่วงวันที่พบครั้งสุดท้าย",

	// Rewards Report
	reward_report: "รายงานของรางวัล",
	reward_report_sub: "รายงานการแลกของรางวัล",
	reward_title: "ชื่อของรางวัล",
	expire_date: "วันหมดอายุ",
	total_redeem: "จำนวนการแลกคูปอง",
	total_activated: "จำนวนการใช้คูปอง",
	total_point_earned: "พอยท์ที่ได้รับ",
	points_used: "พอยท์ที่ถูกใช้",
	points_used_redeem: "พอยท์ที่ใช้แลก",
	reward_status: "สถานะของรางวัล",
	choose_view: "เลือกมุมมอง",
	info_report_rewards_description:
		"เป็นการดูรายงานการใช้งานของรางวัลที่ลูกค้ากดแลกเข้ามา และ นำมาใช้งานกับธุรกิจ ซึ่งสามารถเลือกดูได้ตามช่วงเวลาที่ต้องการว่า ในแต่ละช่วงเวลานั้นมีลูกค้ากดแลกของรางวัลอะไรเข้ามาบ้าง กดแลกจำนวนกี่ครั้ง และนำมาใช้งานกับธุรกิจคุณจำนวนกี่ครั้ง",
	info_report_rewards_detail: [
		{ title: "ช่วงเวลา", detail: "ช่วงเวลาที่มีการกดแลกคูปองของรางวัล หรือ ช่วงเวลาในการใช้งานคูปองของผู้ดูแลระบบ" },
		{ title: "ชื่อของรางวัล", detail: "ชื่อของรางวัลที่ธุรกิจสร้างไว้" },
		{ title: "วันหมดอายุ", detail: "วันหมดอายุของรางวัล" },
		{
			title: "หมวดหมู่ของรางวัล",
			detail:
				"แสดงหมวดหมู่ของรางวัลที่ผู้ดูแลระบบกำหนดไว้ จะแสดงหมวดหมู่ของรางวัลใบนั้นว่าเป็น ของรางวัลสะสมแต้ม หรือ ของรางวัลแจกฟรี",
		},
		{ title: "จำนวนการแลกคูปอง", detail: "จำนวนครั้งในการกดแลกของรางวัลของสมาชิกตามช่วงเวลาที่เลือก" },
		{ title: "จำนวนการใช้คูปอง", detail: "จำนวนครั้งในการกดใช้งานคูปองของรางวัลโดยผู้ดูแลระบบตามช่วงเวลาที่เลือก" },
		{
			title: "พอยท์ที่ถูกใช้",
			detail:
				"จำนวนพอยท์ทั้งหมดที่สมาชิกใช้ในการแลกของรางวัล ตัวอย่างเช่น ของรางวัล A ใช้พอยท์ในการแลก 10 พอยท์ มีสมาชิกกดแลก 5 ครั้ง พอยท์ที่ถูกใช้คือ 50 พอยท์",
		},
	],
	info_report_rewards_note:
		"เมื่อสมาชิกกดแลกของรางวัลและธุรกิจกดใช้งานคูปอง จึงจะมีผลให้ จำนวนการแลกคูปองสอดคล้องกับจำนวนการใช้คูปอง ",
	redeemable_rewards: "ของรางวัลสะสมแต้ม",

	// Ecoupon Report
	ecoupon_report: "รายงานคูปอง",
	ecoupon_report_sub: "รายงานการใช้งานคูปอง",
	total_sent_coupons: "จำนวนการส่งคูปอง",
	percent_usage_coupons: "ร้อยละการใช้งาน",
	usage: "การใช้งาน",
	reward: "ของรางวัล",
	discount: "ส่วนลด",
	wel: "ต้อนรับ",
	ecoupon_status: "สถานะคูปอง",
	promotion: "โปรโมชั่น",
	attached_receipt: "แนบท้าย",
	birthmonth: "เดือนเกิด",
	specific_member: "ระบุสมาชิก",
	info_report_coupon_description:
		"เป็นการดูรายงานการใช้งานคูปอง ที่ธุรกิจมีการสร้างและกดส่งให้ลูกค้า โดยรายงานนี้จะแสดงคูปองที่ธุรกิจของคุณกดใช้งานไปแล้ว ซึ่งสามารถเลือกดูได้ตามช่วงเวลาที่ต้องการ",
	info_report_coupon_detail: [
		{ title: "ช่วงเวลา", detail: "ช่วงเวลาที่มีการใช้งานคูปอง" },
		{ title: "ชื่อคูปอง", detail: "ชื่อคูปองที่ธุรกิจสร้างไว้" },
		{
			title: "หมวดหมู่คูปอง",
			detail:
				"แสดงหมวดหมู่คูปองที่ผู้ดูแลระบบกำหนดไว้ จะแสดงหมวดหมู่ของคูปองใบนั้นว่าเป็น คูปองโปรโมชั่น, คูปองแนบใบเสร็จ หรือ คูปองต้อนรับ",
		},
		{ title: "ประเภทคูปอง", detail: "แสดงประเภทของคูปองใบนั้นว่าเป็น ของรางวัล หรือ ส่วนลด" },
		{
			title: "กลุ่มเป้าหมาย",
			detail:
				"แสดงกลุ่มเป้าหมายที่ธุรกิจทำการส่งคูปองให้ว่าส่งให้ สมาชิกทั้งหมด, สมาชิกในเดือนเกิด, สมาชิกของธุรกิจ หรือ สมาชิกในกลุ่ม RFM",
		},
		{ title: "จำนวนการใช้คูปอง", detail: "จำนวนครั้งในการกดใช้งานคูปองใบนั้นโดยผู้ดูแลระบบตามช่วงเวลาที่เลือก" },
	],

	// Business
	business_infomation: "ข้อมูลธุรกิจ",
	business_code: "รหัสธุรกิจ",
	business_logo: "โลโก้ธุรกิจ",
	business_name: "ชื่อธุรกิจ",
	business_cover: "หน้าปกธุรกิจ",
	business_color: "สีของธุรกิจ",
	business_example: "ตัวอย่างการแสดงผลบนหน้าจอของลูกค้า",
	business_card_display: "นามบัตรธุรกิจ",
	business_description: "คำอธิบายธุรกิจ",
	business_link: "ลิงก์ธุรกิจ",
	convertion_ratio: "อัตราส่วนการแปลงพอยท์",
	convertion_discount_ratio: "อัตราส่วนการแปลงพอยท์เป็นส่วนลด",
	set_ratio_convert: "ตั้งค่าอัตราส่วนการแปลงพอยท์",
	set_ratio_convert_amount_points: "แปลงจากจำนวนเงิน เป็น พอยท์",
	set_ratio_convert_points_amount: "แปลงจากจำนวนพอยท์ เป็น ส่วนลด",
	set_only_per_one: "อัตราส่วน 1 : X หรือ X : 1 เท่านั้น",
	you_can_use_points_discount_rshop: "คุณสามารถใช้งานการแปลงพอยท์เป็นส่วนลดได้ที่",
	get_poster: "แสดงโปสเตอร์",
	bath: "บาท",
	busines_creating: "กำลังสร้างธุรกิจ...",
	busines_wallet_creating: "กำลังสร้างกระเป๋าเงิน กรุณารอสักครู่....",

	// Rewards
	reward_image: "รูปภาพของรางวัล",
	add_reward: "เพิ่มของรางวัล",
	title: "ชื่อของรางวัล",
	description: "รายละเอียด",
	duration_redeem: "ระยะเวลาการแลกของรางวัล",
	delete_reward_confirm: "คุณต้องการลบของรางวัลใช่ไหม ?",
	points_to_redeem: "พอยท์สำหรับแลกของรางวัล",
	create_reward_successfully: "สร้างของรางวัลเรียบร้อย",
	edited_reward_successfully: "แก้ไขของรางวัลเรียบร้อย",
	deleted_reward_successfully: "ลบของรางวัลเรียบร้อย",
	start_on: "เริ่มใช้วันที่",
	error: "พบข้อผิดพลาด",
	set_life_rewards: "กำหนดจำนวนวันหมดอายุหลังจากกดแลกของรางวัล",
	set_limit_rewards: "การจำกัดจำนวนการแลกของรางวัล",
	redemption_per_member: "สิทธิ์การแลกต่อสมาชิก",
	redemption_per_member_unit: "สิทธิ์ต่อสมาชิก",
	redemption_limit_all: "สิทธิ์การแลกทั้งหมด",
	redemption: "สิทธิ์",
	show_remain_rewards: "แสดงจำนวนของรางวัลคงเหลือ",
	less_than: "เมื่อน้อยกว่า",
	case_not_show_remian: 'กรณีไม่กำหนดจำนวน จะแสดงคำว่า "จำนวนจำกัด',
	balance: "คงเหลือ",
	amount_rewards: "จำนวนของรางวัล",
	infinity_amount: "ไม่จำกัดจำนวน",
	member_view: "มุมมองสมาชิก",
	reward_expired: "หมดอายุ",
	deleted: "ถูกลบแล้ว",
	expired_after_receiving_reward: "หมดอายุหลังจากแลกของรางวัล",
	rewards_list: "รายการของรางวัล",
	rewards_redeem_list: "รายการแลกของรางวัล",
	rewards_receiving: "การรับของรางวัล",
	spot_redemption: "Spot Redemption",
	by_self: "รับด้วยตนเอง",
	by_delivery: "รับโดยการจัดส่ง",
	both_self_delivery: "รับด้วยตนเองหรือจัดส่ง",
	use_by_delivey: "โดยการจัดส่ง",
	prepare_reward: "เตรียมของรางวัล",
	prepare: "จัดเตรียม",
	delivery: "จัดส่ง",
	delivered: "จัดส่งแล้ว",
	address_delivery: "ที่อยู่จัดส่ง",
	address_delivery_not_found: "ไม่พบรายการที่อยู่จัดส่ง",
	delivery_status: "สถานะการจัดส่ง",
	guide: "แนะนำ",
	change_delivery_status_guide: "เลือกตัวกรองสถานะการจัดส่งเพื่อเปลี่ยนแปลงสถานะการจัดส่ง",
	change_status: "เปลี่ยนสถานะคูปอง",
	change_delivery_status: "เปลี่ยนสถานะการจัดส่ง",
	amount_coupon_change: "จำนวนคูปองที่ถูกเปลี่ยนสถานะ",
	update_status_success: "อัปเดทสถานะสำเร็จ",
	reward_link_access: "ลิงก์แสดงของรางวัล",
	reward_link_line_liff: "ลิงก์แสดงของรางวัลสำหรับ LINE LIFF app",
	giveaway_link_access: "ลิงก์แสดงของรางวัลแจกฟรี",
	giveaway_link_line_liff: "ลิงก์แสดงของรางวัลแจกฟรีสำหรับ LINE LIFF app",
	reward_create_header: "สร้างของรางวัลสะสมแต้ม",
	reward_create_confirmation: "ยืนยันการสร้างของรางวัลสะสมแต้ม",
	reward_edit_header: "แก้ไขของรางวัลสะสมแต้ม",
	reward_edit_confirmation: "ยืนยันแก้ไขของรางวัลสะสมแต้ม",
	giveaway_edit_confirmation: "ยืนยันแก้ไขของรางวัลแจกฟรี",
	giveaway_create_header: "สร้างของรางวัลแจกฟรี",
	giveaway_create_confirmation: "ยืนยันการสร้างของรางวัลแจกฟรี",
	giveaway_edit_header: "แก้ไขของรางวัลแจกฟรี",
	reward_not_found: "ไม่พบของรางวัลสะสมแต้ม",
	select_reward: "เลือกของรางวัล",
	reward_redeem_value: "มูลค่าของรางวัลสะสมแต้ม",
	value_not_set: "ไม่ได้กำหนดมูลค่า",

	// Terms & Conditions
	terms_and_conditions: "เงื่อนไขการให้บริการ",
	terms_and_conditions_description: "กำหนดกฏ เงื่อนไขการให้บริการของธุรกิจ",
	no_terms_and_conditions: "ไม่พบเงื่อนไขการให้บริการ",
	privacy_policy_description: "กำหนดนโยบายข้อมูลส่วนบุคคล",
	no_privacy_policy: "ไม่พบนโยบายข้อมูลส่วนบุคคล",
	invalid_policy_url: "URL ไม่ถูกต้อง",

	// Admin
	Permission_denied: "คุณไม่มีสิทธิ์เข้าถึงในส่วนนี้",
	role_name_error_character: "รองรับเฉพาะตัวอักษร และตัวเลขเท่านั้น",
	admin: "ผู้ดูแลระบบ",
	invite: "เชิญ",
	invitation: "คำเชิญ",
	roles: "บทบาท",
	function: `การใช้งาน`,
	permission: `การอนุญาต`,
	"user-permission/role-in-used": `ไม่สามารถลบได้เนื่องจากมีผู้ดูแลระบบใช้งานบทบาทนี้อยู่`,
	"user-permission/role-invited": `ไม่สามารถลบได้เนื่องจากมีการเชิญผู้ดูแลระบบที่ใช้งานบทบาทนี้อยู่`,
	roles_have_exceeded_the_limit: `จำนวนบทบาทเกินกว่าสิทธิที่ได้รับ ทำให้ไม่สามารถสร้าง และแก้ไขบทบาทได้`,
	package_cannot_create: `แพ็คเกจของท่านไม่สามารถ สร้าง และแก้ไขบทบาทได้`,
	create_custom_role_successfully: `สร้างบทบาทสำเร็จ`,
	edit_custom_role_successfully: `แก้ไขบทบาทสำเร็จ`,
	create_custom_role: `สร้างบทบาท`,
	edit_custom_role: `แก้ไขบทบาท`,
	confirm_create_custom_role: `ยืนยันสร้างบทบาท`,
	confirm_edit_custom_role: `ยืนยันแก้ไขบทบาท`,
	custom_role: `จัดการบทบาท`,
	custom_role_name: `ชื่อบทบาท`,
	add_role: `คลิก เพิ่ม เพื่อสร้างบทบาท`,
	delete_custom_role_confirm: `คุณต้องการลบบทบาทหรือไม่`,
	duplicate_role_name: `ชื่อบทบาทซ้ำกับที่มีในระบบ`,
	only_self_transactions: `เฉพาะของตนเอง`,
	title_role_list: {
		member: `สมาชิก`,
		member_card: `บัตรสมาชิก`,
		points: `พอยท์`,
		coupon: `คูปอง/ของรางวัล`,
		automation: `Automation`,
		etc: `อื่นๆ`,
		"restrict-view": `จำกัดการมองเห็น`,
	},
	item_role_list: {
		permission_view: {
			member: `ดูข้อมูลสมาชิก`,
			"membercard-batchjob": `สร้าง/นำเข้า ข้อมูลสมาชิก`,
			"report-birthday": `รายงานเดือนเกิด`,
			"report-demographic": `รายงานข้อมูลประชากร`,
			"report-friend-get-friends": `รายงานเพื่อนชวนเพื่อน`,
			"report-RFM-analysis": `รายงาน RFM Analysis`,
			"report-frequency": `รายงานลูกค้าประจำ`,
			"setting-member-card": `ดูข้อมูลบัตรสมาชิก`,
			"setting-transaction-membercard": `รายงานประวัติบัตรสมาชิก`,
			"points-transactions": `ดูประวัติการทำรายการ`,
			"points-send": `ส่งพอยท์`,
			"points-batchjob": `นำเข้าพอยท์`,
			"points-pull": `ดึงพอยท์`,
			"report-transaction": `รายงานประวัติรายการพอยท์`,
			"report-transaction-admin": `รายงานประวัติรายการโดยผู้ดูแลระบบ`,
			"report-points-flow": `รายงาน Points flow`,
			"report-product": `รายงานการให้พอยท์ตามสินค้า`,
			"setting-rewards": `ดูของรางวัล`,
			"setting-ecoupon": `ดูคูปอง`,
			"transaction-redeem": `รายการแลกของรางวัล`,
			"ecoupon-activate": `ใช้งานคูปอง/ของรางวัล`,
			"report-rewards": `รายงานของรางวัล`,
			"report-ecoupon": `รายงานคูปอง`,
			"report-transaction-sent": `รายงานประวัติการส่งคูปอง`,
			"report-transaction-activated": `รายงานประวัติการใช้งานคูปอง`,
			"automation-member-card": `ดูการตั้งค่า automation`,
			"report-dashboard": `รายงาน Dashboard`,
			"report-review": `รายงานความพึงพอใจ & ความเห็น`,
			"report-admin": `รายงานผู้ดูแลระบบ`,
			newsfeed: `Activities`,
			"broadcast-giftvoucher": `รายการส่งบัตรกำนัล`,
		},
		permission_edit: {
			member_name: `แก้ไขข้อมูลสมาชิก`,
			"setting-member-card": `สร้าง/แก้ไขประเภทบัตรสมาชิก`,
			"setting-rewards": `สร้าง/แก้ไขของรางวัล`,
			"setting-ecoupon": `สร้าง/แก้ไขคูปอง`,
			"setting-business": `การตั้งค่า`,
			"broadcast-promotion": `บรอดแคสต์ข้อความ`,
			"restrict-admin-report-owned": `จำกัดเฉพาะรายการที่ตนเองดำเนินการ`,
		},
		permission_create: {
			member_label: `ติดป้ายกำกับสมาชิก`,
			"setting-transaction-membercard": `ออก/เปลี่ยนบัตรสมาชิก`,
			"points-request": `แลกรับพอยท์`,
			"broadcast-giftvoucher": `ส่งคูปองบัตรกำนัล`,
			"automation-member-card": `ตั้งค่า automation`,
			"setting-admin": `ตั้งค่าผู้ดูแล`,
		},
		permission_delete: {
			newsfeed: `จัดการแพ็กเกจ`,
		},
		permission_export: {
			member: `การ Export ข้อมูล`,
		},
	},
	admin_report_owned: {
		description_1: `ประวัติการทำรายการ`,
		description_2: `รายงานประวัติการส่งคูปอง`,
		description_3: `รายงานประวัติการใช้คูปอง`,
		description_4: `รายงานประวัติรายการโดยผู้ดูแลระบบ`,
		description_5: `รายงานผู้ดูแลระบบ`,
	},
	role_transactions_history: "ประวัติรายการ",
	role_transactions_history_access: "การเข้าถึงประวัติรายการ",
	role_restrict_specific_menus: "ให้บทบาทนี้เห็นประวัติรายการดังต่อไปนี้",
	role_description: "รายละเอียดบทบาท",
	admin_list: "รายชื่อผู้ดูแลระบบ",
	invitation_list: "รายชื่อคำเชิญ",
	email_already_exist: "อีเมลมีอยู่แล้ว",
	no_invitation: "ไม่มีคำเชิญ",
	delect_admin_confirm: "คุณต้องการลบผู้ดูแลระบบหรือไม่",
	delect_invitation_confirm: "คุณต้องการลบคำเชิญ",
	send_invite: "ส่งคำเชิญ",
	trans_limit: "จำกัดการส่งพอยท์",
	trans_limit_x_point: "จำกัด : $1 พอยท์",
	manual_admin: "คู่มือผู้ดูแลระบบ",

	// Batch Jobs
	import: "นำเข้า",
	order_no: "ลำดับ",
	job_id: "หมายเลขงาน",
	batchJobs: "นำเข้าพอยท์",
	batchJobsMemberCard: "นำเข้าข้อมูลสมาชิก",
	step_batchJobs: "ขั้นตอนนำเข้าพอยท์",
	addJob: "สร้าง / นำเข้างาน",
	last_modified: "เปลี่ยนแปลงล่าสุด",
	import_type: "ประเภทการนำเข้า",
	choose_import_type: "เลือกประเภทการนำเข้า",
	choose_file_ex: "เลือกตัวอย่างไฟล์นำเข้า",
	status: "สถานะ",
	receiver: "ผู้รับ",
	list: "รายการ",
	record: "รายการ",
	modified_on: "เปลี่ยนแปลงเมื่อ",
	modified_by: "เปลี่ยนแปลงโดย",
	on: "เมื่อ",
	add: "เพิ่ม",
	example_CSV: "ตัวอย่างไฟล์สำหรับนำเข้าในรูปแบบ CSV",
	download: "ดาวน์โหลด",
	select_column: "เลือกคอลัมน์",
	send_sms_text_messages: "การแจ้งเตือนจะส่งระหว่างเวลา {0} เท่านั้น",
	send_text_messages: "ส่งการแจ้งเตือนได้รับพอยท์จะส่งระหว่างเวลา {0} เท่านั้น",
	pull_text_messages: "ส่งการแจ้งเตือนดึงพอยท์จะส่งระหว่างเวลา {0} เท่านั้น",
	only: "เท่านั้น",
	total_job: "รายการทั้งหมด",
	success_job: "รายการที่สำเร็จ",
	failure_job: "รายการที่ผิดพลาด",
	successful: "สำเร็จ",
	failed: "ผิดพลาด",
	partial_failure: "ผิดพลาดบางรายการ",
	in_progress: "ประมวลผล",
	stoped: "รอดำเนินการ",
	start_job: "เริ่มต้นงาน",
	cancel_job: "ยกเลิกงาน",
	upload: "อัปโหลดไฟล์",
	select_file_upload: "เลือกไฟล์อัปโหลด",
	download_from: "ดาวน์โหลดแบบฟอร์มตัวอย่าง",
	download_example: "ดาวน์โหลดไฟล์ตัวอย่าง",
	show_allData: "แสดงข้อมูลทั้งหมด",
	incorrect_data: "ข้อมูลไม่ถูกต้อง",
	correct_data: "ข้อมูลถูกต้อง",
	job_points: "พอยท์",
	job_receiver: "ผู้รับ",
	job_remark: "หมายเหตุ",
	browse_file: "เลือกไฟล์",
	file_error_larger_size: "ขนาดไฟล์ใหญ่เกิน 5 MB",
	file_error_wrong_type: "นามสกุลไฟล์ไม่ถูกต้อง",
	file_error_empty: "ไม่พบข้อมูล",
	file_error_data: "จำนวนข้อมูลมากกว่า {0} รายการ",
	file_error_data_200: "จำนวนข้อมูลมากกว่า 200 รายการ",
	file_error: "พบข้อผิดพลาด",
	file_error_format: "ข้อมูลมีรูปแบบที่ไม่ถูกต้อง",
	file_error_import_point_type: "ไฟล์ไม่ตรงกับประเภทการนำเข้า",
	limit_file_size: "ขนาดไฟล์ต้องไม่เกิน 5 mb",
	limit_import_records: "จำกัดการนำเข้าไม่เกิน {0} รายการต่องาน",
	cron_job_error: {
		"parmas/request-points-params": "จำนวนพอยท์ไม่ถูกต้อง",
		"parmas/points-params-not-match": "รูปแบบพอยท์ไม่ถูกต้อง",
		"points/request-points": "จำนวนพอยท์ไม่ถูกต้อง",
		"points/limit_poitns-transfer": "ส่งพอยท์เกินจำนวนที่กำหนด",
		"member/request-member-profile": "ไม่พบข้อมูลสมาชิก",
		"member/already-has-card": "ผู้รับมีบัตรสมาชิกอยู่แล้ว",
		"cronjob/transfer-points-internal-error": "เกิดข้อผิดพลาด ส่งพอยท์ไม่สำเร็จ",
		"cronjob/import-member-card-internal-error": "เกิดข้อผิดพลาด ออกบัตรสมาชิกไม่สำเร็จ",
		"member/user-already-exist": "มีผู้ใช้อยู่แล้ว",
	},
	import_receiver_invalid: "รูปแบบผู้รับไม่ถูกต้อง",
	import_point_invalid: "รูปแบบพอยท์ไม่ถูกต้อง",
	import_amount_invalid: "รูปแบบจำนวนไม่ถูกต้อง",
	import_firstname_invalid: "ชื่อต้องไม่เกิน 50 ตัวอักษร",
	import_lastname_invalid: "นามสกุลต้องไม่เกิน 50 ตัวอักษร",
	import_gender_invalid: 'เพศต้องมีค่าเป็น "male" "female" "unspecified" เท่านั้น',
	import_date_of_birth_invalid: "วันเกิดต้องมีรูปแบบเป็น DD-MM-YYYY เท่านั้น",
	age_range_invalid: "รองรับสมาชิกที่มีอายุ 13 - 100 ปี",
	import_email_invalid: "รูปแบบอีเมลไม่ถูกต้อง",
	import_member_card_invalid: "ไม่มีประเภทบัตรสมาชิกนี้",
	incorrect_sku: "รหัส SKU ไม่ถูกต้อง",
	incorrect_receipt_no: "เลขใบเสร็จรับเงินไม่ถูกต้อง",
	import_member_card: "นำเข้าข้อมูลบัตรสมาชิก",
	import_initial_point: "นำเข้าข้อมูลพอยท์ตั้งต้น",
	import_initial_member_card_upload_type: "บัตรสมาชิก",
	import_initial_point_upload_type: "พอยท์ตั้งต้น",
	modal_title_incomplete_import_data: "ข้อมูลไม่ถูกต้อง",
	modal_detail_please_verify: "กรุณาตรวจสอบ และแก้ไข ระบบจะนำเข้าข้อมูลที่ถูกต้องเท่านั้น",
	modal_btn_download: "ดาวน์โหลดข้อมูลที่ไม่ถูกต้อง",
	modal_btn_cancel: "ยกเลิก",
	modal_btn_continue: "ดำเนินการต่อ",
	open_member_card: "กรุณาเปิดใช้งานระบบบัตรสมาชิกเพื่อดำเนินการต่อ ",
	click_open_member_card: "คลิกเพื่อไปหน้าเปิดระบบบัตรสมาชิก",
	product_point: "** พอยท์ของแต่ละสินค้าจะคำนวณรวมในใบเสร็จเดียวกัน",
	import_confirm: "ยืนยันการนำเข้าข้อมูล",
	// eCoupon
	ecoupon: "คูปอง",
	ecoupon_image: "รูปภาพคูปอง",
	add_ecoupon: "เพิ่มคูปองโปรโมชั่น",
	create_and_send_coupon: "สร้างและส่งคูปอง",
	name_ecoupon: "ชื่อคูปอง",
	name_ecoupons: "ชื่อคูปอง",
	name_ecoupon_placeholder: "ตย. ส่วนลด 25% สำหรับซื้อสินค้าหรือบริการ",
	type_ecoupon: "ประเภทคูปอง",
	type_ecoupons: "ประเภทคูปอง",
	coupons_category: "หมวดหมู่คูปอง",
	rewards_category: "หมวดหมู่ของรางวัล",
	ecoupon_free: "ของรางวัล",
	description_ecoupon: "รายละเอียดคูปอง",
	ecoupon_discount: "ส่วนลด",
	ecoupon_givePoint: "แจกพอยท์",
	note: "หมายเหตุ",
	target: "กลุ่มเป้าหมาย",
	target_groups: "เลือกกลุ่มเป้าหมายจาก",
	all_members: "ทั้งหมด",
	rfm_members: "กลุ่ม RFM",
	rfm_groups: "กลุ่ม RFM",
	rfm_groups_6m: "6 เดือนที่แล้ว",
	rfm_groups_12m: "12 เดือนที่แล้ว",
	member_card_groups: "บัตรสมาชิก",
	birth_month_members: "เดือนเกิด",
	each_birth_month_members: "สมาชิกที่เกิดในแต่ละเดือน",
	member_business: "ระบุรายบุคคล",
	select_birth_month_members: "เลือกสมาชิกที่เกิดในเดือน",
	select_rfm_group: "เลือกกลุ่ม RFM",
	time: "เวลา",
	duration_usage: "ระยะเวลาการใช้งาน",
	schedule_of_usage: "ตามช่วงเวลา",
	ecoupon_all: "ส่งแล้ว",
	coupon_promotion: "คูปองโปรโมชั่น",
	coupon_promotion_create: "สร้างคูปองโปรโมชั่น",
	coupon: "คูปองโปรโมชั่น",
	coupon_send_sms_text: "ส่งการแจ้งเตือนให้สมาชิก",
	repeat: "ทำซ้ำ",
	duplicate: "ทำซ้ำ",
	create_coupon_successfully: "สร้างคูปองเรียบร้อย",
	edited_coupon_successfully: "แก้ไขคูปองเรียบร้อย",
	deleted_coupon_successfully: "ลบคูปองเรียบร้อย",
	ecoupon_will_incur_costs: "การส่งคูปองโปรโมชั่น มีการคิดค่าบริการตามแพ็กเกจที่ใช้งาน",
	ecoupon_confrim_send: "ยืนยันการส่งคูปองโปรโมชั่น",
	ecoupon_costs_approximately: "การส่งคูปองโปรโมชั่น คิดค่าบริการรายการส่งตามแพ็กเกจที่ใช้งาน",
	ecoupon_costs_approximately_transaction: "การส่งคูปองครั้งนี้ คิดเป็น $1 รายการ",
	not_member_in_target_group: "ไม่พบสมาชิกในกลุ่มเป้าหมายที่เลือก\nกรุณาเลือกกลุ่มเป้าหมายอีกครั้ง",
	confirm_delete_coupon: "คุณต้องการลบคูปองใบนี้ใช่ไหม ?",
	ecoupon_automation_setting: "ระบบส่งคูปองอัตโนมัติ",
	ecoupon_automation_setting_description: "สร้างแคมเปญเพื่อให้ระบบส่งคูปองโดยอัตโนมัติตามกลุ่มเป้าหมายที่กำหนดไว้",
	ecoupon_automation_add_campaign: "เพิ่มแคมเปญ",
	campaign: "แคมเปญ",
	campaign_frequency: "รูปแบบการส่ง",
	campaign_frequency_daily: "ทุกวัน",
	campaign_frequency_monthly: "ทุกวันที่ 1 ของเดือน",
	campaign_frequency_new_members: "สมาชิกใหม่ของธุรกิจ",
	campaign_create_success: "สร้างแคมเปญสำเร็จ",
	campaign_edit_success: "แก้ไขแคมเปญสำเร็จ",
	campaign_delete_success: "ลบแคมเปญสำเร็จ",
	campaign_confirm_delete: "ยืนยันการลบแคมเปญนี้",
	campaign_editor_create: "สร้างแคมเปญ",
	campaign_editor_edit: "แก้ไขแคมเปญ",
	campaign_period: "ระยะเวลาแคมเปญ",
	campaign_coupon_sent: "รายการที่ส่งแล้ว",
	campaign_coupon_activated: "ใช้งานแล้ว",
	campaign_coupon_not_activated: "ยังไม่ใช้งาน",
	campaign_coupon_usage: "การใช้งาน",
	campaign_title: "ชื่อแคมเปญ",
	campaign_duration: "ระยะเวลาแคมเปญ",
	campaign_coupon_duration_warning: "ระยะเวลาการใช้งานคูปองไม่สอดคล้องกับแคมเปญ",
	campaign_coupon_duration: "ระยะเวลาการใช้งานคูปอง",
	campaign_coupon_target: "กลุ่มเป้าหมาย",
	campaign_coupon_target_each_month: "ส่งคูปองให้กับสมาชิกที่เกิดในแต่ละเดือนตลอดช่วงเวลาแคมเปญ",
	campaign_use_coupon_setting: "ใช้การตั้งค่าของคูปอง",
	campaign_edit_coupon_warning: 'คูปองนี้ถูกใช้งานในแคมเปญ "{0}" \nการแก้ไขช่วงเวลาอาจมีผลทำให้สมาชิกไม่ได้รับคูปอง',
	campaign_expired: "สิ้นสุดแคมเปญ",
	campaign_please_select_target_group_again: "กรุณาเลือกกลุ่มเป้าหมายอีกครั้ง",
	campaign_trigger_description: "รูปแบบการส่งทุกวัน และทุกวันที่ 1 ของเดือน ระบบจะส่งคูปองเวลา 9:00 น.",

	automation_coupon_title: "ระบบส่งคูปองอัตโนมัติ",
	automation_coupon_description: "สร้างแคมเปญเพื่อให้ระบบส่งคูปองโดยอัตโนมัติตามกลุ่มเป้าหมายที่กำหนดไว้",

	// Welcome eCoupon
	coupon_welcome: "คูปองต้อนรับ",
	wel_coupon: "คูปองต้อนรับ",
	coupon_welcome_image: "รูปภาพคูปองต้อนรับ",
	add_welcome_ecoupon: "เพิ่มคูปองต้อนรับ",
	name_welcome_ecoupon: "ชื่อคูปองต้อนรับ",
	type_welcome_ecoupon: "ประเภทคูปองต้อนรับ",
	description_welcome_ecoupon: "รายละเอียดคูปองต้อนรับ",
	create_welcoupon_successfully: "สร้างคูปองต้อนรับเรียบร้อย",
	edited_welcoupon_successfully: "แก้ไขคูปองต้อนรับเรียบร้อย",
	deleted_welcoupon_successfully: "ลบคูปองต้อนรับเรียบร้อย",

	//Verify Business
	verify_process: "ขั้นตอนการเปิดใช้งานธุรกิจ",
	verify_establishment: "สถานประกอบการ",
	verify_business_product_type: "ประเภทธุรกิจและสินค้า",
	verify_business_type: "ประเภทธุรกิจ",
	verify_mobile_phone: "เบอร์โทรศัพท์",
	verify_credit_card: "บัตรเครดิต",
	add_detail: "เพิ่มรายละเอียด",
	edit_detail: "แก้ไขรายละเอียด",
	add_detail_success: "สำเร็จ",
	rejected: "ถูกปฏิเสธ",
	confirm_details: "ยืนยันรายละเอียดสถานประกอบการ",
	confirm_type: "ยืนยันประเภทธุรกิจและสินค้า",
	confirm_phone: "ยืนยันเบอร์โทรศัพท์",
	confirm_card: "ยืนยันบัตรเครดิต",
	confirm_sub: "ของธุรกิจ",
	juristic_person: "นิติบุคคล",
	ordinary_person: "บุคคลธรรมดา",
	verify_business: "เปิดใช้งานธุรกิจ",
	submit_detail: "ยืนยันรายละเอียด",
	submit_type: "ยืนยันประเภท",
	submit_phone: "ยืนยันเบอร์โทรศัพท์",
	submit_card: "ยืนยันบัตรเครดิต",
	verify_detail_success: "ยืนยันรายละเอียดธุรกิจสำเร็จ",
	verify_type_success: "ยืนยันประเภทธุรกิจสำเร็จ",
	verify_phone_success: "ยืนยันเบอร์โทรศัพท์สำเร็จ",
	verify_card_success: "ยืนยันบัตรเครดิตสำเร็จ",
	verify_detail_fail: "ยืนยันรายละเอียดธุรกิจล้มเหลว",
	verify_type_fail: "ยืนยันประเภทธุรกิจล้มเหลว",
	verify_phone_fail: "ยืนยันเบอร์โทรศัพท์ล้มเหลว",
	verify_card_fail: "ยืนยันบัตรเครดิตล้มเหลว",
	request_verify_business: "ขอยืนยันธุรกิจ",
	company_name: "ชื่อบริษัท / นิติบุคคล",
	receipt_name: "ชื่อบริษัท / นิติบุคคล / ชื่อผู้รับ",
	commercial_businesses_name: "ชื่อที่ใช้ในการประกอบพาณิชยกิจ",
	name_of_director: "ชื่อกรรมการ",
	owner_name: "ชื่อเจ้าของธุรกิจ",
	id: "เลขบัตรประชาชน",
	tax_id: "เลขประจำตัวผู้เสียภาษี",
	receipt_tax_id: "เลขประจำตัวผู้เสียภาษี / เลขบัตรประชาชน",
	business_address: "ที่อยู่สถานประกอบการ",
	address_line1: "เลขที่ หมู่ที่, หมู่บ้าน, ซอย",
	address_line2: "ถนน, แขวง/ตำบล",
	map_position: "ตำแหน่งที่ตั้งบนแผนที่",
	map_description: "เลื่อนแผนที่ไปยังตำแหน่งที่ต้องการ และ คลิกกำหนดตำแหน่งที่ตั้งลงบนแผนที่",
	copy_of_IDcard: "สำเนาบัตรประชาชน",
	copyof_company_certification: "สำเนารับรองบริษัท",
	business_registration: "ทะเบียนการค้า",
	commercial_registration: "ทะเบียนพานิชย์",
	photo_shop: "รูปถ่ายหน้าร้านธุรกิจ",
	attached_file: "ไฟล์แนบ",
	choose_registration_type: "เลือกรูปแบบการจดทะเบียนธุรกิจ",
	choose_business_type: "เลือกประเภทธุรกิจ",
	choose_business_type_sub: "ค้นหาและเลือกประเภทธุรกิจของคุณอย่างน้อย 1 ข้อ เลือกสูงสุด 3 ข้อ",
	business_choosed: "ประเภทธุรกิจของคุณ",
	choose_product_type: "เลือกประเภทสินค้า",
	choose_product_type_sub: "ค้นหาและเลือกประเภทสินค้าของคุณอย่างน้อย 1 ข้อ เลือกสูงสุด 3 ข้อ",
	product_choosed: "ประเภทสินค้าของคุณ",
	search_business: "ค้นหาประเภทธุรกิจของคุณ",
	search_product: "ค้นหาประเภทสินค้าของคุณ",
	no_result: "ไม่พบผลลัพธ์การค้นหา",
	phone_mobile: "เบอร์โทรศัพท์",
	input_phone: "ใส่เบอร์โทรศัพท์เพื่อรับรหัส OTP",
	request_otp: "ขอรหัส OTP",
	resend_otp: "ส่งรหัสได้อีกครั้งในอีก",
	verify_otp: "ยืนยันรหัส OTP",
	input_otp: "กรอกรหัส OTP",
	error_otp: "เกิดข้อผิดพลาดในการยืนยันรหัส OTP โปรดลองอีกครั้ง",
	"oauth2/invalid-phone-number": "เบอร์โทรศัพท์ไม่ถูกต้อง",
	"oauth2/argument-phone-error": "กรุณากรอกเบอร์โทรศัพท์",
	"oauth2/argument-otp-error": "กรุณากรอกรหัส OTP",
	"oauth2/invalid-otp": "รหัส OTP ไม่ถูกต้อง กรุณากรอกรหัส OTP ใหม่",
	"oauth2/otp-limit-exceeded": "ขอรหัส OTP เกินจำนวนครั้งที่กำหนด\nกรุณาลองใหม่อีกครั้งใน $1 นาที",
	"oauth2/operation-error": "พบข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่",
	"oauth2/invalid-reference": "รหัส OTP หมดอายุ กรุณาขอรหัส OTP ใหม่",
	"oauth2/reference-expired": "รหัส OTP หมดอายุ กรุณาขอรหัส OTP ใหม่",
	"oauth2/otp-too-many-retry": "กรอกรหัส OTP ไม่ถูกต้องเกินจำนวนครั้ง\nกรุณาขอรหัส OTP ใหม่",
	your_business_type: "ประเภทธุรกิจของคุณ",
	wait_verify: "กรุณากรอกรายละเอียดให้ครบถ้วนเพื่อเปิดใช้งานธุรกิจ",
	pending_verify: "อยู่ระหว่างดำเนินการเปิดใช้งานธุรกิจ",
	fail_verify: "การเปิดใช้งานธุรกิจไม่สำเร็จ",
	success_verify: "การเปิดใช้งานธุรกิจสำเร็จ",
	verification_business: "การเปิดใช้งานธุรกิจ",
	get_verified_business: "เมื่อธุรกิจได้รับการเปิดใช้งานสำเร็จ",
	send_point_unlimited: "คุณสามารถส่งพอยท์ได้ตามแพ็กเกจที่ใช้งาน",
	import_point_verified: "คุณสามารถใช้งานนำเข้าพอยท์ได้",
	warning: "คำเตือน",
	submit_verify_business: "คุณต้องการเปิดใช้งานธุรกิจหรือไม่",
	submit_verify_business_detail_1: 'เมื่อคุณ "ยืนยัน" ระบบจะทำการตรวจสอบข้อมูลการยืนยันธุรกิจของคุณ',
	submit_verify_business_detail_2: "ซึ่งจะไม่สามารถทำการแก้ไขข้อมูลของการยืนยันธุรกิจได้",
	warning_verify_send_points: "คุณยังไม่ได้รับการยืนยันธุรกิจ สามารถส่งพอยท์ได้ $1 ครั้งเท่านั้น",
	total_send_points: "คุณส่งพอยท์ไปแล้ว",
	total_more_send_points: "คุณส่งพอยท์มากกว่า",
	refCode: "รหัสอ้างอิง",
	your_verifyphone: "เบอร์โทรศัพท์ที่คุณใช้ในการยืนยันธุรกิจ",
	your_verifycard: "บัตรเครดิตที่คุณใช้ในการยืนยันธุรกิจ",
	foreign_pay_detail:
		"กรณีที่ชำระเงินค่าบริการด้วยบัตรเดบิต กรุณาติดต่อธนาคารเพื่อเปิดใช้งานการชำระเงินไปยังต่างประเทศ",
	card_verification_remark:
		"Pointspot จะทำการทดสอบเรียกเก็บเงินเป็นจำนวน 1 บาท เมื่อท่านทำการเพิ่มบัตร และจะทำการคืนเงินเมื่อรายการเสร็จสมบูรณ์",
	mobile_invalid: "เบอร์โทรศัพท์ไม่ถูกต้อง",

	verifyprocess: [
		{
			id: "BusinessDetail",
			detail: "1. ยืนยันรายละเอียดสถานประกอบการ",
		},
		{
			id: "BusinessTypeModal",
			detail: "2. ยืนยันประเภทธุรกิจ",
		},
		{
			id: "PhoneModal",
			detail: "3. ยืนยันเบอร์โทรศัพท์",
		},
		{
			id: "CreditCardModal",
			detail: "4. ยืนยันบัตรเครดิต",
		},
	],
	conditionVerify: {
		accept: {
			title: "เงื่อนไขการอนุมัติเปิดใช้งานธุรกิจ",
			description: "ธุรกิจที่มีการยืนยันธุรกิจตามขั้นตอนการเปิดใช้งานธุรกิจครบทั้ง 3 ขั้นตอน ได้แก่",
			items: [
				{ title: `การยืนยันรายละเอียดสถานประกอบการ ซึ่งรายละเอียดสถานประกอบการจะต้องมีอยู่จริง` },
				{ title: `การยืนยันประเภทธุรกิจที่ขายหรือให้บริการอยู่ในปัจจุบัน` },
				{ title: `การยืนยันเบอร์โทรศัพท์ที่เป็นของเจ้าของจริงและผ่านการยืนยันรหัส OTP` },
			],
		},
		reject: {
			title: "ธุรกิจในกลุ่มต่อไปนี้ ยังไม่สามารถอนุมัติเปิดใช้งานธุรกิจได้",
			description: "เนื่องจากอาจขัดต่อกฎหมายเกี่ยวกับการโฆษณาและการจัดกิจกรรมส่งเสริมการขาย",
			items: [
				{ title: `ธุรกิจเหล้า เบียร์ เครื่องดื่มแอลกอฮอล์ และ สถานบันเทิง` },
				{
					title: `ธุรกิจการเงิน เช่น เงินดิจิตอล (BitCoin) เงินด่วน เงินกู้นอกระบบออนไลน์ บริการปั้มแรงค์ เก็บเรทวิน จำหน่ายบัตรเติมเกมส์ออนไลน์ เป็นต้น`,
				},
				{ title: `ธุรกิจยารักษาโรค และเวชภัณฑ์` },
				{ title: `ธุรกิจเครื่องสำอาง ที่อวดอ้างสรรพคุณเกินจริง และ ไม่ได้ขึ้นทะเบียนจดแจ้ง หรือ อย.` },
			],
		},
	},
	cookie_consent: `เราใช้คุกกี้ในการให้บริการเพื่อเพิ่มประสบการณ์ที่ดีบนผลิตภัณฑ์ของเรา`,
	terms_and_con: {
		title: "หมายเหตุ",
		items: [
			{
				terms_and_con_detail:
					"1. หากทำการตรวจสอบข้อมูลของลูกค้าแล้วพบว่าไม่ตรงตามเงื่อนไขและข้อกำหนดของบริษัท ทางบริษัทจะถือว่าเป็นโมฆะ",
			},
			// { terms_and_con_detail: '2. การยืนยันบัตรเครดิต ลูกค้าจะยังไม่ถูกเรียกเก็บเงินใดๆ จนกว่าจะมีการสมัครใช้งานแพ็กเกจที่มีการคิดค่าบริการ' }
		],
	},
	credit_non_charge: "ลูกค้าจะยังไม่ถูกเรียกเก็บเงินใดๆ จนกว่าจะมีการสมัครใช้งานแพ็กเกจที่มีการคิดค่าบริการ",
	scan_qrcode_send_point: "สแกน QR Code\nเพื่อรับ Pointspot ID ของสมาชิก",

	//Business Contact
	business_contact: "ข้อมูลการติดต่อ",
	website: "เว็บไซต์",

	//Business Subscribe
	dailymail_summary: "สรุปสถิติประจำวัน",
	dailymail_summary_detail: "ส่งหรือยกเลิกการส่งอีเมลแสดงสรุปสถิติประจำวัน",
	something_wrong: "เกิดปัญหาบางอย่าง",

	//Business Review
	report_satisfaction: "ความพึงพอใจ",
	report_review: "ความเห็น",
	satisfaction: "ความพึงพอใจ",
	satisfaction_score: "คะแนนความพึงพอใจ",
	satisfaction_summary: "สรุปความพึงพอใจ",
	satisfaction_summary_in_business: "สรุปความพึงพอใจของธุรกิจ",
	reviews: "ความเห็น",
	non_review: "ไม่พบความเห็น",
	comment: "แสดงความคิดเห็น",
	comment_on: "แสดงความคิดเห็นเมื่อ",
	comment_summary: "สรุปความคิดเห็น",
	comment_summary_in_business: "สรุปความคิดเห็นของธุรกิจ",
	comments_only: "แสดงเฉพาะที่มีความเห็น",
	profile_member: "ข้อมูลสมาชิก",
	satisfaction_comment_summary: "สรุปคะแนนความพึงพอใจและความคิดเห็น",
	satisfaction_comment_summary_report: "รายงานคะแนนความพึงพอใจและความคิดเห็นของธุรกิจ",
	subscribe_at: "คุณสามารถสมัครรับข่าวสารได้ที่",
	review: "ความคิดเห็น",
	review_only: "เฉพาะที่มีความคิดเห็นเท่านั้น",
	score: "คะแนน",

	// Expriry points
	expiry_points: "พอยท์หมดอายุ",
	setting_expiry_points: "ตั้งค่าพอยท์หมดอายุ",
	setting_expiry_points_noti: "ตั้งค่าแจ้งเตือนพอยท์หมดอายุ",
	setting_expiry_points_setting: "ตั้งค่าวันที่หมดอายุของพอยท์ในธุรกิจ",
	select_expiration_of_point: "เลือกการหมดอายุของพอยท์",
	noti_expiration_of_point: "การแจ้งเตือนพอยท์หมดอายุ",
	non_noti_expiry_points: "ไม่แจ้งเตือน",
	use_noti_expiry_points: "แจ้งเตือนล่วงหน้า",
	use_noti_expiry_points_detail:
		"ทำการแจ้งเตือนพอยท์หมดอายุล่วงหน้า นับจากวันที่พอยท์จะหมดอายุ (ไม่เกิน 30 วัน)" +
		"\nยกตัวอย่าง พอยท์หมดอายุสิ้นเดือน $1 ตั้งแจ้งเตือนล่วงหน้า 10 วัน สมาชิกจะได้รับการแจ้งเตือนพอยท์หมดอายุวันที่ $2",
	noti_expiry_points_note:
		"การตั้งค่าแจ้งเตือนพอยท์หมดอายุพอยท์ล่วงหน้า จะคิดค่าบริการการแจ้งเตือนเป็นจำนวนตามการใช้จริง",
	non_setting_expiry_points: "ไม่หมดอายุ",
	non_setting_expiry_points_detail: "พอยท์ไม่มีหมดอายุ",
	select_number_day: "เลือกจำนวนวัน (1-30)",
	month_setting_expiry_points: "แบบทยอยหมดอายุในแต่ละเดือน",
	month_setting_expiry_points_detail:
		"ทำการดึงพอยท์คืนเป็นรายเดือน นับจากเดือนที่ได้รับพอยท์" +
		"\nยกตัวอย่าง ได้รับพอยท์ในเดือน $1 พอยท์มีอายุ $2 เดือน และพอยท์จะหมดอายุในวันสุดท้ายของเดือน $3",
	select_number_month: "เลือกจำนวนเดือน",
	year_setting_expiry_points: "ตั้งค่าพอยท์หมดอายุแบบปี",
	year_setting_expiry_points_detail:
		"ทำการดึงพอยท์คืนเป็นรายปี นับจากปีที่ได้รับพอยท์" +
		"\nยกตัวอย่าง ได้รับพอยท์ในปี $1 พอยท์มีอายุ $2 ปี และพอยท์จะหมดอายุในวันสุดท้ายของปี $3",
	select_number_year: "เลือกจำนวนปี",
	custom_setting_expiry_points: "กำหนดวันหมดอายุ",
	custom_setting_expiry_points_detail:
		"ทำการดึงพอยท์คืนทั้งหมด ในวันสุดท้ายของเดือนและปีที่ระบุ" 
    + "\nยกตัวอย่าง พอยท์จะหมดอายุในวันสุดท้ายของเดือน $1",
	select_month_year: "เลือกเดือน / ปี ค.ศ.",
	expiry_points_note: "การตั้งค่าวันหมดอายุพอยท์ สามารถเลือกได้เพียงครั้งเดียว",
	expiry_confirm: "คุณต้องการกำหนดวันหมดอายุของพอยท์ ใช่หรือไม่",
	expiry_noti_confirm: "คุณต้องการกำหนดการแจ้งเตือนพอยท์หมดอายุ ใช่หรือไม่",
	points_expiration_noti: "แจ้งเตือนพอยท์หมดอายุ",

	//Archive Business
	archive_business_menu: "ลบธุรกิจ",
	archive_business_title: "ลบธุรกิจ",
	archive_warning: {
		title: "เมื่อคุณยืนยันที่จะลบธุรกิจ หมายความว่าคุณได้เข้าใจถึงข้อกำหนดเหล่านี้",
		lists: [
			"สมาชิกจะไม่สามารถเห็นธุรกิจของคุณ",
			"ข้อมูลสมาชิกที่ลงทะเบียนกับธุรกิจจะถูกยกเลิก",
			"ลบธุรกิจออกจากระบบ Pointspot",
		],
		confirm_message1: "กรุณาใส่รหัสธุรกิจ (แสดงด้านบน) เพื่อยืนยันการลบธุรกิจ",
		confirm_message2: "กรุณาใส่รหัสธุรกิจ (แสดงด้านบน) เพื่อขอรหัส OTP",
	},
	archive_with_trial_process: "เป็นการทดลองใช้งานและจะสมัครใช้จริงภายหลัง",
	archive_with_not_looking_for: "ไม่ใช่สิ่งที่กำลังมองหา",
	archive_with_no_feature_need: "ไม่มีฟีเจอร์ที่ต้องการใช้งาน",
	archive_with_no_support: "ไม่ได้รับการช่วยเหลือจากเจ้าหน้าที่",
	tell_us: "กรุณาบอกเหตุผลที่คุณต้องการลบธุรกิจ เพื่อช่วยให้เราพัฒนาแพลตฟอร์มได้ดียิ่งขึ้น",
	otp_confirm_archive: "ยืนยันรหัส OTP เพื่อลบธุรกิจ",
	otp_sendTo_mobile: "OTP ถูกส่งไปยังเบอร์โทรศัพท์ที่ใช้ยืนยันธุรกิจ",

	//Package
	package: "แพ็กเกจ",
	title_free_package: "ฟรี",
	title_shappy_package: "SHAPPY+",
	title_free_trial_package: "ฟรี",
	title_pro_package: "โปร",
	title_premium_package: "พรีเมียม",
	title_gold_package: "โกลด์",
	title_platinum_package: "T15",
	title_diamond_package: "ไดมอนด์",
	title_enterprise_package: "ธุรกิจ",
	title_max_package: "T30",
	title_test_package: "เทส",
	title_f32_package: "F32",
	title_starter_package: "Starter",
	title_advance_package: "Professional",
	title_annual_l_package: "Enterprise",
	title_pro_a_package: "Pro-A",
	title_premium_a_package: "Premium-A",
	title_f200_package: "F200",

	pricing_sale_until: "ราคาถึงวันที่",
	pricing_package: "ราคาแพ็กเกจ",
	unlimited: "ไม่จำกัดจำนวน",
	current_usage_package: "แพ็กเกจปัจจุบัน",
	monthly_fee_package: "ค่าธรรมเนียมรายเดือน",
	yearly_fee_package: "ค่าธรรมเนียมรายปี",
	member_package: "สมาชิก",
	member_unit: "สมาชิก",
	monthly_free_credit: "เครดิตฟรีต่อเดือน",
	monthly_free_credits: "เครดิตฟรีรายเดือน",
	add_ons_credit: "เครดิตเสริม",
	member_usage_description: "นับจำนวนสมาชิกจาก สมาชิกที่ได้รับบัตรสมาชิก หรือ สมาชิกที่ลงทะเบียนและมีพอยท์",
	transaction_package: "รายการ",
	transaction_unit: "รายการ",
	transaction_usage_description:
		"นับจำนวนรายการจากรายการส่งพอยท์ รายการดึงพอยท์ รายการส่งคูปองโปรโมชั่น รายการคูปองแนบท้าย รายการบัตรกำนัล และรายการออกบัตรสมาชิก",
	transaction_annual_usage_description:
		"นับจำนวนรายการจากรายการส่งพอยท์ ดึงพอยท์ ออกบัตรสมาชิก ส่งคูปองโปรโมชั่น ส่งคูปองแนบท้าย ส่งบัตรกำนัล บรอดแคสต์ แจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ แจ้งเตือนพอยท์หมดอายุล่วงหน้า และแจ้งเตือนบัตรสมาชิกหมดอายุล่วงหน้า",
	transaction_yearly_usage_description:
		"นับจำนวน Basic Transactions จาก รายการส่งพอยท์ รายการดึงพอยท์ และรายการออกบัตรสมาชิก",
	transaction_yearly_credit_usage_description:
		"นับจำนวนเครดิตจาก การบรอดแคสต์ การส่งคูปองโปรโมชั่น การส่งคูปองแนบท้าย การส่งบัตรกำนัล และรายการส่วนที่เกินจากแพ็กเกจ",
	chunk_description: "Chunk คือหน่วยย่อยของข้อความ SMS",
	feature_package: "คุณสมบัติเพิ่มเติม",
	add_on_package: "ส่วนเสริม",
	choose_package: "เลือกแพ็กเกจ",
	prices_vat: "ราคายังไม่รวมภาษีมูลค่าเพิ่ม",
	package_upgrade: "อัปเกรด",
	change_package: "เลือกแพ็กเกจ",
	request_change_package: "เปลี่ยนแพ็กเกจ",
	currency: {
		thb: "บาท",
	},
	upgrade_subscription_successfully: "อัปเกรดเรียบร้อยแล้ว",
	cancle_subscription_successfully: "ยกเลิกเรียบร้อยแล้ว",
	name_unit: "รายชื่อ",
	notification_package: "การแจ้งเตือน",
	additional_sms: "SMS",
	additional_line_noti: "LINE",
	message_unit: "ข้อความ",
	update_payment_info_successfully: "อัปเดตข้อมูลเรียบร้อยแล้ว",
	notification_usage_description: "นับจำนวนรายการที่มีการส่งข้อความแจ้งเตือน เฉพาะรายการส่วนที่เกินจากแพ็กเกจ",
	chunk_unit: "Chunk",
	chunk: "Chunk",

	//Package FREE
	per_month: "ต่อเดือน",
	per_year: "ต่อปี",
	free: "ฟรี",
	package_free: "ฟรี",
	free_up_to: "ฟรีถึง",
	transaction_month: "รายการ / เดือน",
	transaction_over_quota: "จำนวนรายการคงเหลือไม่เพียงพอ",

	//Package BASIC
	package_basic: "เบสิก",

	//Package PRO
	package_pro: "โปร",
	first_members: "500 สมาชิกแรก",
	members_onward: "สมาชิกที่ {0} เป็นต้นไป",
	per_member_month: "ต่อสมาชิก / เดือน",
	first_transaction: "500 รายการแรก",
	transaction_onward: "รายการที่ {0} เป็นต้นไป",
	per_transaction_month: "ต่อรายการ / เดือน",
	package_pro_start: "เริ่มใช้งานวันที่ 1 กรกฎาคม 2562",
	payment_method: "ช่องทางการชำระเงิน",
	show_detail: "แสดงรายละเอียด",
	package_features: {
		premium_id: "Premium ID เช่น @yourbrand",
		transfer_points_to_friend: "โอนพอยท์ให้เพื่อน",
		promotional_coupons: "คูปองโปรโมชั่น",
		attached_coupons: "คูปองแนบท้าย",
		member_loyalty: "รายงานลูกค้าประจำ",
		transaction_history_seperated_by_admin: "รายงานประวัติรายการโดยผู้ดูแลระบบ",
		lucky_wheel: "เกมวงล้อเพิ่มพอยท์",
		custom_sender: "เปลี่ยนชื่อผู้ส่ง SMS",
		gift_voucher: "บัตรกำนัล",
		broadcast_message: "บรอดแคสต์ข้อความ",
		product_level_points: "รองรับระบบพอยท์รายสินค้า",
		product_management: "ระบบพอยท์รายสินค้า และรายงานสินค้า",
		automation_coupon: "Automation คูปอง",
		automation_member_card: "Automation บัตรสมาชิก",
		broadcast_line_flex: "บรอดแคสต์รูปภาพ",
		custom_role: "บทบาทผู้ดูแลแบบกำหนดเอง",
		custom_form: "แบบฟอร์มลงทะเบียน",
		advance_points_expire_notification: "แจ้งเตือนพอยท์หมดอายุล่วงหน้า",
		advance_member_card_expire_notification: "แจ้งเตือนบัตรสมาชิกหมดอายุล่วงหน้า",
		member_card_level: "ประเภทบัตรสมาชิก",
	},
	package_items: {
		overage_basic_transaction: "Basic Transactions ส่วนเกิน",
		monthly_fee_package: "ค่าธรรมเนียมรายเดือน",
		member_package: "สมาชิก",
		transaction_package: "รายการ",
		broadcast_message: "บรอดแคสต์ข้อความ",
		premium_id: "Premium ID",
		sender_name: "ชื่อผู้ส่งข้อความ",
		product_level_points: "ระบบพอยท์รายสินค้า",
		additional_transaction: "รายการ",
		additional_sms: "การแจ้งเตือน - SMS",
		additional_email: "การแจ้งเตือน - EMAIL",
		additional_shappy: "การแจ้งเตือน - SHAPPY",
		additional_line_noti: "การแจ้งเตือน - LINE",
		additional_sms_broadcast: "บรอดแคสต์ - SMS",
		additional_email_broadcast: "บรอดแคสต์ - EMAIL",
		additional_line_broadcast: "บรอดแคสต์ - LINE",
		additional_shappy_broadcast: "บรอดแคสต์ - SHAPPY",
		additional_member: "สมาชิก",
		additional_sms_inter: "เบอร์ต่างประเทศ - SMS",
		advance_apis: "Advance APIs",
		additional_coupon_transaction: "คูปอง/บัตรกำนัล",
		additional_coupon_sms: "คูปอง/บัตรกำนัล - SMS",
		additional_coupon_line: "คูปอง/บัตรกำนัล - LINE",
		additional_coupon_email: "คูปอง/บัตรกำนัล - EMAIL",
		additional_coupon_shappy: "คูปอง/บัตรกำนัล - SHAPPY",
		not_send_notification: "ส่งแบบไม่แจ้งเตือน",
		broadcast: "บรอดแคสต์",
		notification: "การแจ้งเตือน",
		additional_sms_collect_points: "การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - SMS",
		additional_line_collect_points: "การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - LINE",
		additional_email_collect_points: "การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - EMAIL",
		additional_shappy_collect_points: "การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - SHAPPY",
		points_collect_requests_rejected: "การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ",
		advance_points_expiration_notifications: "การแจ้งเตือนพอยท์ใกล้หมดอายุ",
		additional_sms_points_expiration: "การแจ้งเตือนพอยท์ใกล้หมดอายุ - SMS",
		additional_line_points_expiration: "การแจ้งเตือนพอยท์ใกล้หมดอายุ - LINE",
		additional_email_points_expiration: "การแจ้งเตือนพอยท์ใกล้หมดอายุ - EMAIL",
		additional_shappy_points_expiration: "การแจ้งเตือนพอยท์ใกล้หมดอายุ - SHAPPY",
	},
	package_items_unit: {
		transaction_unit: "รายการ",
		name_unit: "รายการ",
		month: "เดือน",
		member_unit: "สมาชิก",
	},
	charge_automatically: "ชำระเงินอัตโนมัติ",
	send_invoice: "ส่งใบแจ้งหนี้",
	usage_history: "ประวัติการใช้งาน",
	credit_purchase_history: "ประวัติการเติมเครดิต",

	// Credit Card
	you_want_delete_credit_card: "คุณต้องการลบบัตรหรือไม่ ?",
	add_credit_card: "เพิ่มบัตร",
	credit_card_number: "หมายเลขบัตร",
	when_doing: "เมื่อทำการ",
	you_understand_and_accept: "ท่านเข้าใจและยอมรับ",
	cardholder_name: "ชื่อผู้ถือบัตร",
	expiration_month: "เดือนที่หมดอายุ",
	expiration_year: "ปีที่หมดอายุ",
	delete_credit_card: "ลบบัตร",
	create_card_error_message: "เกิดข้อผิดพลาดขณะประมวลผลบัตรของคุณ ลองใหม่อีกครั้ง",
	card_primary: "บัตรหลัก",
	card_mark_primary: "ตั้งเป็นบัตรหลัก",

	//API Token
	credential_title: "API Credentials",
	credential_detail: "สร้าง Credential สำหรับการเข้าถึง API ของธุรกิจคุณ",
	btn_create_credential: "สร้าง Credential",
	credential_params: {
		clientid: "Client ID",
		create_date: "วันที่สร้าง",
		seen_date: "ใช้งานครั้งสุดท้าย",
		device_os: "สร้างจาก",
	},
	use_token: "Token สำหรับการเข้าถึง API",
	confirm_message_revoke: "คุณต้องการยกเลิก token นี้หรือไม่ ?",
	revoke: "ยกเลิก",
	revoke_successfully: "ยกเลิกเรียบร้อยแล้ว",
	web_restriction: "Website restriction",
	restriction_description: "กำหนด key ที่ใช้ในการเข้าถึงเว็บไซต์",
	restriction: "Restriction",
	all_access: "หากไม่กำหนด restriction จะยินยอมการเข้าถึงจากทุกเว็บไซต์",

	//Unsubscribe Confirm
	unsubscribe_confirm: {
		card_title: "ยืนยันการยกเลิกรับข่าวสาร",
		email_confirm: 'คุณได้ยกเลิกการรับข่าวสาร "สรุปสถิติประจำวัน" เรียบร้อยแล้ว',
		review_message_confirm: 'คุณได้ยกเลิกการรับข่าวสาร "สรุปคะแนนความพึงพอใจและความคิดเห็น" เรียบร้อยแล้ว',
	},

	// payment contact
	add_contact: "เพิ่มที่อยู่",
	contact_invoice: "ที่อยู่ใบแจ้งหนี้",
	contact_receipt: "ที่อยู่ใบเสร็จรับเงิน",
	address: "ที่อยู่",
	address_number: "เลขที่",
	village_number: "หมู่ที่",
	village: "หมู่บ้าน",
	lane: "ซอย",
	road: "ถนน",
	district: "แขวง/ตำบล",
	city: "เขต/อำเภอ",
	state: "จังหวัด",
	postal_code: "รหัสไปรษณีย์",
	country: "ประเทศ",
	_sub_district: "แขวง",
	_district: "เขต",
	province: "จังหวัด",
	please_fill_detail_english: "กรุณากรอกรายละเอียดเป็นภาษาอังกฤษเท่านั้น",
	please_fill_information: "กรุณากรอกข้อมูล",
	please_fill_information_english: "กรุณากรอกข้อมูลเป็นภาษาอังกฤษ",
	please_fill_information_number: "กรุณากรอกข้อมูลเป็นตัวเลข",
	please_fill_information_format_phone: "กรุณากรอกข้อมูลเบอร์โทรศัพท์",

	//payment method
	add_payment_method: "เพิ่มช่องทางการชำระเงิน",
	credit_card: "บัตรเครดิต",
	change_credit_card: "เปลี่ยนบัตรเครดิต",
	change_credit_card_success: "เปลี่ยนบัตรเครดิตเรียบร้อย",
	change_credit_card_fail: "เปลี่ยนบัตรเครดิตไม่สำเร็จ",
	debit_card: "บัตรเดบิต",
	select: "เลือก",
	payment_failed: "ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",

	// subscription
	packages: "แพ็กเกจ",
	package_and_charge: "แพ็กเกจและค่าบริการ",
	package_title: "Packages",
	subscription_package: "สมัครแพ็กเกจ",
	unsubscription_package: "ยกเลิกสมัครแพ็กเกจ",
	change_subscribed_package: "เปลี่ยนแพ็กเกจ",
	sub_total: "รวมเงิน",
	vat: "ภาษีมูลค่าเพิ่ม",
	monthly_fee: "ค่าบริการรายเดือน",
	annual_fee: "ค่าบริการรายปี",
	total_balance: "ยอดสุทธิ",
	current_balance_pending: "ยอดการใช้งานระหว่างรอบบิล",
	usage_summary: "ยอดการใช้งานเดือน",
	example_invoice: "ตัวอย่าง: ใบแจ้งหนี้ระหว่างรอบบิล",
	trial: "ทดลองใช้งาน",
	might_be_changed: "อาจมีการเปลี่ยนแปลง หากมีการอัปเดท",
	period_end: "สิ้นสุดรอบบิล",
	usage_detail: "รายละเอียดการใช้งาน",
	data_on: "ข้อมูล ณ วันที่",
	unsubscription_package_confirm: "คุณต้องการยกเลิกสมัครแพ็กเกจ ?\nค่าบริการจะถูกเรียกเก็บเงินหลังจากยกเลิกแพ็กเกจ",
	unsubscription_package_payment_failed: "คุณไม่สามารถยกเลิกสมัครแพ็กเกจได้ เนื่องจากการชำระเงินไม่สำเร็จ",
	unable_show_packages: "ไม่สามารถแสดงรายละเอียดแพ็กเกจได้",
	addons: "ส่วนเสริม",
	add_subscription_addons: "เพิ่มส่วนเสริม",
	premium_id: "Premium ID",
	premium_id_description: "สามารถเปลี่ยนลิงก์ธุรกิจให้แสดงเป็นชื่อที่กำหนดได้",
	broadcast_addons: "Broadcast information",
	broadcast_message_description: "สามารถใช้งานบรอดแคสต์เพื่อแจ้งข้อมูลไปยังลูกค้าของคุณได้",
	broadcast_transaction_usage: "นับจำนวนรายการบรอดแคสต์โดย SMS 3 chunks / 1 รายการ และ LINE 1 ข้อความ / 1 รายการ",
	broadcast_transaction_limit_100: "จำกัดจำนวนรวม 100 รายการ",
	billing_overage: "ค่าบริการส่วนเกิน",
	billing_item: "รายการ",
	billing_quantity: "จำนวน",
	billing_unit_price: "ราคาต่อหน่วย",
	billing_unit_credit: "เครดิตต่อหน่วย",
	billing_amount: "ราคา",
	subscription_coupons: {
		internal_account: "บัญชีภายใน",
		system_change_package: "ส่วนลดปรับแพ็กเกจ",
		special_discount: "ส่วนลดพิเศษ",
		first_month_25_discount: "ส่วนลด 25% สำหรับการอัปเกรดในเดือนแรก",
		first_month_50_discount: "ส่วนลด 50% สำหรับการอัปเกรดในเดือนแรก",
		first_month_75_discount: "ส่วนลด 75% สำหรับการอัปเกรดในเดือนแรก",
		covid_discount: "ส่วนลดพิเศษช่วงโควิด-19",
		trial_package: "ส่วนลดบัญชีทดลองใช้",
	},
	credit_use: "เครดิตที่ใช้",
	credit_not_enough: "เครดิตของคุณไม่เพียงพอ",
	credit_low: "เครดิตของคุณใกล้หมด กรุณาเติมเครดิตเพื่อการใช้งานอย่างต่อเนื่อง",
	transactions_low:
		"คุณได้ใช้งาน Transaction ใกล้ครบตามปริมาณที่ได้รับจากแพ็กเกจ กรุณาเติมเครดิตเพื่อการใช้งานที่ต่อเนื่อง",
	purchase_credit: "ซื้อเครดิต",
	contact_upgrade: "ต้องการอัพเกรดแพ็กเกจ",
	contact_purchase: "ต้องการซื้อเครดิตเพิ่ม",
	contact_call: "กรุณาติดต่อเจ้าหน้าที่",
	contact_staff: "ติดต่อเจ้าหน้าที่",
	contact_number: "โทร. 02-016-6900",
	usage_report: "รายงานการใช้งาน Pointspot",

	// usage record
	temporary_out_of_service: "หยุดให้บริการชั่วคราว",
	temporary_exceeding_quotas: "ปริมาณการใช้งานกำลังจะเกินโควต้า",
	usage_in: "ปริมาณการใช้งานเดือน",
	member_usage: "จำนวนสมาชิก",
	transaction_usage: "จำนวนรายการ",
	over_usage: "ปริมาณการใช้งานเกินโควต้า",
	over_usage_plz_upgrade: "ปริมาณการใช้งานเกินโควต้า กรุณาอัปเกรด",

	// Business Poster
	business_poster: "โปสเตอร์ธุรกิจ",
	poster_description: "สมัครสมาชิกสะสมแต้ม\nแลกของรางวัล",
	scan_qrcode: `สแกน QR Code`,
	scan_qrcode_description: `เพื่อสมัครสมาชิกและดูของรางวัล\nสมัครฟรี! ง่าย ด้วยเบอร์โทรศัพท์`,
	goto_website: `หรือไปที่เว็บไซต์`,
	supports_working_on_computer: "รองรับการทำงานบนหน้าจอคอมพิวเตอร์เท่านั้น",

	// Attached Coupon
	receipt_coupon: "คูปองแนบท้าย",
	receipt_coupons: "คูปองแนบท้าย",
	attached_coupon: "คูปองแนบท้าย",
	create_attached_coupon: "สร้างคูปองแนบท้าย",
	select_coupon: "เลือกคูปอง",
	edit_coupon: "แก้ไขคูปอง",
	select_attached_coupon: "เลือกคูปองแนบท้าย",
	redeem_rewards: "แลกของรางวัล",
	expired_coupon: "คูปองหมดอายุ",
	"pointspot/ecard-expire": "คูปองหมดอายุ",
	"pointspot/ecard-cancel": "คูปองถูกยกเลิก",
	"pointspot/points-not-enough": "พอยท์ไม่เพียงพอ",
	expired_after_receiving_coupon: "หมดอายุหลังจากได้รับคูปอง",
	after_receiving_coupon: "หลังจากได้รับคูปอง",
	set_expired_date_after_receiving_coupon: "ตามจำนวนวัน",
	attached_coupon_name: "ชื่อคูปองแนบท้าย",
	attached_coupon_description: "รายละเอียดคูปองแนบท้าย",
	attached_coupon_type: "ประเภทคูปองแนบท้าย",
	attached_coupon_image: "รูปภาพคูปองแนบท้าย",
	add_attached_coupon: "เพิ่มคูปองแนบท้าย",
	create_attached_coupon_successfully: "สร้างคูปองแนบท้ายเรียบร้อย",
	edit_attached_coupon_successfully: "แก้ไขคูปองแนบท้ายเรียบร้อย",
	delete_attached_coupon_successfully: "ลบคูปองแนบท้ายเรียบร้อย",
	want_to_delete_attached_coupon: "คุณต้องการลบคูปองแนบท้ายหรือไม่ ?",
	attached_coupon_not_found: "ไม่พบคูปองแนบท้าย",

	//ReportUsageAdmin
	report_admin: "ประวัติรายการโดยผู้ดูแลระบบ",
	report_usage_admin: "รายงานประวัติรายการผู้ดูแลระบบ",
	report_usage_admin_sub: "รายงานแสดงประวัติรายการของผู้ดูแลระบบ",
	transaction_date: "วันที่ทำรายการ",
	admin_name: "ชื่อผู้ดูแลระบบ",
	total_transactions: "รวมรายการ",
	error_occurred: "พบข้อผิดพลาด",
	please_enter_redeemcode: "กรุณาระบุรหัสคูปอง",
	not_found: "ไม่พบ",
	export_data: "ส่งออกข้อมูล",
	export_data_all: "ส่งออกข้อมูลทั้งหมด",
	account_admin: "ผู้ดูแลระบบ",
	account_pincode: "ผู้ใช้งานรหัส",
	account_origin: "ชื่อโดเมน",
	account_credential: "API Credential Client ID",
	account_automation: "Automation",

	//ReportAdmin
	admin_report: "รายงานผู้ดูแลระบบ",
	admin_report_description: "รายงานสรุปข้อมูลผู้ดูแลระบบ",
	registered_members: "สมาชิกที่สมัคร",
	total_member: "สมาชิกทั้งหมด",
	total_points_send: "พอยท์ที่ส่ง",
	total_points_pull: "พอยท์ที่ดึง",
	member_unit_report: "ราย",

	// Welcome Page
	welcome: "ยินดีต้อนรับ",
	home_page: "หน้าหลัก",
	welcome_page: "คู่มือสำหรับผู้เริ่มต้น",
	welcome_to: "ยินดีต้อนรับเข้าสู่ระบบ Pointspot",
	how_to_pointspot: "เรียนรู้การใช้งาน Pointspot",
	features_basic_pointspot: "คุณสมบัติเบื้องต้น Pointspot",
	hide_home_page: "ซ่อนหน้าหลัก",
	hide_guide: "ซ่อน คู่มือสำหรับผู้เริ่มต้น",
	intro_welcome:
		"เริ่มใช้ Pointspot ครั้งแรก? นี่คือคู่มือเบื้องต้นที่จะช่วยให้คุณเรียนรู้การใช้งาน\nและนำระบบสะสมพอยท์ด้วยเบอร์โทรศัพท์ ไปเริ่มใช้กับธุรกิจของคุณได้เร็วขึ้น",
	topic_send_point: "พอยท์",
	intro_send_point: "ส่งพอยท์แบบสะดวกและรวดเร็ว",
	button_send: "ส่งพอยท์",
	topic_reward: "ของรางวัล",
	intro_reward: "สร้างของรางวัลสำหรับแลกพอยท์",
	button_reward: "สร้างของรางวัล",
	topic_contact: "ข้อมูลการติดต่อ",
	intro_contact: "จัดการข้อมูลการติดต่อของธุรกิจ",
	button_contact: "ข้อมูลการติดต่อ",
	topic_poster: "โปสเตอร์ธุรกิจ",
	intro_poster: "สร้างโปสเตอร์เพื่อใช้โปรโมท",
	button_poster: "สร้างโปสเตอร์",
	verify_for_free: "กรุณาเปิดใช้งานธุรกิจเพื่อเริ่มใช้งาน Pointspot",
	intro_transaction: "แสดงประวัติการทำรายการ",
	intro_welcome_verified:
		"หน้าหลักคือ คุณสมบัติเบื้องต้นที่จะช่วยให้คุณเรียนรู้การใช้งาน\nและนำระบบสะสมพอยท์ด้วยเบอร์โทรศัพท์ ไปเริ่มใช้กับธุรกิจของคุณได้เร็วขึ้น",

	// Wizard
	business_brand: "ชื่อธุรกิจ / แบรนด์",
	your_business_brand: "ชื่อธุรกิจ / แบรนด์ ของคุณ",
	enter_information_create_business: "กรอกข้อมูลเพื่อเริ่มสร้างธุรกิจ",
	please_enter_your_business: "กรุณากรอกชื่อธุรกิจ / แบรนด์ มากกว่า 5 ตัวอักษร",

	// Select Business
	choose_your_business: "เลือกธุรกิจที่ต้องการเข้าใช้งาน",
	sign_in_with_another_account: "ลงชื่อเข้าใช้ด้วยบัญชีอื่น",
	verification_sent: "กรุณากรอกรหัสยืนยันที่ถูกส่งไปยังอีเมล",
	confirm_sending_otp: "ยืนยันตัวตนด้วย OTP",
	confirm_choose_business: "เพื่อเป็นการเพิ่มความปลอดภัยในการเข้าใช้งานธุรกิจ <b>$1</b>",
	send_to_email: "ระบบจะส่ง OTP ไปยังอีเมล <b>$1</b>",
	send_to_mobile_phone: "ระบบจะส่ง OTP ไปยังเบอร์โทร <b>$1</b>",
	verify_ref: "รหัสอ้างอิง",
	verify_button: "ยืนยัน",
	send_otp: "ส่ง OTP",
	send_email_again: "ส่งรหัสอีกครั้ง",

	// Tutorial
	tutorial: "แนะนำการใช้งาน",
	tutorial_welcome: "ยินดีต้อนรับเข้าสู่ Pointspot",
	start_tutorial: "เริ่มพาทัวร์ได้เลย",
	tutorial_detail:
		"ยินดีด้วย! คุณได้สร้างธุรกิจบนระบบ Pointspot สำเร็จแล้ว! ขั้นตอนต่อไป เราขอแนะนำฟีเจอร์หลักให้คุณได้รู้จัก และเรียนรู้การใช้งาน เพื่อให้คุณนำ Pointspot ไปเริ่มใช้จริงกับธุรกิจได้เร็วขึ้น",
	tutorial_step_detail: [
		{
			title: `ส่งพอยท์`,
			description: `คุณสามารถให้พอยท์กับสมาชิก\nหรือลูกค้าใหม่ได้ง่าย ๆ โดยไปที่เมนู “ส่งพอยท์”`,
		},
		{
			title: `ส่งพอยท์`,
			description: `กรอกเบอร์โทรศัพท์ หรือสแกน QR Code\nของสมาชิกที่ต้องการส่งพอยท์ให้`,
		},
		{
			title: `ส่งพอยท์`,
			note: `อัตราการแปลงพอยท์สามารถกำหมดใหม่ได้ภายหลัง`,
			description: `กรอกจำนวนเงิน\nเพื่อเปลี่ยนแปลงเป็นพอยท์ให้สมาชิก`,
		},
		{
			title: `ส่งพอยท์`,
			description: `คลิกที่ปุ่ม "ส่งพอยท์" ระบบจะทำการส่งการแจ้งเตือน\nไปยังเบอร์โทรศัพท์ที่กรอกไว้\nเพื่อให้สมาชิกตรวจสอบการรับพอยท์`,
		},
		{
			title: `ของรางวัลสะสมแต้ม`,
			description: `ต่อไป คือเมนู "ของรางวัลสะสมแต้ม"\nที่ธุรกิจสามารถเพิ่มหรือบริหารจัดการ\nของรางวัลสำหรับให้สมาชิกแลกได้ที่นี่`,
		},
		{
			title: `ของรางวัลสะสมแต้ม`,
			description: `ตัวอย่างของรางวัล\nซึ่งคุณสามารถทดลองแลกรับของรางวัล\nหรือสร้างของรางวัลเพิ่มได้ภายหลัง`,
		},
		{
			title: `ใช้งานคูปอง`,
			description: `เมื่อสมาชิกแลกของรางวัลแล้ว\nต่อไป คลิกที่เมนู "ใช้งานคูปอง"\nเพื่อใช้งานคูปองของรางวัล`,
		},
		{
			title: `ใช้งานคูปอง`,
			description: `กรอกรหัสคูปอง หรือสแกน OR Code\nเพื่อใช้งานคูปอง`,
		},
		{
			title: `โปสเตอร์ธุรกิจ`,
			description: `โปรโมท Pointspot ให้ลูกค้าใหม่\nได้รู้จักด้วยการสร้างโปสเตอร์\nคลิกที่เมนู “โปสเตอร์ธุรกิจ”`,
		},
		{
			title: `โปสเตอร์ธุรกิจ`,
			description: `โปสเตอร์สำเร็จรูปที่คุณสามารถดาวน์โหลด\nเพื่อนำไปแสดงที่ธุรกิจของคุณ`,
		},
		{
			title: `โปสเตอร์ธุรกิจ`,
			description: `และโปสเตอร์อีกดีไซน์ ที่คุณสามารถอัปโหลด\nรูปภาพธุรกิจของคุณ ให้มีความสวยงาม`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `ให้สมาชิก หรือลูกค้าใหม่ได้รู้จักธุรกิจของคุณ\nคลิกที่เมนู "ข้อมูลธุรกิจ" เพื่ออัปเดท\nข้อมูลธุรกิจของคุณ`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `อัปโหลดโลโก้ธุรกิจคุณ\nด้วยรูปภาพขนาด 1 : 1`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `กรอกรายละเอียดของธุรกิจ\nเพื่อให้สมาชิกเข้าใจรายละเอียดของธุรกิจคุณ`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `อัปโหลดรูปภาพของธุรกิจคุณ เช่น\nรูปหน้าร้าน บรรยากาศภายในร้าน\nหรือแบนเนอร์ร้าน ด้วยรูปภาพขนาด 2 : 1`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `อัปโหลดรูปภาพของธุรกิจคุณ\nเพื่อเป็นรูปภาพนามบัตรธุรกิจ`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `เลือกสีของธุรกิจ\nเพื่อสร้างการจดจำให้ธุรกิจของคุณ`,
		},
		{
			title: `ข้อมูลธุรกิจ`,
			description: `เมื่ออัปเดทข้อมูลธุรกิจเรียบร้อย\n คลิปที่ปุ่ม "บันทึก" เพื่อบันทึกข้อมูลธุรกิจ`,
		},
		{
			title: `เปิดใช้งานธุรกิจ`,
			description: `เมื่อสมัครใช้งาน แนะนำให้คุณเปิดใช้งานธุรกิจ\nเพื่อรับสิทธิประโยชน์ ในการใช้งานระบบ\n คลิกที่เมนู "เปิดใช้งานธุรกิจ"`,
		},
		{
			title: `เปิดใช้งานธุรกิจ`,
			description: `กรอกข้อมูลให้ครบทั้ง 3 หัวข้อ\nเพื่อเปิดใช้งานธุรกิจของคุณ\nและสามารถใช้งานในแพ็กเกจฟรีได้ทันที`,
		},
	],

	// News feed
	newsfeed: "News Feed",
	activities: "Activities",
	redeemed_reward: "ได้แลกของรางวัล",
	activated_reward: "ได้ใช้งานของรางวัล",
	get_giveaway: "ได้กดรับของรางวัลแจกฟรี",
	register_and_subscribe: "ได้สมัครสมาชิกธุรกิจของคุณ",
	pay_to_business: "ได้โอนพอยท์ให้ธุรกิจจำนวน {0} พอยท์",
	found_newsfeed: "พบ {0} รายการใหม่",
	notfound_newsfeed: "ไม่พบรายการใหม่",
	notfound_newsfeed_in_business: "ไม่พบรายการใหม่ ในธุรกิจของคุณ",
	to_know_movement_business: "เพื่อให้ทราบความเคลื่อนไหวในธุรกิจของคุณ\nแนะนำให้ส่งพอยท์ และสร้างของรางวัล",
	just_now: "เมื่อสักครู่",
	minute_ago: "นาทีที่แล้ว",
	minutes_ago: "นาทีที่แล้ว",
	hour_ago: "ชั่วโมงที่แล้ว",
	hours_ago: "ชั่วโมงที่แล้ว",

	// game
	game: "เกม",
	game_setting: "ตั้งค่าเกม",
	game_setting_sub: "ตั้งค่า เปิด/ปิด การเล่นเกมหลังลูกค้าของคุณได้รับพอยท์",
	game_sub_condition:
		'เมื่อเปิดใช้งานเกมวงล้อเพิ่มพอยท์ สมาชิกสามารถเข้าเล่นเกมวงล้อเพิ่มพอยท์จากการคลิกลิงก์ใน "การแจ้งเตือนได้รับพอยท์" เท่านั้น และ พอยท์ที่ได้จากการเล่นเกมวงล้อจะถูกเพิ่มให้สมาชิกทันที',
	earn_game: "ได้พอยท์จากการเล่นเกม",
	lose_game: "เสียพอยท์จากเกม",
	increase: "เพิ่ม",
	increase_points: "เพิ่มพอยท์",
	decrease: "ลด",
	decrease_points: "ลดพอยท์",
	lucky_wheel: "เกมวงล้อเพิ่มพอยท์",
	lucky_draw_wheel_guide:
		"แนะนำ : เมื่อทำการกดหมุน วงล้อจะหมุนและสุ่มหยุด ณ พื้นที่ใดพื้นที่หนึ่งของวงล้อ ผลลัพธ์ที่ได้คือตำแหน่งที่หมุดชี้อยู่",
	earn_points_more: "คุณได้รับพอยท์เพิ่ม",
	lose_points: "คุณถูกลดพอยท์",
	game_deal: "คุณไม่ได้รับพอยท์เพิ่ม",
	spin_draw: "หมุนวงล้อ",
	try_again_later: "ลองอีกครั้งในภายหลัง",
	congratulations: "ยินดีด้วย",
	email_verified: "อีเมลของคุณได้รับการยืนยันแล้ว",
	try_again: "ลองใหม่โอกาสหน้า",
	save_success: "บันทึกสำเร็จ",
	game_preview: "ตัวอย่างเกม",
	points_game_test: "ทดสอบการได้รับพอยท์",
	points_game_test_sub: "สมมติพอยท์ที่ลูกค้าได้รับเพื่อดูตัวอย่างวงล้อที่เกิดขึ้น",
	setting_minimum_points: "ตั้งค่าพอยท์ขั้นต่ำ",
	setting_minimum_points_sub: "กำหนดพอยท์ขั้นต่ำที่สมาชิกได้รับแล้วจะสามารถเล่นเกมได้",
	points_to_percent_ratio: "โดยระบบจะนำพอยท์ที่ได้รับมาคิดเป็นอัตราส่วนร้อยละ(%) และแสดงบนวงล้อ",
	setting_section_wheel: "ตั้งค่าสัดส่วนเกมวงล้อเพิ่มพอยท์",
	cal_ratio_description: "สัดส่วนจะถูกนำไปคิดเป็นร้อยละ (%) ของพอยท์ที่ได้รับ สามารถเพิ่มได้สูงสุด 8 ชิ้น",
	cal_waste_points: "เมื่อเปิดให้เล่นเกมจะใช้พอยท์เพิ่มโดยเฉลี่ย",
	add_piece_wheel: "เพิ่มชิ้นส่วนวงล้อ",
	orderly: "ตามลำดับ",

	// setting points
	setting_points: "ตั้งค่าพอยท์",
	pay_setting: "ตั้งค่าการโอนพอยท์ให้ธุรกิจ",
	pay_setting_sub: "ตั้งค่าเปิดปิดการโอนพอยท์ให้ธุรกิจ",
	transfer_setting: "ตั้งค่าการโอนพอยท์ให้เพื่อน",
	transfer_setting_sub: "ตั้งค่าเปิดปิดการโอนพอยท์ให้เพื่อน",
	transfer_expire_month: "พอยท์ที่ได้รับจากการโอนจะใช้ได้ถึงสิ้นเดือนนั้นเท่านั้น",
	transfer_expire_year: "พอยท์ที่ได้รับจากการโอนจะใช้ได้ถึงสิ้นปีนั้นเท่านั้น",

	// transaction_activated_coupon
	transaction_activated: "ประวัติการใช้งานคูปอง",
	transaction_activated_in_business: "ประวัติการใช้งานคูปองในธุรกิจ",
	transaction_activated_report: "รายงานประวัติการใช้งานคูปอง",
	activated_name: "ผู้ใช้งานคูปอง",
	coupon_activated_range: "ช่วงเวลาใช้คูปอง",
	coupon_activated_date: "วันที่ใช้งานคูปอง",

	// transaction_sent_coupon
	transaction_sent: "ประวัติการส่งคูปอง",
	transaction_sent_in_business: "ประวัติการส่งคูปองในธุรกิจ",
	transaction_sent_coupon_report: "รายงานประวัติการส่งคูปอง",
	sent_name: "ผู้ส่งคูปอง",
	coupon_sent_range: "ช่วงเวลาส่งคูปอง",
	coupon_sent_date: "วันที่ส่งคูปอง",

	// Setting Business Activate Coupon
	setting_activate_coupon: "การใช้คูปอง",
	swipe_client: "โดยการสไลด์บนหน้าจอ",
	pincode: "รหัสผู้ใช้",
	add_pincode: "เพิ่มรหัสผู้ใช้",
	setting_swipe_to_activate: "ตั้งค่าการสไลด์บนหน้าจอเพื่อใช้งานคูปอง",
	setting_swipe_to_activate_description: "สามารถใช้งานคูปอง ที่หน้าแสดงคูปองของสมาชิก โดยการสไลด์บนหน้าจอ",
	setting_pincode_to_activate: "ตั้งค่ารหัสผู้ใช้เพื่อใช้งานคูปอง",
	setting_pincode_to_activate_description: "สามารถใช้งานคูปอง ที่หน้าแสดงคูปองของสมาชิก โดยการกรอกรหัสผู้ใช้",
	pincode_info: "ข้อมูลรหัสผู้ใช้",
	not_found_pincode_info: "ไม่พบข้อมูลรหัสผู้ใช้",
	please_enter_more_than_5char: "กรุณากรอกข้อมูลมากกว่า 5 ตัวอักษร",
	you_want_delete_pincode: "คุณต้องการลบรหัสผู้ใช้หรือไม่ ?",
	swipe_note: "ข้อความอ้างอิง",
	show_activated_with_swipe: "แสดงเฉพาะที่ใช้งานโดยการสไลด์บนหน้าจอ",
	show_activated_with_delivery: "แสดงเฉพาะการใช้งานโดยการจัดส่ง",

	// RFM Analysis
	RFM_analysis: "RFM Analysis",
	RFM_analysis_report: "RFM Analysis report",
	RFM_analysis_description: "RFM Analysis description",
	RFM_recency: "Recency",
	RFM_frequency: "Frequency",
	RFM_monetary: "Monetary",
	RFM_group: "กลุ่ม",
	RFM_list: {
		champions: {
			name: "Champions",
			description: "กลุ่มลูกค้าที่มีการใช้บริการเมื่อไม่นานมานี้ มีการใช้บริการเป็นประจำ และมีพอยท์ที่ได้รับสูงที่สุด",
		},
		loyal_customers: {
			name: "Loyal Customers",
			description: "กลุ่มลูกค้าที่มีการใช้บริการบ่อยครั้ง และมีพอยท์ที่ได้รับสูงที่สุด",
		},
		potential_loyal_list: {
			name: "Potential Loyalist",
			description:
				"กลุ่มลูกค้าที่มีการใช้บริการเมื่อไม่นานมานี้ มีจำนวนครั้งที่ใช้บริการ และมีพอยท์ที่ได้รับระดับปานกลาง",
		},
		new_customers: {
			name: "New Customers",
			description: "กลุ่มลูกค้าที่เริ่มมีการใช้บริการเมื่อไม่นานมานี้",
		},
		promise: {
			name: "Promising",
			description:
				"กลุ่มลูกค้าที่มีการใช้บริการเมื่อไม่นานมานี้ แต่ยังมีการใช้บริการไม่บ่อย และมีพอยท์ที่ได้รับไม่มากนัก",
		},
		need_attention: {
			name: "Need Attention",
			description:
				"กลุ่มลูกค้าที่มีการใช้บริการมาระยะหนึ่งแล้ว มีจำนวนครั้งที่ใช้บริการ และมีพอยท์ที่ได้รับในระดับปานกลาง",
		},
		about_to_sleep: {
			name: "About to Sleep",
			description: "กลุ่มลูกค้าที่มีพอยท์ที่ได้รับน้อย มีการใช้บริการไม่บ่อย และเริ่มไม่กลับมาใช้บริการมาระยะหนึ่งแล้ว",
		},
		cant_lose_them: {
			name: "Can’t Lose Them",
			description: "กลุ่มลูกค้าที่มีพอยท์ที่ได้รับสูงสุด มีการใช้บริการเป็นประจำ แต่ไม่กลับมาใช้บริการระยะหนึ่งแล้ว",
		},
		at_risk: {
			name: "At Risk",
			description:
				"กลุ่มลูกค้าที่มีพอยท์ที่ได้รับ และมีจำนวนครั้งที่ใช้บริการในระดับปานกลาง แต่ไม่กลับมาใช้บริการระยะหนึ่งแล้ว",
		},
		hibernating: {
			name: "Hibernating",
			description: "กลุ่มลูกค้าที่มีพอยท์ที่ได้รับน้อย มีการใช้บริการไม่บ่อย และไม่กลับมาใช้บริการระยะหนึ่งแล้ว",
		},
	},
	RFM_desRFM: {
		recency: {
			name: "Recency",
			description: "จำนวนวันจากการที่ลูกค้าเข้ามาใช้บริการครั้งล่าสุด",
		},
		"frequency&monetary": {
			name: "Frequency & Monetary",
			description: "จำนวนครั้งที่ลูกค้ามาใช้บริการ และพอยท์ที่ได้รับของลูกค้า",
		},
	},
	RFM_start_tutorial:
		" RFM Analysis รายงานที่แบ่งกลุ่มสมาชิกที่อยู่ในธุรกิจ ออกเป็น 10 กลุ่ม\nเพื่อทราบถึงจำนวนรายการและจำนวนพอยท์ที่ได้รับ\nการกลับมาใช้งาน และค่าเฉลี่ยการใช้งานธุรกิจ",
	RFM_tutorial_step: [
		{
			description: `ประเภทของกลุ่มทั้งหมด\nที่ถูกแบ่งออกเป็น 10 กลุ่ม`,
		},
		{
			description: `จำนวนครั้งที่ลูกค้ามาใช้บริการ\nและพอยท์ที่ได้รับของลูกค้า`,
		},
		{
			description: `จำนวนวันจากการที่ลูกค้าเข้ามาใช้บริการครั้งล่าสุด`,
		},
		{
			description: `คุณสามารถคลิกเลือกกลุ่มที่ต้องการ\nเพื่อทราบคำอธิบายและแสดงรายละเอียด\nของสมาชิกที่อยู่ในกลุ่ม`,
		},
		{
			description: `จะแสดงชื่อ สัญลักษณ์\nและคำอธิบายและความหมายของกลุ่ม`,
		},
		{
			description: `แสดงรายะเอียดของสมาชิกในกลุ่ม\nจำนวนรายการและจำนวนพอยท์ที่ได้รับ\nการกลับมาใช้งาน และค่าเฉลี่ยการใช้งานธุรกิจ`,
		},
	],

	// Member card
	member_card: "บัตรสมาชิก",
	no_member_card: "ไม่มีบัตรสมาชิก",
	member_card_exist_same_card: `สมาชิกมีบัตร $1 อยู่แล้ว`,
	issue_member_card: "ออกบัตรสมาชิก",
	issue_member_card_successfully: "ออกบัตรสมาชิกสำเร็จ",
	issue_member_card_description: "ออกบัตรสมาชิกด้วยเบอร์โทรศัพท์ หรือ Pointspot ID",
	select_member_card: "เลือกบัตรสมาชิก",
	setting_member_card_type_on_off: "ตั้งค่าเปิด / ปิดประเภทบัตรสมาชิก",
	create_setting_member_card_type_detail: "สร้างประเภทบัตรสมาชิกในธุรกิจ",
	enable_setting_member_card: "เปิดการใช้งานตั้งค่าบัตรสมาชิก",
	disable_setting_member_card: "ปิดการใช้งานตั้งค่าบัตรสมาชิก",
	setting_member_card_type: "ตั้งค่าประเภทบัตรสมาชิก",
	type_member_card: "ประเภทบัตรสมาชิก",
	type_member_card_in_business: "ประเภทบัตรสมาชิกของธุรกิจ",
	type_member_card_in_business_not_found: "ไม่พบประเภทบัตรสมาชิกของธุรกิจ",
	please_add_type_member_card: "กรุณาเพิ่มประเภทบัตรสมาชิกที่เมนู",
	add_member_card_type: "เพิ่มประเภทบัตรสมาชิก",
	sort_member_card_type: "จัดลำดับประเภทบัตรสมาชิก",
	sort_member_card_type_successfully: "จัดลำดับประเภทบัตรสมาชิกสำเร็จ",
	edit_member_card_type: "แก้ไขประเภทบัตรสมาชิก",
	edit_member_card_infomation: "แก้ไขข้อมูลบัตรสมาชิก",
	update_member_card: "แก้ไขข้อมูลบัตรสมาชิก",
	example_member_card: "ตัวอย่างบัตรสมาชิก",
	member_card_type_name: "ชื่อประเภทบัตรสมาชิก",
	member_card_privilege: "สิทธิประโยชน์บัตรสมาชิก",
	member_card_style: "รูปแบบบัตรสมาชิก",
	member_card_bg_color: "สีพื้นหลัง",
	member_card_font_color: "สีตัวอักษร",
	member_card_upload_bg_image: "อัปโหลดรูปภาพพื้นหลังบัตรสมาชิก",
	member_card_size: "ขนาดบัตรสมาชิก : กว้าง 170px ยาว 300px",
	member_card_file_type: "ไฟล์ที่รองรับ : .jpg, .jpeg, .png",
	member_card_selected_fail: "กรุณาอัปโหลดไฟล์ที่มีนามสกุล .jpg, .jpeg หรือ .png",
	zoom: "ปรับขนาดรูปภาพ",
	rotate: "หมุน",
	member_card_expiry_date: "วันหมดอายุบัตรสมาชิก",
	set_member_card_expiry_only_new_card: "สามารถกำหนดวันหมดอายุบัตรสมาชิก ได้ในขั้นตอนการสร้างบัตรสมาชิกใหม่เท่านั้น",
	cant_set_member_card_expiry_automation: "ไม่มีวันหมดอายุตามการตั้งค่าของ Automation",
	member_receive_member_card_type: "สมาชิกที่ได้รับบัตรประเภท",
	canceled_cannot_use_member_card: "จะถูกยกเลิก และไม่สามารถใช้งานบัตรสมาชิกได้",
	member_card_type_non_expiry_date: "ไม่กำหนดวันหมดอายุ",
	member_card_type_expiry_date_after: "กำหนดวันหมดอายุ",
	after_receiving_member_card_3_month: "หลังจากได้รับบัตรสมาชิก 3 เดือน",
	after_receiving_member_card_6_month: "หลังจากได้รับบัตรสมาชิก 6 เดือน",
	after_receiving_member_card_12_month: "หลังจากได้รับบัตรสมาชิก 12 เดือน",
	after_receiving_member_card_24_month: "หลังจากได้รับบัตรสมาชิก 24 เดือน",
	create_member_card_type_successfully: "สร้างประเภทบัตรสมาชิกสำเร็จ",
	edit_member_card_type_successfully: "แก้ไขประเภทบัตรสมาชิกสำเร็จ",
	delete_member_card_type_successfully: "ลบประเภทบัตรสมาชิกสำเร็จ",
	change_member_card_type: "เปลี่ยนประเภทบัตรสมาชิก",
	change_member_card_type_successfully: "เปลี่ยนประเภทบัตรสมาชิกสำเร็จ",
	change_member_card_name_successfully: "แก้ไขชื่อของสมาชิกสำเร็จ",
	cancel_member_card: "ยกเลิกบัตรสมาชิก",
	cancel_member_card_successfully: "ยกเลิกบัตรสมาชิกสำเร็จ",
	you_want_cancel_member_card: "คุณต้องการยกเลิกบัตรสมาชิก",
	card_holder: "ชื่อผู้ถือบัตร",
	membercard_setting_description:
		"เมื่อเปิดใช้งานประเภทบัตรสมาชิก ธุรกิจสามารถที่จะสร้างประเภทบัตรสมาชิกและออกบัตรให้กับสมาชิกในธุรกิจของคุณได้",
	manual_member_card: "คำอธิบายการใช้งานบัตรสมาชิก",
	when_enabled_member_card: "การเปิดใช้งานประเภทบัตรสมาชิก\nมีผลต่อการคำนวณค่าบริการตามแพ็กเกจ",
	when_enabled_member_card_remark: "เมื่อเปิดใช้งานประเภทบัตรสมาชิก จะไม่สามารถปิดการใช้งานได้",
	enabled_member_card_usage:
		"สำหรับการคิดค่าบริการ จำนวนสมาชิกจะนับสมาชิกที่ถือบัตรร่วมด้วย\nและจำนวนรายการจะถูกนับการออกบัตรสมาชิกในแต่ละครั้ง",
	you_confirm_enable_member_card: "คุณต้องการเปิดการใช้งานประเภทบัตรสมาชิกหรือไม่ ?",
	manual_member_card_detail: [
		{
			title: "การตั้งค่าบัตรสมาชิก",
			detail: [
				"เมื่อเปิดใช้งานสามารถสร้างบัตรสมาชิกและแก้ไขบัตรสมาชิกได้",
				"สามารถแก้ไขบัตรสมาชิกได้โดยคลิกที่บัตรสมาชิก",
				"ธุรกิจสามารถเพิ่มบัตรสมาชิกได้สูงสุด 3 ประเภท",
			],
		},
		{
			title: "การออกบัตรสมาชิก",
			detail: [
				"สามารถออกบัตรให้กับสมาชิกได้โดยไปที่เมนูข้อมูลสมาชิก",
				"คลิกเลือกสมาชิกที่ต้องการจากนั้นคลิกออกบัตรสมาชิก โดยสามารถใส่ชื่อนามสกุลและเลือกประเภทบัตรสมาชิกได้",
				"เมื่อกดยืนยันระบบจะส่งข้อความแจ้งเตือนไปยังสมาชิก",
				"หลังจากออกบัตรสมาชิกสามารถเปลี่ยนบัตรหรือยกเลิกบัตรได้",
			],
		},
	],
	total_member_card: "จำนวนบัตรสมาชิก",
	gender_male: "ชาย",
	gender_female: "หญิง",
	gender_unspecified: "ไม่ระบุ",
	member_card_automation_setting: "ระบบบัตรสมาชิกอัตโนมัติ",
	member_card_automation_setting_description: "ระบบปรับระดับบัตรสมาชิกอัตโนมัติตามพอยท์ที่ได้รับ",
	member_card_automation_setting_confirmation: "กรุณายืนยันการตั้งค่าการปรับบัตรสมาชิกอัตโนมัติ",
	member_card_promotion_setting: "ส่งบัตรสมาชิก",
	member_card_promotion_setting_description: "ระบบจะส่งบัตรสมาชิกให้อัตโนมัติเมื่อสมาชิกได้รับพอยท์ถึงจำนวนที่กำหนดไว้",
	member_card_promotion_setting_time: "ระยะเวลาที่ใช้คำนวณพอยท์ที่ได้รับ",
	member_card_automation_status_success: "อัปเดตการตั้งค่าสำเร็จ",
	member_card_earned_point: "จำนวนพอยท์เพื่อรับบัตรแต่ละระดับ",
	member_card_earned_point_range: "ได้รับ {0} - {1} พอยท์",
	member_card_earned_point_range_last: "ได้รับ {0} พอยท์ขึ้นไป",
	member_card_earned_point_specific: "ได้รับ {0} พอยท์",
	member_card_earned_point_error_required: "จำนวนพอยท์เพื่อรับบัตร {0} ต้องมากกว่า {1}",
	member_card_promotion_remark: "* ค่าบริการจะคำนวณจากการเปลี่ยนประเภทบัตรสมาชิกตามการใช้งานจริง",
	member_card_promotion_edit_remark: "* การตั้งค่าจะถูกนำไปใช้กับสมาชิกที่ได้รับพอยท์ครั้งถัดไปเท่านั้น",
	member_card_not_available: "ไม่พบบัตรสมาชิก",
	member_card_not_available_click: "กดที่นี่เพื่อตั้งค่า",
	member_card_demotion_setting: "ปรับลดบัตรสมาชิก",
	member_card_demotion_setting_description: "ระบบจะปรับลดระดับบัตรสมาชิกอัตโนมัติตามอายุบัตรสมาชิกหรือพอยท์ที่ได้รับ",
	member_card_demotion_card_expiration: "วันหมดอายุของบัตรสมาชิก",
	member_card_demotion_earned_point: "จำนวนพอยท์ที่ได้รับ",
	member_card_demotion_earned_point_check_frequency: "ความถี่ในการตรวจสอบพอยท์ที่ได้รับ",
	member_card_demotion_1_month_check: "ตัดรอบการตรวจสอบพอยท์ในวันสุดท้ายของทุกเดือน",
	member_card_demotion_3_month_check: "ตัดรอบการตรวจสอบพอยท์ในวันสุดท้ายของเดือน มี.ค., มิ.ย., ก.ย., ธ.ค.",
	member_card_demotion_6_month_check: "ตัดรอบการตรวจสอบพอยท์ในวันสุดท้ายของเดือน มิ.ย., ธ.ค.",
	member_card_demotion_12_month_check: "ตัดรอบการตรวจสอบพอยท์ในวันสุดท้ายของเดือน ธ.ค.",
	member_card_demotion_condition: "เงื่อนไขการปรับลด",
	issue_member_card_confirm: "ยืนยันการออกบัตรสมาชิก",
	change_member_card_type_confirm: "ยืนยันการเปลี่ยนประเภทบัตรสมาชิก",
	member_card_info: "ข้อมูลบัตรสมาชิก",
	privilege_card: "สิทธิประโยชน์",
	member_card_one_line: "แบบตั้งชื่อบัตรสมาชิก 1 บรรทัด",
	member_card_two_lines: "แบบตั้งชื่อบัตรสมาชิก 2 บรรทัด",
	size_member_card: "ขนาดบัตรสมาชิก: 170px x 300px",
	size_logo: "ขนาดโลโก้: 50px x 50px",
	view_template: "ดู Template",
	max_2_lines: "พิมพ์ได้สูงสุด 2 บรรทัด",

	// Report Member card
	transaction_member_card: "ประวัติบัตรสมาชิก",
	transaction_member_card_in_business: "ประวัติบัตรสมาชิกของธุรกิจ",
	transaction_member_card_report: "รายงานประวัติบัตรสมาชิก",
	status_member_card: "สถานะบัตรสมาชิก",
	status_changed: "เปลี่ยนบัตรใหม่",
	status_activate: "ใช้งาน",
	status_canceled: "ยกเลิก",
	status_expired: "หมดอายุ",
	authority_member_card: "ผู้ออกบัตร",

	// Giveaway coupon
	giveaway_coupon: "ของรางวัลแจกฟรี",
	giveaway: "แจกฟรี",
	giveaway_coupon_ribbon: "ของรางวัลแจกฟรี",
	add_giveaway_coupon: "เพิ่มของรางวัลแจกฟรี",
	add_giveaway_coupon_successfully: "สร้างของรางวัลแจกฟรีสำเร็จ",
	edited_giveaway_coupon_successfully: "แก้ไขของรางวัลแจกฟรีสำเร็จ",
	delete_giveaway_coupon_successfully: "ลบของรางวัลแจกฟรีสำเร็จ",
	image_giveaway_coupon: "รูปภาพของรางวัลแจกฟรี",
	title_giveaway_coupon: "ชื่อของรางวัลแจกฟรี",
	description_giveaway_coupon: "รายละเอียดของรางวัลแจกฟรี",
	duration_of_giveaway_coupon: "ระยะเวลาการรับของรางวัลแจกฟรี",
	set_lifetime_giveaway_coupon: "กำหนดจำนวนวันหมดอายุหลังจากรับของรางวัลแจกฟรี",
	expired_after_receiving_giveaway_coupon: "หมดอายุหลังจากรับของรางวัลแจกฟรี",
	set_limit_giveaway_coupon: "การจำกัดจำนวนการรับของรางวัลแจกฟรี",
	receiving_per_member: "สิทธิ์การรับต่อสมาชิก",
	receiving_limit_all: "สิทธิ์การรับทั้งหมด",
	reward_redemption_limit_error: "จำนวนไม่ถูกต้อง สิทธิ์การรับต้องไม่ต่ำกว่าจำนวนของรางวัลที่ถูกแลกไปแล้ว $1 สิทธิ์",
	show_remain_giveaway_coupon: "แสดงจำนวนของรางวัลแจกฟรีคงเหลือ",
	confirm_delete_giveaway: "คุณต้องการลบของรางวัลแจกฟรี หรือไม่ ?",
	coupon_unit: "คูปอง",
	coupon_per_member_unit: "คูปองต่อสมาชิก",

	// redeem points
	use_points: "ใช้พอยท์",
	pending: "รอดำเนินการ",

	// gift voucher
	giftvoucher: "บัตรกำนัล",
	gift_voucher: "คูปองบัตรกำนัล",
	add_gift_voucher: "เพิ่มบัตรกำนัล",
	send_gift_voucher: "ส่งบัตรกำนัล",
	gift_voucher_list: "รายการบัตรกำนัล",
	gift_voucher_broadcast_list: "รายการส่งบัตรกำนัล",
	gift_voucher_broadcast_list_title: "รายการส่งบัตรกำนัล",
	select_gift_voucher: "เลือกบัตรกำนัล",
	gift_voucher_name: "ชื่อบัตรกำนัล",
	gift_voucher_description: "รายละเอียดบัตรกำนัล",
	gift_voucher_type: "ประเภทบัตรกำนัล",
	gift_voucher_image: "รูปภาพบัตรกำนัล",
	gift_voucher_value: "มูลค่าบัตรกำนัล",
	gift_voucher_status: "สถานะบัตรกำนัล",
	gift_voucher_send_amount: "จำนวนบัตรกำนัล",
	gift_voucher_amount: "ใบ",
	gift_voucher_notfound: "ไม่พบบัตรกำนัล",
	gift_voucher_confirm: "ยืนยันการส่งคูปองบัตรกำนัล",
	voucher_value: "มูลค่า",
	expired_after_gift_voucher: "หมดอายุหลังจากได้รับบัตรกำนัล",
	after_receiving_gift_voucher: "หลังจากได้รับบัตรกำนัล",
	set_expired_date_after_gift_voucher: "ตามจำนวนวัน",
	gift_voucher_text_messages: "ส่งการแจ้งเตือนส่งบัตรกำนัลทาง SMS จะส่งระหว่างเวลา {0} เท่านั้น",
	send_by_gift_voucher: "ส่งบัตรกำนัลโดย",
	amount_gift_voucher: "จำนวนบัตรกำนัล",
	total_sent_gift_voucher: "ส่งบัตรกำนัล",
	total_activated_gift_voucher: "ใช้งานบัตรกำนัล",
	date_send: "วันที่ส่ง",
	send_gift_voucher_successfully: "ส่งบัตรกำนัลเรียบร้อย",
	created_gift_voucher_successfully: "สร้างบัตรกำนัลเรียบร้อย",
	edited_gift_voucher_successfully: "แก้ไขบัตรกำนัลเรียบร้อย",
	deleted_gift_voucher_successfully: "ลบบัตรกำนัลเรียบร้อย",
	want_to_delete_gift_voucher: "คุณต้องการลบบัตรกำนัลหรือไม่ ?",
	discount_amount: "มูลค่าส่วนลด",
	sent: "ส่งแล้ว",
	sent_by: "ส่งบัตรกำนัลโดย",

	// tag label
	label_name: "ชื่อป้ายกำกับ",
	example_label_name: "ตัวอย่างป้ายกำกับ",
	select_label: "เลือกป้ายกำกับ",
	select_member_label: "เลือกป้ายกำกับสมาชิก",
	label: "ป้ายกำกับ",
	tag_label_description: "ใช้ป้ายกำกับในการติดตามการใช้งาน",
	add_label: "เพิ่มป้ายกำกับ",
	add_member_label: "เพิ่มป้ายกำกับสมาชิก",
	add_transaction_label: "เพิ่มป้ายกำกับรายการ",
	edit_member_label: "แก้ไขป้ายกำกับสมาชิก",
	edit_transaction_label: "แก้ไขป้ายกำกับรายการ",
	label_not_found: "ไม่พบป้ายกำกับ",
	delete_label_confirm: "ต้องการลบป้ายกำกับ ?",
	add_label_success: "เพิ่มป้ายกำกับสำเร็จ",
	add_label_error: "เพิ่มป้ายกำกับล้มเหลว",
	save_label_success: "บันทึกป้ายกำกับสำเร็จ",
	save_label_error: "บันทึกป้ายกำกับล้มเหลว",
	delete_label_success: "ลบป้ายกำกับสำเร็จ",
	delete_label_error: "ลบป้ายกำกับล้มเหลว",
	label_color: "สีป้ายกำกับ",
	transaction_label: "ป้ายกำกับรายการ",
	transaction_label_description: "ใช้ป้ายกำกับในการติดตามการใช้งานรายการต่างๆ",
	member_label: "ป้ายกำกับสมาชิก",
	no_member_label: "ไม่มีป้ายกำกับสมาชิก",
	member_label_description: "ใช้ป้ายกำกับในการติดตามสมาชิก",
  automatic_label: 'ป้ายกำกับอัตโนมัติ',
  automatic_label_description:
    'ใช้งาน AI ช่วยติดป้ายกำกับรายการจากการตรวจสอบรูปที่แนบมาในรายการส่งพอยท์',
  tooltip_ai_label: 'ป้ายกำกับเลือกโดย AI',

	// broadcast promotion
	broadcast: "บรอดแคสต์",
	"Broadcast Message": "บรอดแคสต์ข้อความ",
	broadcast_message: "บรอดแคสต์ข้อความ",
	broadcast_tx_remain: "จำนวนรายการคงเหลือ",
	broadcast_promotiom_list: "รายการบรอดแคสต์",
	enable_broadcast_message_success: "เปิดการใช้งานบรอดแคสต์สำเร็จ",
	setting_enable_broadcast_message: "ตั้งค่าเปิดการใช้งานบรอดแคสต์",
	setting_enable_broadcast_message_detail: "ตั้งค่าเปิดการใช้งานบรอดแคสต์เพื่อแจ้งข้อมูลข่าวสารให้กับสมาชิกในธุรกิจ",
	addon_package_broadcast_message: "มีค่าบริการเพิ่มจากแพ็กเกจหลัก ตามความยาวของข้อความ และจำนวนรายการ",
	message_broadcast: "ข้อความบรอดแคสต์",
	add_message_broadcast: "เพิ่มข้อความ",
	edit_message_broadcast: "แก้ไขข้อความบรอดแคสต์",
	message_broadcast_not_found: "ไม่พบข้อความ",
	broadcast_message_example: "ตัวอย่างการแสดงผล",
	created_broadcast_message_successfully: "เพิ่มข้อความบรอดแคสต์สำเร็จ",
	edited_broadcast_message_successfully: "แก้ไขข้อความบรอดแคสต์สำเร็จ",
	edited_broadcast_successfully: "แก้ไขบรอดแคสต์สำเร็จ",
	deleted_broadcast_message_successfully: "ลบข้อความบรอดแคสต์สำเร็จ",
	deleted_broadcast_successfully: "ลบบรอดแคสต์สำเร็จ",
	choose_another_message: "เลือกข้อความอื่น",
	edit_broadcast_promotion: "แก้ไขการส่งบรอดแคสต์",
	dynamic_message: "การปรับแต่งข้อความ",
	dynamic_message_description: "ข้อความจะถูกปรับเปลี่ยนตามข้อมูลของสมาชิก หรือข้อมูลธุรกิจ",
	dynamic_message_description2: "ข้อความจะถูกปรับเปลี่ยนตามข้อมูลของสมาชิก",
	add_link: "เพิ่มลิงก์",
	add_shortener_link: "สร้างลิงก์แบบย่อ",
	total_send_message: "ส่งข้อความทั้งหมด",
	delivered_message: "ส่งสำเร็จ",
	undelivered_message: "ส่งไม่สำเร็จ",
	opened_message: "อัตราการเปิด",
	clicked_message: "อัตราการกดลิงก์",
	select_broadcast_messages_target: "เลือกข้อความ และกลุ่มเป้าหมาย",
	broadcast_message_confirm_send: "ยืนยันการส่งข้อความบรอดแคสต์",
	message_broadcast_confirm_delete: "ยืนยันการลบข้อความบรอดแคสต์นี้",
	broadcast_confirm_delete: "ยืนยันการลบบรอดแคสต์นี้",
	cannot_edit_broadcast: "ไม่สามารถแก้ไขบรอดแคสต์ได้",
	cannot_delete_broadcast: "ไม่สามารถลบบรอดแคสต์ได้",
	broadcast_status: {
		created: "กำลังดำเนินการ",
		queueing: "กำลังดำเนินการ",
		success: "สำเร็จ",
		failed: "เกิดข้อผิดพลาด",
		pending: "รอดำเนินการ",
	},
	broadcast_tab_message: "ข้อความ",
	broadcast_tab_list: "ประวัติการส่งบรอดแคสต์",
	broadcast_tab_send: "ส่งบรอดแคสต์",
	broadcast_billing_credit: "การใช้งานเครดิต",
	broadcast_billing_charge: "ค่าบริการบรอดแคสต์",
	broadcast_billing_provider: "ช่องทาง",
	broadcast_billing_messages: "จำนวนข้อความ",
	broadcast_billing_amount: "จำนวนรายการ",
	broadcast_billing_sub_total: "ค่าบริการ (บาท)",
	broadcast_billing_total: "รวม",
	broadcast_billing_remark: "* ค่าบริการที่ต้องชำระจริงอาจแตกต่างจากนี้",
	broadcast_billing_remark_credit: "* จำนวนเครดิตที่ใช้จริงอาจแตกต่างจากนี้",
	broadcast_choose_by_system: "เลือกโดยระบบ",
	broadcast_time: "เวลาการส่งข้อความ",
	send_now: "ส่งทันที",
	custom_send_time: "กำหนดเวลาส่ง",
	send_time: "เวลาส่ง",
	tooltip_broadcast_option_system: "ระบบจะเลือกใช้ช่องทาง LINE ก่อนจากนั้นจะใช้ SMS",
	tooltip_broadcast_option_system_inter: "สำหรับลูกค้าต่างประเทศระบบจะส่งได้เฉพาะ EMAIL",
	select_broadcast_option: "ช่องทางการบรอดแคสต์",
	select_redeem_rewards: "ของรางวัลสะสมแต้ม",
	credit: "เครดิต",
	free_credit: "เครดิตฟรี",
	remaining_credit: "เครดิตคงเหลือ",
	not_noti_sms_provider_remark: "* สำหรับช่องทาง SMS จะไม่มีการแจ้งเตือน",
	please_select_future_time: "โปรดกำหนดวันเวลาบรอดแคสต์ให้เป็นวันเวลาในอนาคต",

	info_spot_redmption: "รูปแบบการทำงานของระบบการรับของรางวัลแบบ Spot Redemption",
	info_spot_redmption_detail: [
		"เมื่อผู้ใช้กดแลกรับของรางวัล ระบบจะแสดงรหัสแลกของรางวัลไว้เพียง 5 นาที",
		"ระบบจะยังไม่หักพอยท์และสิทธิ์การแลกของรางวัล จนกว่าผู้ดูแลระบบจะกดใช้งานรหัสแลกของรางวัลนั้น",
	],
	member_pending_group: "ผู้ใช้ที่รอสมัครสมาชิก",
	member_points: "จำนวนพอยท์",
	member_purchase_product: "ประวัติการซื้อสินค้า",
	member_receive_reward: "เกณฑ์การได้รับของรางวัล",
	condition: "เงื่อนไข",
	purchase_product_condition: "เงื่อนไขการซื้อสินค้า",
	purchase_condition: "เงื่อนไขการซื้อสินค้าที่เลือก",
	members_purchased_all: "ทั้งหมด",
	members_purchased_any: "บางรายการ",
	between: "ระหว่าง",
	select_points: "เลือกพอยท์",
	amount_points: "จำนวนพอยท์",
	range_points: "จำนวน",
	points_range_invalid: "ช่วงพอยท์ไม่ถูกต้อง",
	points_invalid: "จำนวนพอยท์ไม่ถูกต้อง",
	point_from_to: "ตั้งแต่",
	point_greater_equal: "มากกว่า/เท่ากับ",
	point_less_equal: "น้อยกว่า/เท่ากับ",
	points_greater: "พอยท์ขึ้นไป",
	points_or_greater: "พอยท์ขึ้นไป",
	button_label: "ข้อความบนปุ่ม",
	title_name: "ชื่อหัวข้อ",
	broadcast_title: "หัวข้อบรอดแคสต์",
	default_message: "ข้อความทั่วไป",
	line_message: "ข้อความสำหรับ LINE",
	line_error: "ไม่สามารถดำเนินการต่อได้\nกรุณาตั้งค่า LINE ล็อกอิน และ Line Messaging",
	aspect_ratio: "อัตราส่วน",
	view_details: "ดูรายละเอียด",
	invalid_url: "URL ไม่ถูกต้อง",

	// Product Management
	product: "สินค้า",
	products_management: "การจัดการสินค้า",
	product_level_points: "ระบบพอยท์รายสินค้า",
	detail_product_level_points_title: "คำอธิบายระบบพอยท์รายสินค้า",
	product_level_points_description: "จัดการสินค้าของธุรกิจเพื่อใช้ในการส่งพอยท์ตามรายการสินค้า",
	confirm_enable_product_level_points: "เปิดใช้งานระบบพอยท์รายสินค้า ?",
	when_enabled_product_level_points: "การเปิดใช้งานระบบพอยท์รายสินค้า\nจะมีการคิดค่าบริการเพิ่ม 1,200 บาทต่อเดือน",
	enable_success: "เปิดใช้งานสำเร็จ",
	disable_success: "ปิดใช้งานสำเร็จ",
	add_category: "เพิ่มหมวดหมู่",
	category_name: "ชื่อหมวดหมู่",
	category_name_place_holder: "หมวดหมู่ 1",
	add_category_success: "เพิ่มหมวดหมู่สำเร็จ",
	edit_category_success: "แก้ไขหมวดหมู่สำเร็จ",
	delete_category_success: "ลบหมวดหมู่สำเร็จ",
	move_product_success: "ย้ายสินค้าทั้งหมดสำเร็จ",
	edit_category: "แก้ไขหมวดหมู่",
	delete_category: "ลบหมวดหมู่",
	move_all_product: "ย้ายสินค้าทั้งหมด",
	move: "ย้าย",
	confirm_delete_category: "คุณต้องการลบหมวดหมู่นี้ ?",
	category: "หมวดหมู่",
	product_category: "หมวดหมู่สินค้า",
	product_quantity: "จำนวนสินค้า",
	item: "ชิ้น",
	add_product: "เพิ่มสินค้า",
	product_name: "ชื่อสินค้า",
	product_number: "เลขสินค้า",
	sku_number: "SKU",
	points_ratio: "อัตราแปลงพอยท์",
	points_ratio_product: "อัตราแปลงพอยท์สินค้า",
	set_points_ratio_product: "กำหนดอัตราแปลงพอยท์สินค้า",
	product_points_ratio_condition: "สินค้าที่ไม่ได้กำหนดอัตราแปลงพอยท์ จะใช้อัตราแปลงพอยท์มาตรฐานของธุรกิจ",
	add_product_success: "เพิ่มสินค้าสำเร็จ",
	delete_product_success: "ลบสินค้าสำเร็จ",
	edit_product_success: "แก้ไขสินค้าสำเร็จ",
	default: "ค่าเริ่มต้น",
	product_not_found: "ไม่พบรายการสินค้า",
	edit_product: "แก้ไขสินค้า",
	delete_product: "ลบสินค้า",
	product_deleted: "สินค้าถูกลบ",
	confirm_delete_product: "คุณต้องการลบสินค้านี้ ?",
	add_image: "เพิ่มรูปภาพ",
	select_image: "เลือกรูปภาพ",
	change_image: "เปลี่ยนรูปภาพ",
	image_size_recommend: "ขนาดรูปภาพที่แนะนำ 1200 * 600",
	not_defined: "ไม่กำหนด",
	standard_value: "ค่ามาตรฐาน",
	search_products: "ค้นหาสินค้า",
	search_product_placeholder: "SKU หรือ ชื่อสินค้า",
	product_list: "รายการสินค้า",
	select_product: "เลือกสินค้า",
	total_amount: "จำนวนรวม",
	trans_detail: "รายละเอียดรายการ",
	manual_product_level_points: [
		{
			title: "การจัดการสินค้า",
			detail: [
				"เมื่อเปิดใช้งานสามารถสร้างสินค้าของธุรกิจได้",
				"สามารถกำหนดรายละเอียดสินค้าได้ ซึ่งประกอบไปด้วย ชื่อ, รหัสสินค้า, อัตราแปลงพอยท์สินค้า และ รูปภาพ",
				"สามารถสร้างหมวดหมู่และจัดหมวดหมู่ของสินค้าได้",
			],
		},
		{
			title: "ระบบพอยท์รายสินค้า",
			detail: [
				"สามารถส่งพอยท์ตามรายการสินค้าที่เพิ่มได้",
				"พอยท์จะถูกคำนวณตามรายการสินค้าให้อัตโนมัติ",
				"สามารถเพิ่มสินค้าได้หลายรายการต่อการส่งพอยท์หนึ่งครั้ง",
				"สามารถดูรายงานระบบพอยท์รายสินค้าได้",
			],
		},
	],
	product_report: "รายงานสินค้า",
	product_report_description: "รายงานสรุปรายการสินค้า",
	number_of_member: "สมาชิก",
	transaction_product: "รายการสินค้า",
	total_points: "พอยท์",
	points_error_remark:
		"จำนวนพอยท์ที่แสดงจะมีความคลาดเคลื่อนจากพอยท์ที่ส่งจริง เนื่องจากการคำนวณพอยท์ตอนส่งคิดจากผลรวมของสินค้าหลายรายการ",
	all_archive_product: "สินค้าในหมวดหมู่นี้จะถูกลบ",
	already_sku: "มีรหัส SKU นี้ในระบบแล้ว",
	same_sku: "มีรหัส SKU ซ้ำ",
	no_sku: "ไม่ระบุ SKU",
	"pointspot/sku-exists": "มีรหัส SKU นี้ในระบบแล้ว",
	import_product: "นำเข้าสินค้า",
	importing_product: "กำลังนำเข้าสินค้า",
	limit_import_product_records: "จำกัดการนำเข้าไม่เกิน 200 รายการต่องาน",
	import_product_points_ratio_condition:
		"หากต้องการใช้อัตราแปลงพอยท์มาตรฐานของธุรกิจ ให้ระบุอัตราแปลงพอยท์ของสินค้าเป็นค่าว่าง",
	import_product_qty: "จำนวน",
	produc_sku_error_character: "SKU ต้องไม่มีช่องว่างและอักขระพิเศษ",
	produc_sku_error_length: "SKU ต้องไม่เกิน 20 ตัวอักษร",
	produc_name_error_length: "ชื่อสินค้าต้องไม่เกิน 300 ตัวอักษร",
	all_categories: "ทั้งหมด",
	product_require_config: "ต้องเลือกสินค้าเพื่อส่งพอยท์",
	product_require_config_description: "เปิดใช้งานการบังคับให้เลือกสินค้าอย่างน้อยหนึ่งรายการเพื่อส่งพอยท์",
	set_require_product_status_success: "อัปเดตการตั้งค่าสินค้าสำเร็จ",

	// Welcome points
	welcome_points: "พอยท์ต้อนรับ",
	welcome_points_setting: "ตั้งค่าพอยท์ต้อนรับ",
	welcome_points_setting_sub: "ตั้งค่าเปิดปิดการส่งพอยท์ต้อนรับสำหรับสมาชิกใหม่",
	welcome_points_period: "ระยะเวลาแจกพอยท์ต้อนรับ",
	open_setting: "เปิดการใช้งาน",
	send_sms_confirm: "ยืนยันส่งการแจ้งให้สมาชิก",

	// display_name
	display_name: "ชื่อที่แสดง",
	"user-permission/email-already-invite": "อีเมลนี้มีอยู่ในระบบแล้ว",
	"user-permission/transfer-limit": "ระบุการจำกัดการส่งพอยท์ไม่ถูกต้อง",

	// friend_get_friends
	friend_get_friends: "เพื่อนชวนเพื่อน",
	friend_get_friends_reward: "รางวัลเพื่อนชวนเพื่อน",
	friend_get_friends_description: "สมาชิกสามารถชวนเพื่อนมาสมัครสมาชิกเพื่อรับของรางวัล",
	friend_get_friends_period: "ระยะเวลาเพื่อนชวนเพื่อน",
	inviter_condition: "เงื่อนไขที่ผู้เชิญจะได้รับของรางวัล",
	invitee_subscribed: "ผู้ถูกเชิญสมัครสมาชิก",
	invitee_active: "ผู้ถูกเชิญใช้งาน (ใช้งานคูปองหรือรับพอยท์)",
	invitee_subscribed_active: "ผู้ถูกเชิญสมัครสมาชิกและใช้คูปอง",
	inviter_limit: "จำกัดจำนวนการเชิญ",
	inviter_reward: "รางวัลของผู้เชิญ",
	invitee_reward: "รางวัลของผู้ถูกเชิญ",
	tooltip_invite_limit: "หากไม่ระบุจำนวนระบบจะใส่เป็น ไม่จำกัดจำนวน",
	invite_limit_unit: "คน",
	friend_get_friends_report: "รายงานเพื่อนชวนเพื่อน",
	friend_get_friends_report_description: "รายงานแสดงสมาชิกใหม่ที่ถูกเชิญโดยสมาชิกของธุรกิจ",
	friends_subscribe_date: "วันที่สมัครสมาชิก",
	invitee_id: "Pointspot ID",
	invitee_name: "ชื่อสมาชิก",
	invitee_reward_status: "สถานะการรับรางวัลสมาชิกใหม่",
	inviter_id: "Pointspot ID ผู้เชิญ",
	inviter_name: "ชื่อผู้เชิญ",
	inviter_reward_status: "สถานะการรับรางวัลผู้เชิญ",
	invite_reward_receive: "ได้รับแล้ว",
	invite_reward_not_receive: "ยังไม่ได้รับ",

	//alias name
	update_alias_name: "ตั้งชื่อ",
	alias_name: "ชื่อ",

	// line_liff
	liff_id: "LINE LIFF app ID",
	liff_id_title: "เชื่อมต่อ Pointspot กับ LINE LIFF app",
	liff_id_nav: "เชื่อมต่อกับ LINE LIFF app",
	liff_home_page: "หน้าจอหลัก",
	liff_member_card_page: "บัตรสมาชิก",
	liff_my_coupon_page: "คูปองของฉัน",
	liff_reward_page: "รางวัล",
	liff_switch_title: "ใช้งาน Pointspot ผ่าน LINE LIFF app",
	liff_switch_description: "เพิ่มการสมัครสมาชิก Pointspot ด้วย LINE ผ่านการเชื่อมต่อโดย LINE LIFF app",
	liff_save_toast_success: "บันทึกข้อมูลเรียบร้อย",
	liff_save_toast_fail: "บันทึกข้อมูลผิดพลาด",
	liff_close_toast_success: "ปิดใช้งานสำเร็จ",
	liff_close_toast_fail: "ปิดใช้งานไม่สำเร็จ",
	liff_copy_toast_success: "คัดลอกแล้ว",
	login_with_line: "ใช้งานผ่าน LINE",
	liff_endpoint_url: "Endpoint URL",
	liff_remove_warning_title: "ยกเลิกการเชื่อมต่อ LINE ล็อกอิน",
	liff_remove_waening_des:
		"กรุณายืนยันการยกเลิกเชื่อมต่อกับ LINE ล็อกอิน ผู้ใช้ที่เคยใช้งานผ่าน LINE จะไม่สามารถลงชื่อเข้าใช้ด้วย LINE โดยอัตโนมัติได้",
	rich_menu_title: "ริชเมนู",
	rich_menu_card: "ตั้งค่าริชเมนู",
	rich_menu_image_size: "ขนาดภาพ",
	rich_menu_color: "สีตัวอักษรและไอคอน",
	rich_menu_image: "ภาพเมนู",
	rich_menu_dark: "เข้ม",
	rich_menu_light: "อ่อน",

	//line messaging
	line_messaging_nav: "เชื่อมต่อกับ LINE Messaging",
	line_messaging_save_toast_fail: "บันทึกข้อมูลผิดพลาด",
	line_messaging_save_toast_success: "บันทึกข้อมูลเรียบร้อย",
	line_messaging_close_toast_success: "ปิดใช้งานสำเร็จ",
	line_messaging_close_toast_fail: "ปิดใช้งานไม่สำเร็จ",
	line_messaging_switch_title: "ส่งข้อความแจ้งเตือนของ Pointspot ผ่าน LINE",
	line_messaging_switch_description:
		"ส่งข้อความแจ้งเตือนของ Pointspot ผ่าน LINE Official Account ด้วย LINE Messaging APIs",
	line_messaging_id_title: "เชื่อมต่อ Pointspot กับ LINE Messaging",
	line_messaging_token: "LINE Messaging โทเคน",
	line_messaging_remove_warning_title: "เลิกเชื่อมต่อกับ LINE Messaging",
	line_messaging_remove_warning_des:
		"กรุณายืนยันการเลิกเชื่อมต่อกับ LINE Messaging เมื่อเลิกเชื่อมต่อแล้วข้อความแจ้งเตือนจะส่งผ่าน SMS เท่านั้น",

	//change payment noti
	change_payment_noti_title: "แจ้งเปลี่ยนผู้ให้บริการการชำระเงินจาก stripe เป็น 2c2p",
	change_payment_noti_des:
		"ทั้งนี้จำเป็นที่จะต้องรบกวนให้ท่านทำการตรวจสอบข้อมูลที่อยู่สำหรับออก\nใบเสร็จ และเพิ่มข้อมูลบัตรเครดิตอีกครั้ง",
	change_payment_noti_verify: "ตรวจสอบ",
	change_payment_noti_later: "ภายหลัง",

	// 2c2p
	card_no_require: "กรุณากรอกหมายเลขบัตร",
	card_no_invalid: "หมายเลขบัตรไม่ถูกต้อง",
	exp_month_require: "กรุณากรอกเดือนที่หมดอายุ",
	exp_month_2_digit: "เดือนที่หมดอายุต้องเป็นเลข 2 หลัก",
	exp_month_invalid: "เดือนที่หมดอายุไม่ถูกต้อง",
	exp_year_require: "กรุณากรอกปีที่หมดอายุ",
	exp_year_4_digit: "ปีที่หมดอายุต้องเป็นเลข 2 หลัก",
	card_expired: "บัตรหมดอายุแล้ว",
	cvv_invalid: "CVV ไม่ถูกต้อง",
	add_card_error: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
	add_card_error_cancelled: "เกิดข้อผิดพลาด รายการถูกยกเลิก",
	add_card_error_invalid_number: "เกิดข้อผิดพลาด หมายเลขบัตรไม่ถูกต้อง",
	add_card_error_owner_cancel: "เกิดข้อผิดพลาด พบการยกเลิกจากเจ้าของบัตร",
	add_card_error_bank_unsupport: "เกิดข้อผิดพลาด ระบบไม่สามารถเชื่อมต่อกับธนาคารเจ้าของบัตรนี้ได้",
	add_card_error_expired_card: "เกิดข้อผิดพลาด บัตรหมดอายุ",
	add_card_error_restricted_card: "เกิดข้อผิดพลาด บัตรไม่สามารถใช้งานได้",
	add_card_error_lost_card: "เกิดข้อผิดพลาด บัตรนี้ถูกแจ้งว่าเป็นบัตรที่สูญหาย",
	add_card_error_stolen_card: "เกิดข้อผิดพลาด บัตรนี้ถูกแจ้งว่าเป็นบัตรที่ถูกขโมย",
	add_card_error_insufficient_funds: "เกิดข้อผิดพลาด จำนวนเงินไม่เพียงพอ",
	add_card_error_exceed_withdrawal_limit: "เกิดข้อผิดพลาด จำนวนการใช้งานเกินกำหนด",

	// Invoice status
	invoice_status: {
		processing: "กำลังประมวลผล",
		paid: "จ่ายสำเร็จ",
		failed: "ไม่สำเร็จ",
	},

	// Ecard
	ecard_code: "รหัสของรางวัล",
	ecard_code_add: "เพิ่มรหัส",
	ecard_code_added: "รหัสที่นำเข้าสำเร็จ",
	ecard_code_added_total: "จำนวนรายการ",
	ecard_code_type: "เลือกใช้รหัส",
	ecard_code_type_auto: "รหัสอัตโนมัติ",
	ecard_code_type_import: "รหัสที่นำเข้า",
	ecard_code_import_limit: "จำกัดการนำเข้าไม่เกิน {0} รหัสต่อครั้ง",
	ecard_code_import_limit_input: "ระบุได้มากสุด {0} รหัสต่อครั้ง",
	ecard_code_import_limit_exceed: "จำนวนข้อมูลมากกว่า {0} รายการ",
	ecard_code_import_pattern: 'สามารถใช้รูปแบบรหัสเป็น A-Z, a-z, 0-9 และ "-" เท่านั้น',
	ecard_code_import_limit_character: "สามารถระบุรหัสได้สูงสุด 32 ตัวอักษรต่อรหัส",
	ecard_code_import_pattern_separator: 'คั่นด้วยเครื่องหมาย "," หรือ space เท่านั้น',
	ecard_code_import_input: "ระบุรหัส",
	ecard_code_import_type_here: "กรอกรหัสที่นี่",
	ecard_code_import_method_change_confirm: "ยืนยันการเปลี่ยนวิธีนำเข้ารหัส",
	ecard_code_import_method_change_warning: "หากเปลี่ยนวิธีการนำเข้ารหัส ข้อมูลที่กรอกจะถูกรีเซ็ต",
	ecard_code_duplicated: "รหัสซ้ำ",
	ecard_code_exist: "มีรหัสในระบบแล้ว",
	ecard_code_invalid: "รูปแบบรหัสไม่ถูกต้อง",
	ecard_code_invalid_32: "รหัสเกิน 32 ตัวอักษร",
	ecard_code_list_total: "จำนวนรายการ: {0}",
	ecard_code_list_view: "ดูรายการ",
	ecard_code_list_valid: "รหัสที่สามารถนำเข้าได้ {0} รายการ",
	ecard_code_list_duplicated: "มีรหัสซ้ำกันจำนวน {0} รายการ",
	ecard_code_list_exist: "มีรหัสในระบบแล้วจำนวน {0} รายการ",
	ecard_code_list_invalid: "มีรหัสที่มีรูปแบบไม่ถูกต้อง {0} รายการ",
	ecard_code_list_valid_label: "รหัสที่สามารถนำเข้าได้",
	ecard_code_list_error_label: "รายการรหัสที่ผิดพลาด",
	ecard_code_list_invalid_label: "รูปแบบรหัสไม่ถูกต้อง",
	ecard_code_list_duplicated_exist_label: "รายการที่ซ้ำกัน / มีในระบบแล้ว",
	ecard_code_automatic: "อัตโนมัติ",
	ecard_code_custom: "กำหนดเอง",

	// Consent
	consent: {
		accept_terms: "ยอมรับ",
		accept: "ยินยอม",
		decline: "ไม่ยินยอม",
		accept_personal_shared_a: "ยินยอมให้ใช้ข้อมูลส่วนบุคคลในวัตถุประสงค์ทางการตลาด",
		accept_personal_shared_b: "ของบริษัท เรดดี้แพลนเน็ต จำกัด (มหาชน) ",
		accept_personal_shared_c: "และบริษัทในเครือ",
	},

	total_points_setting: "พอยท์สะสม",
	invalid_member_card: "ข้อมูลบัตรสมาชิกไม่ถูกต้อง",

	expire_in: "หมดอายุ",

	view_transactions: "ดูประวัติรายการ",
	points_earned_last_30_days: "พอยท์ที่ได้รับ 30 วันล่าสุด",

	//icon
	home: "หน้าแรก",
	point: "พอยท์",
	my_coupons: "คูปองของฉัน",
	icon_other: "อื่นๆ",
	get_friends: "ชวนเพื่อน",

	free_privileges: "สิทธิพิเศษฟรี",
	history: "ดูประวัติ",
	point_expire: "25 พอยท์จะหมดอายุในวันที่ 30 มิ.ย. 2566",
	view_all: "ดูทั้งหมด",

	setting_coupon: "ตั้งค่าคูปอง",
	view_all_photos: "ดูรูปภาพทั้งหมด",

	title_member_card_setting_noti_expiry: "การแจ้งเตือนบัตรสมาชิกหมดอายุ",
	no_notification_member_card_setting_noti: "ไม่แจ้งเตือน",
	advance_member_card_setting_noti: "แจ้งเตือนล่วงหน้า",
	setting_member_card_setting_noti_announcement:
		"ทำการแจ้งเตือนบัตรหมดอายุล่วงหน้า นับจากวันที่บัตรจะหมดอายุ (ไม่เกิน 30 วัน)",
	setting_member_card_setting_noti_explain_example:
		"ยกตัวอย่าง บัตรหมดอายุสิ้นเดือน มีนาคม 2024 ตั้งแจ้งเตือนล่วงหน้า 10 วัน สมาชิกจะได้รับการแจ้งเตือนบัตรหมดอายุวันที่ 21 มีนาคม",
	choose_amount_setting_member_noti_expiry_date: "เลือกจำนวนวัน (1-30)",
	warning_member_card_setting_noti_use_credit:
		"คำเตือน : การตั้งค่าแจ้งเตือนบัตรสมาชิกหมดอายุล่วงหน้า จะคิดค่าบริการการแจ้งเตือนเป็นจำนวนตามการใช้งานจริง",
	edit_member_card_setting_noti_successfully: "แก้ไขการแจ้งเตือนบัตรสมาชิกหมดอายุสำเร็จ",
	member_card_expire_notification: "แจ้งเตือนบัตรสมาชิกหมดอายุ",

	// Additional Register Form
	enable_or_disable_additional_register: "เปิด / ปิด การใช้งานแบบฟอร์มลงทะเบียน",
	title_additional_register: "หัวข้อคำถาม",
	answer_type: "ประเภทคำตอบ",
	require_form: "บังคับกรอก",
	require: "บังคับ",
	display_form_on: "แสดงข้อมูลที่",
	enable_setting_additional_register: "เปิดใช้งานแบบฟอร์มลงทะเบียน",
	disable_setting_additional_register: "ปิดใช้งานแบบฟอร์มลงทะเบียน",
	show_example: "แสดงตัวอย่าง",
	register_page: "หน้าลงทะเบียน",
	follow_biz_page: "หน้าติดตามธุรกิจ",
	popup_page: "หน้าป๊อบอัพ",
	popup_page_description: "แสดงหน้าต่างป๊อบอัพกรณีที่สมาชิกยังไม่ตอบคำถามใหม่ที่บังคับกรอก",
	example_additional_register: "ตัวอย่างแบบฟอร์มลงทะเบียน",
	question: "คำถาม",
	add_question: "เพิ่มคำถาม",
	add_thai: "เพิ่มภาษาไทย",
	add_english: "เพิ่มภาษาอังกฤษ",
	title_example_th: "เช่น รหัสสาขา จำนวนสัตว์เลี้ยงของคุณ",
	title_example_en: "E.g. branch code, number of your pet.",
	short_answer: "ข้อความสั้น (100 ตัวอักษร)",
	long_answer: "ข้อความยาว (200 ตัวอักษร)",
	choice_answer: "ตัวเลือก (100 ตัวเลือก)",
	placeholder: "ข้อความตัวอย่าง",
	member_information_page: "หน้าข้อมูลที่คุณให้กับธุรกิจของสมาชิก",
	membercard_page: "หน้าบัตรสมาชิก",
	manage_option: "จัดการตัวเลือก",
	options: "ตัวเลือก",
	no_option: "ไม่มีตัวเลือก",
	option_not_found: "ไม่พบตัวเลือก",
	add_options: "เพิ่มตัวเลือก",
	edit_options: "แก้ไขตัวเลือก",
	duplicate_option: "ตัวเลือกซ้ำ",
	delete_options: "ลบตัวเลือก",
	add_items_description: "ระบุ 1 ตัวเลือก (ความยาวไม่เกิน 50 ตัวอักษร) ต่อ 1 บรรทัด",
	option_list: "รายการตัวเลือก",
	and_more: "และอีก",
	options_reached_maximum: "ตัวเลือกถึงจำนวนสูงสุด",
	edit_info_additional_register: "แก้ไขข้อมูลจากแบบฟอร์มลงทะเบียน",
	custom_form_confirm_delete: "ยืนยันการลบคำถามนี้",
	delete_question_successfully: "ลบคำถามเรียบร้อย",
	display_member_card: "เลือกคำถามที่ใช้แสดงบนบัตรสมาชิก",
	changes_not_save: "ท่านยังไม่ได้บันทึกการแก้ไข",
	do_you_want_to_save: "ต้องการบันทึกข้อมูลหรือไม่",
	editing: "การแก้ไข",
	allow_member_to_edit: "อนุญาตให้สมาชิกแก้ไขข้อมูลได้ภายหลัง",
	dont_show: "ไม่แสดง",

	line_display_name: "ชื่อที่แสดงใน LINE",

	//Modal Chart New Package
	chart_point_title: "พอยท์",
	chart_coupon_title: "คูปอง",
	chart_broadcast_title: "บรอดแคสต์",
	chart_member_card_title: "บัตรสมาชิก",
	chart_trans_noti_expire_point_title: "แจ้งเตือนพอยท์หมดอายุ",
	chart_trans_noti_expire_member_card_title: "แจ้งเตือนบัตรสมาชิกหมดอายุ",
	chart_reject_collect_points_title: "ปฏิเสธรายการแลกรับพอยท์",
	chart_doughnut_tx: "รายการ",
	chart_doughnut_email: "อีเมล",
	chart_doughnut_sms: "SMS",
	chart_doughnut_line: "LINE",
	chart_doughnut_credit_title: "เครดิต",
	chart_overage_tx: "รายการส่วนเกิน",
	chart_normal_tx: "รายการ",
	chart_usage: "การใช้งาน",
	chart_summary_credit: "รวมเครดิตที่ใช้",
	chart_topup_credit: "เติมเครดิต",

	//security
	security: 'ความปลอดภัย',
	two_factor_authentication: 'การยืนยันตัวตน 2 ขั้นตอน',
	confirm_enable_two_factor_authentication: 'ยืนยันการเปิดใช้งาน การยืนยันตัวตน 2 ขั้นตอน',
	confirm_disable_two_factor_authentication: 'ยืนยันการปิดใช้งาน การยืนยันตัวตน 2 ขั้นตอน',
	two_factor_description: 'เพิ่มขั้นตอนการยืนยันตัวตนผ่าน OTP หลังจากเข้าสู่ระบบสำเร็จบนเบราว์เซอร์ใหม่',
	you_have_unsaved_changes: 'มีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก',
	are_you_sure_you_want_to_leave: 'คุณต้องการบันทึกก่อนดำเนินการหรือไม่?',
	cancel_and_stay: 'ยกเลิก',
	unsave_and_leave: 'ไม่บันทึก',
	save_and_continue: 'บันทึก',
	select_method_authentication: 'เลือกช่องทางในการยืนยันตัวตน',
	permission_changed: 'สิทธิ์การเข้าใช้งานมีการเปลี่ยนแปลง กรุณาเข้าสู่ระบบใหม่',
	ok:'ตกลง',
	cannot_verify_identity: 'ไม่สามารถยืนยันตัวตนได้',
	mobile_number_cannot_otp: 'หมายเลข $1 ไม่สามารถรับรหัส OTP ได้',
	contact_admin_for_edit: 'กรุณาติดต่อผู้ดูแลระบบเพื่อแก้ไขข้อมูล',
}
export default TH;