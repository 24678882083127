import React from 'react'
import { Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCronjobDetail, clearCronjobDetail } from '../../redux/actions/action_jobs'
import { getIsOverTrans, getUsageRecord } from '../../redux/actions/action_usage'

import { withRouter } from 'react-router-dom'

import Caching from '../../libs/caching'
import CronJobsDetailSection from './CronJobsDetailSection'
import JobsPagination from './JobsPagination'
import JobsTable from './JobsTable'
import Loading from '../../components/Loading'

const menukey = 'membercard-batchjob'
const menukey2 = 'points-batchjob'

class JobsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      job_status: 'pending',
      isLoadingGetCronJob: false,
      isLoadingGetOverTrans: false
    }
  }

  componentDidMount() {
    this.getCronjobData()
    this.refreshCronjob()
    this.checkIsOverTrans()
  }

  componentWillUnmount() {
    let key = `JobDetail`
    clearInterval(Caching.getCaching(key))
    Caching.clearCaching(key)
    this.props.clearCronjobDetail()
  }

  refreshCronjob = () => {
    let key = `JobDetail`
    Caching.setCaching(key, Caching.getCaching(key) ||
      setInterval(() => {
        if ((this.state.job_status && this.state.job_status === 'pending') && !this.state.isLoadingGetCronJob)
          this.getCronjobData()
      }, 30 * 1000)
    )
  }

  getCronjobData = () => {
    let { store_business, store_jobs, getCronjobDetail, cronJobsId, type = "point" } = this.props
    this.setState({ isLoadingGetCronJob: true }, () => {
      getCronjobDetail(store_business.current.business_code, cronJobsId, store_jobs.limit, store_jobs.offset, type,
        (err, data) => {
          if (data && data.data.length) {
            if (this.state.job_status === 'pending' && data.data[0].success) {
              this.props.getUsageRecord(store_business.current.business_code);
            }
            this.setState({ job_status: data.data[0].status })
          }
          this.setState({ isLoadingGetCronJob: false })
        })
    })
  }

  checkIsOverTrans = () => {
    let { store_business } = this.props
    this.setState({ isLoadingGetOverTrans: true }, () => {
      this.props.getIsOverTrans(store_business.current.business_code, () => {
        this.setState({ isLoadingGetOverTrans: false })
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let { isLoading, jobs } = this.props.store_jobs
    if (!isLoading && !jobs.length) {
      this.props.history.push(this.props.customRoute ? this.props.customRoute : '/jobs')
    }
  }

  render() {
    let { store_jobs, store_business } = this.props
    const permission =
      store_business.current.permission &&
      (store_business.current.permission[menukey].permission_edit ||
        store_business.current.permission[menukey2].permission_edit);
    let { store_jobs: { isLoading } } = this.props
    let { type } = this.props

    if (this.state.isLoadingGetCronJob && store_jobs.jobs.length === 0) {
      return <Container fluid>
        <div className='d-flex justify-content-center'>
          <Loading />
        </div>
      </Container>
    }

    return store_jobs.jobs && store_jobs.jobs.length > 0
      && <Container fluid>
        <Row className="mb-2">
          <CronJobsDetailSection 
            permission={permission} 
            getCronjobData={this.getCronjobData} 
            isLoadingGetCronJob={this.state.isLoadingGetCronJob || isLoading}
            isLoadingGetOverTrans={this.state.isLoadingGetOverTrans}
            type={type} 
          />
          <JobsTable type={type} />
          <JobsPagination type={type} />
        </Row>
      </Container>
  }
}

const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getCronjobDetail,
    clearCronjobDetail,
    getUsageRecord,
    getIsOverTrans
  }, dispatch)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobsContainer))
