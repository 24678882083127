import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Title2, Title3 } from "../../components/Font";
import { getPointColumns, getMemberCardColumns } from "./ImportColumn";
import _ from "lodash";
import { getCookie } from "../../libs/cookies";
import { verifyDateOfBirth } from "../../libs/validator";
import moment from "moment";

export default function PreDataTable({ data, type = "point", pointType, customRoute }) {
	const dictionary = useSelector((state) => state.language.dictionary);
	const { form_setting } = useSelector((state) => state.custom_form); 
	const business = useSelector((state) => state.business.current); 
	const lang = getCookie("__lang");
	const columns = type === "point" ? getPointColumns(pointType, customRoute) : getMemberCardColumns();
	const additionalColumns = [
		{
			key: "key",
			dictionaryTitle: "order_no",
			width: 5.5,
			additionalCol: true,
		},
	];
	columns.splice(0, 0, ...additionalColumns);
	if (customRoute === "/jobsMemberCard") {
		const additionalColumnsForm = business?.custom_form && form_setting.map((form) => {
			let titleEn = _.isEmpty(form["en"].title)
				? form["th"].title
				: form["en"].title;
			let required = form.is_required ? ' *' : ''
			return {
				key: `custom_form_${form.id}`,
				title: { th: form["th"].title + required, en: titleEn + required },
				width: 14,
				additionalCol: true,
				dictionaryInvalid: "modal_title_incomplete_import_data",
			};
		});
		if (additionalColumnsForm)
			columns.splice(columns.length, 0, ...additionalColumnsForm);
	}

	const getDisplayedDateOfBirth = (dateOfBirth) => {
		if (lang === "th" && verifyDateOfBirth(dateOfBirth) && moment(dateOfBirth, "DD-MM-YYYY").isValid()) {
			const year = dateOfBirth.split("-")[2];
			return dateOfBirth.replace(year, Number(year) + 543);
		}

		return dateOfBirth;
	};

	return !_.isEmpty(data) ? (
		<div style={{ maxHeight: 300, overflowY: "auto", overflowX: "scroll" }}>
			<TablePreData style={{ minWidth: `${form_setting.length > 3 ? 1200 + (100 * (form_setting.length - 3)) : 1200}px` }}>
				<RowHeadPreData>
					{columns.map((col, index) => (
						<ColHeadPreData
							key={col.key}
							width={col.width}
							center={!index || col.key !== "remark"}
						>
							<Title2 bold style={{ wordBreak: col.key.includes('custom_form') ? "break-word" : "" }}>
								{`${col.key.includes('custom_form') ? col.title[lang]
								: dictionary[col.dictionaryTitle]} ${col.require ? "*" : ""}`} {col.productPoint ? "**" : ""}{" "}
							</Title2>
						</ColHeadPreData>
					))}
				</RowHeadPreData>
				{data.map((value, key) => (
					<RowPreData key={key} valueKey={key} id={'job-' + key}>
						{columns.map((col, index) => (
							<ColPreData
								key={`${key}-${col.key}`}
								width={col.width}
								className="d-block"
								center={!index || col.key !== "remark"}
							>
								<Title3 danger={value.danger.includes(col.key) || value.danger.includes(col.sub_key)} style={{ wordBreak: "break-all" }}>
									{col.key === "key"
										? value[col.key] + 1
										: col.key === "date_of_birth"
										? getDisplayedDateOfBirth(value[col.key])
										: value[col.key]}
								</Title3>
								{(value[col.key] || _.isEmpty(value[col.key]) || col.key === "receipt_no" || col.key === "sku") && (value.danger.includes(col.key) || value.danger.includes(col.sub_key)) ? (
									<div className="mt-2">
										<Title3 bold danger>{`* ${
                      dictionary[
                        value.danger.includes(col.key)
                          ? col.dictionaryInvalid
                          : col.dictionarySubInvalid
                      ]
                    }`}</Title3>
									</div>
								) : null}
							</ColPreData>
						))}
					</RowPreData>
				))}
			</TablePreData>
		</div>
	) : (
		<ResultsFound>
			<Title3 center>{dictionary.no_results_found}</Title3>
		</ResultsFound>
	);
}

const TablePreData = styled.div`
	position: relative;
`;

const RowHeadPreData = styled.div`
	top: 0px;
	min-height: 40px;
	display: flex;
	position: sticky;
	align-items: center;
	background-color: #f9f9f9;
	border-top: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	> * {
		border-right: 1px solid #dee2e6;
		:last-child {
			border-right: 0px solid #dee2e6;
		}
	}
`;

const ColHeadPreData = styled.div`
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 7px;
	visibility: ${(props) => props.hidden};
	text-align: center;
	width: ${(props) => props.width}%;
`;

const RowPreData = styled.div`
	min-height: 40px;
	display: flex;
	align-items: stretch;
	border-bottom: 1px solid #dee2e6;
	${(props) => props.valueKey % 2 === 1 && `background-color:  #F2F2F2;`}
	> * {
		border-right: 1px solid #dee2e6;
		:last-child {
			border-right: 0px solid #dee2e6;
		}
	}
`;

const ColPreData = styled.div`
	min-height: 40px;
	padding: 5px 7px;
	display: flex;
	align-items: center;
	visibility: ${(props) => props.hidden};
	width: ${(props) => props.width}%;
	${(props) =>
		props.center
			? `justify-content: center; text-align: center;`
			: props.right
			? `justify-content: flex-end;`
			: `justify-content: flex-start;`}
`;

const ResultsFound = styled.div`
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f9f9f9;
`;
