import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from "reactstrap";
import Loading from "../../components/Loading";
import { getPrivilegeAndInvite } from "../../redux/actions/action_admin";
import { getCronjobs, initialJobssState, togleTabMember } from "../../redux/actions/action_jobs";
import { getMemberCardInfo } from "../../redux/actions/action_business";
import CronJobsTable from "./CronJobsTable";
import JobsPagination from "./JobsPagination";
import StickyMenu from "./StickyMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faDatabase } from "@fortawesome/free-solid-svg-icons";

import { Title2 } from "../../components/Font";
import { getCustomFormSetting } from "../../redux/actions/action_custom_form";

const menukey = "membercard-batchjob";

export default function MemberCardJobs() {
	const store_business = useSelector((state) => state.business);
	const store_jobs = useSelector((state) => state.jobs);
	const store_language = useSelector((state) => state.language);
	const dispatch = useDispatch();

	const business = _.get(store_business, ["current"]);
	const permission = _.get(business, ["permission", menukey]);

	const { dictionary } = store_language;
	const {
		current: { business_code },
	} = store_business;

	const [activeTab, setActiveTab] = useState("import_member_card");

	useEffect(() => {
		if (store_jobs.toggleTabMember === "import_member_card") {
			setActiveTab("import_member_card");
			dispatch(getCronjobs(business.business_code, store_jobs.limit, 0, "membercard"));
		} else if (store_jobs.toggleTabMember === "import_initial_point") {
			setActiveTab("import_initial_point");
			dispatch(getCronjobs(business.business_code, store_jobs.limit, 0, "point"));
		}
		dispatch(getCustomFormSetting({ business_code: business.business_code, with_archive: true }));

		return () => {
			dispatch(initialJobssState());
		};
	}, [store_jobs.toggleTabMember, store_jobs.limit, business.business_code, dispatch]);

	useEffect(() => {
		dispatch(getMemberCardInfo(business_code));
		dispatch(getPrivilegeAndInvite(business_code));
	}, [business_code, dispatch]);

	const toggle = (tab) => {
		if (tab === "import_member_card") {
			setActiveTab("import_member_card");
			dispatch(togleTabMember(tab));
			dispatch(getCronjobs(business.business_code, store_jobs.limit, 0, "membercard"));
		} else if (tab === "import_initial_point") {
			setActiveTab("import_initial_point");
			dispatch(togleTabMember(tab));
			dispatch(getCronjobs(business.business_code, store_jobs.limit, 0, "point"));
		}
	};
 
	return (
		<div>
			{store_business.isFetching ? (
				<Container fluid style={{ margin: "30px 0px" }}>
					<Loading />
				</Container>
			) : (
				<>
					<TabNavMember
						dictionary={dictionary}
						isMember={activeTab === "import_member_card"}
						isPremember={activeTab === "import_initial_point"}
						toggleTabMember={() => toggle("import_member_card")}
						toggleTabPremember={() => toggle("import_initial_point")}
					/>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="import_member_card">
							{(store_business.member_card_info && store_business.member_card_info.length === 0) ? (
								<Row className="mt-2 ml-1 mr-1">
									<Col>
										<Alert className="text-center" color="warning">
											<Title2 b>{dictionary.open_member_card}</Title2>
											{ permission.privilege_group_id !== 6 && //!role operator
                        <Link to="/memberCard">
                          <Title2 link blue>
                            {dictionary.click_open_member_card}
                          </Title2>
                        </Link>
                      }
										</Alert>
									</Col>
								</Row>
							) : (
								<>
									<StickyMenu permission={permission} customRoute="/jobsMemberCard/create"></StickyMenu>
									{store_jobs.isLoading ? (
                    <Container fluid>
                      <Loading />
                    </Container>
                  ) : (
                    <Container fluid>
                      <Row>
                        <Col xs={12} className="mb-2 p-0">
                          <CronJobsTable customRoute="/jobsMemberCard" />
                        </Col>
                        <Col xs={12} className="text-center pt-2 mb-2">
                          <JobsPagination type="membercard" />
                        </Col>
                      </Row>
                    </Container>
                  )}
								</>
							)}
						</TabPane>
						<TabPane tabId="import_initial_point">
							<StickyMenu permission={permission} customRoute="/jobsMemberCard/createPoint"></StickyMenu>
							{store_jobs.isLoading ? (
                <Container fluid>
                  <Loading />
                </Container>
              ) : (
                <Container fluid>
                  <Row>
                    <Col xs={12} className="mb-2 p-0">
                      <CronJobsTable customRoute="/jobsMemberCard/point" />
                    </Col>
                    <Col xs={12} className="text-center pt-2 mb-2">
                      <JobsPagination />
                    </Col>
                  </Row>
                </Container>
              )}
						</TabPane>
					</TabContent>
				</>
			)}
		</div>
	);
}

const TabNavMember = (props) => {
	let { isMember, isPremember, dictionary, toggleTabMember, toggleTabPremember } = props;
	return (
		<Nav tabs className="pl-3">
			<NavItem className="mt-3">
				<NavLink
					active={isMember}
					onClick={toggleTabMember}
					style={{
						cursor: "pointer",
						backgroundColor: isMember ? "" : "#F5F5F5",
						border: isMember ? "" : "1px solid #DEE2E6",
					}}
				>
					<Title2 bold={isMember}>
						<FontAwesomeIcon icon={faAddressCard} style={{ marginRight: 5 }} /> {dictionary.import_member_card}
					</Title2>
				</NavLink>
			</NavItem>
			<NavItem className="mt-3">
				<NavLink
					active={isPremember}
					onClick={toggleTabPremember}
					style={{
						cursor: "pointer",
						backgroundColor: isPremember ? "" : "#F5F5F5",
						border: isPremember ? "" : "1px solid #DEE2E6",
					}}
				>
					<Title2 bold={isPremember}>
						<FontAwesomeIcon icon={faDatabase} style={{ marginRight: 5 }} /> {dictionary.import_initial_point}
					</Title2>
				</NavLink>
			</NavItem>
		</Nav>
	);
};
