import React, { useState,useRef } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

import { Title2, Title3, Title4 } from "../../components/Font";
import { AdvanceOptionHeader } from "../Ecard/Ecard.style";
import { IconChevron } from "../../components/CardHeaderFilter/CardHeaderFilter.style";
import { Collapse, Row, Col, FormGroup, Input, FormFeedback } from "reactstrap";
import "../BroadcastPromotion/styleBroadcast.css";
import { toCurrency } from "../../libs/currency";
import { validateURLLink } from "../../libs/validator";

const MAX_LENGTH = 70;

export default function AdvanceOption({ message, onMessageChange }) {
	const [isShow, setShow] = useState(false);
	const [invalidUrl, setInvalidUrl] = useState(false);
	const [invalidLength, setInvalidLength] = useState(false);
	const dictionary = useSelector((state) => state.language.dictionary);
	const store_business = useSelector((state) => state.business);
	const textareaInnerRef = useRef(null);

	const handleDynamicOptionClick = (code) => {
		const element = textareaInnerRef.current;
		const cursorPosition = element.selectionStart;
		const beforeCursor = message.text.slice(0, cursorPosition);
		const afterCursor = message.text.slice(cursorPosition);
	  
		const hasTextBefore = beforeCursor.trim().length > 0;
		const hasTextAfter = afterCursor.trim().length > 0;
	  
		const prefix = hasTextBefore ? " " : "";
		const suffix = hasTextAfter ? " " : "";
	  
		const newMessage = `${beforeCursor}${prefix}${code}${suffix}${afterCursor}`;
   
		setInvalidLength(getMessageLength(newMessage) > MAX_LENGTH);
		onMessageChange({
		  text: newMessage,
		  isError: getMessageLength(newMessage) > MAX_LENGTH,
		});
	  

		requestAnimationFrame(() => {
		  element.focus();
		  element.setSelectionRange(
			cursorPosition + prefix.length + code.length + 1,
			cursorPosition + prefix.length + code.length + 1
		  );
		});
	  };
	  
	  

	const handleMessageInput = (event) => {
		const { value } = event.target;
		const urlPattern = /((http|https):\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/a-zA-Z0-9#@?&%=._-]*)?/gi;
		const urls = value.match(urlPattern);
		let validLink = true;
		if (urls) {
		validLink = urls.every((url) => validateURLLink(url));
		}
		setInvalidUrl(!validLink);
		setInvalidLength(getMessageLength(value) > MAX_LENGTH);
		onMessageChange({
			text: value,
			isError: getMessageLength(value) > MAX_LENGTH || !validLink,
		});
	};

	const getMessageLength = (text) => {
		if (!text) return null;

    const business_link_length = store_business.current?.business_alias
      ? `https://pointspot.co/@${store_business.current?.business_alias}`
      : `https://pointspot/b/${store_business.business_code}`;

    const key = [
      { key: '$name', length: '1234567890123456789012345678901234567890' },
      { key: '$link', length: business_link_length },
      { key: '$point', length: '100,000' }
    ];

    let length = 0;
    const line = text.split('\n');
    // line
    for (let index = 0; index < line.length; index++) {
      const element = line[index];
      const _message = element.split(' ');
      // word
      for (let index2 = 0; index2 < _message.length; index2++) {
        let element2 = _message[index2];
				key.map((f) => element2 = element2.replace(f.key, f.length));
        if (element2.length) _message[index2] = element2;
      }
      length += _message.join(' ').length || 1;
    }
    return length;
	};

	return (
		<>
			<AdvanceOptionHeader onClick={() => setShow(!isShow)}>
				<Title2 className="title" bold>
					{dictionary.addtional_setting}
				</Title2>
				<IconChevron toggle={isShow}>
					<Title3>
						<i className="fas fa-chevron-up" />
					</Title3>
				</IconChevron>
			</AdvanceOptionHeader>
			<Collapse isOpen={isShow}>
				<Row>
					<Col sm={12} md={6}>
						<FormGroup>
							<div className="d-flex pb-1 align-items-center justify-content-between w-100">
								<div className="d-flex">
									<Title2 bold>{dictionary.notification_message}</Title2>
								</div>
								<Title3 secondary danger={invalidLength} bold={invalidLength}>
									{`${toCurrency(getMessageLength(message.text) || 0)} / ${MAX_LENGTH}`}
								</Title3>
							</div>
							<Input
								rows={2}
								name="message"
								type="textarea"
								innerRef={textareaInnerRef}
								id="input-message"
								style={{ resize: "none" }}
								onChange={handleMessageInput}
								value={message.text}
								invalid={invalidUrl}
							/>
							<FormFeedback>
								<Title4 danger>{dictionary.invalid_url}</Title4>
							</FormFeedback>
						</FormGroup>
					</Col>
					<Col sm={12} md={6}>
						<div className="pb-1">
							<Title2 bold>{dictionary.dynamic_message}</Title2>
							<Title3 secondary>{dictionary.dynamic_message_description}</Title3>
						</div>
						<DynamicOptions message={message.text} dictionary={dictionary} onItemClick={handleDynamicOptionClick} />
					</Col>
				</Row>
			</Collapse>
		</>
	);
}

const DynamicOptions = ({ message = "", dictionary, onItemClick }) => {
	const dynamicOptions = [
		{
			code: "$name",
			title: "<code class='code-broadcast'>" + dictionary.first_name + "</code>",
			title_disable: "<code class='code-broadcast disable'>" + dictionary.first_name + "</code>",
		},
		{
			code: "$link",
			title: "<code class='code-broadcast'>" + dictionary.business_link + "</code>",
			title_disable: "<code class='code-broadcast disable'>" + dictionary.business_link + "</code>",
		},
		{
			code: '$point',
			title: "<code class='code-broadcast'>" + dictionary.points + '</code>',
			title_disable: "<code class='code-broadcast disable'>" + dictionary.points + '</code>'
		}
	];

	return (
		<div className="w-100 d-flex">
			{dynamicOptions.map((value, key) => {
				let check = message.match(/\$name|\$link|\$point/gm) || [];
				let _disable = Boolean(check.indexOf(value.code) > -1);

				if (_disable)
					return (
						<div className="mr-2" key={`dynamic_${key}`}>
							<Title2>{parse(value.title_disable)}</Title2>
						</div>
					);

				return (
					<div className="mr-2 pointer" key={`dynamic_${key}`} onClick={() => onItemClick(value.code)}>
						<Title2>{parse(value.title)}</Title2>
					</div>
				);
			})}
		</div>
	);
};
