import React from 'react'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCronjobs } from '../../redux/actions/action_jobs'

class JobsPagination extends React.Component {
  onPageChange(event) {
    let { selected } = event
    let { store_business, getCronjobs, store_jobs, type } = this.props
    let offset = selected * store_jobs.limit
    getCronjobs(store_business.current.business_code, store_jobs.limit, offset, type)
  }
  render() {
    let { store_jobs } = this.props
    let curPage = store_jobs.currentPage
    let total = store_jobs.totalCronjobs
    let pageCount = Math.ceil(total / store_jobs.limit)
    return pageCount > 0 && (
      <div className="d-inline-block">
        <ReactPaginate
          forcePage={curPage}
          pageCount={pageCount}
          previousLabel={<i className="fas fa-chevron-left" />}
          nextLabel={<i className="fas fa-chevron-right" />}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          pageClassName={"page-items"}
          onPageChange={this.onPageChange.bind(this)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getCronjobs
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsPagination)
