import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, Input, Tooltip } from 'reactstrap'
import _ from 'lodash'
import AnimatedNumber from 'react-animated-number'

import {
  ButtonSection,SearchResultContainer, ProfileSection, ImageProfileSection,
  Icon, PointsBox, PointsTitle, PointsSubTitle,
  ProfileContainer, IconEdit, IconLine, Labelcss, LineDisplayName
} from './SearchResultPersonal.style'

import { icon } from '../../../libs/icon'
import { theme } from '../../../styles/theme'
import { toCurrency } from '../../../libs/currency'

import Loading from '../../../components/Loading'
import { Title1, Title2, Title3 } from '../../../components/Font'
import { Label, LabelSelectModal } from '../../../components/Label'
import { InvertBtn, PrimaryBtn, SuccessBtn } from '../../../components/Button'
import default_avatar from '../../../assets/img/default_avatar.png'

import { getMemberProfileBasic, updateMemberLabel, toggleEditAliasNameModal, editAliasName, clearMemberFilter } from '../../../redux/actions/action_member'
import { getTransactionsWithFilter } from '../../../redux/actions/action_transactions'
import { getCustomFormValue, editCustomFormValue, getCustomFormSetting } from '../../../redux/actions/action_custom_form'

import { toast } from 'react-toastify'
import { ToastBar } from '../../../components/ToastComponent/Toast.style'
import lineLogo from '../../../assets/img/LINE_SOCIAL_Basic.png'
import { ellipsis } from '../../../libs/ellipsis'

import moment from 'moment'

class SearchResult extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isLoadingLabelAction: false,
      isUpdatingAliasName: false,
      isModalMemberLabel: false,
      alias_name: '',
      isOpenEditCustomValue: false,
      isOpenToolTip: false,
      showMoreFormValue: false,
    }
    this.onChangeInput = this.onChangeInput.bind(this)
    this.toggleToolTip = this.toggleToolTip.bind(this)
  }

  componentDidMount() {
    let { memberBadgeCode } = this.props
    let { store_business: { current } } = this.props

    this.setState({ isLoading: true })
    this.props.getMemberProfileBasic(current.business_code, memberBadgeCode, (err, data) => {
      if (this._isMounted)
        return null
      this.setState({ isLoading: false })
    })
    this.props.getCustomFormSetting({ business_code: current.business_code })
    this.props.getCustomFormValue(current.business_code, memberBadgeCode)
  }

  componentWillUnmount() {
    this._isMounted = true
    const { history } = this.props;
    if (!history.location.pathname.includes('/member') || history.location.pathname.includes('/memberCard')) {
      this.props.clearMemberFilter();
    }
  }

  toggleSelectLabel = () => {
    this.setState({ isModalMemberLabel: !this.state.isModalMemberLabel })
  }

  updateMemberLabel = (list) => {
    let { store_language: { dictionary } } = this.props
    let { store_business: { current } } = this.props
    let { memberBadgeCode } = this.props

    let input = {
      business_code: current.business_code,
      member_badge_code: memberBadgeCode,
      member_label: (list && list.map(v => v.tag_id)) || []
    }

    this.setState({ isLoadingLabelAction: true })
    return this.props.updateMemberLabel(input, (err, data) => {
      if (this._isMounted) return null

      if (err)
        this.toastShow('error', dictionary.error_occurred)
      else {
        this.toastShow('success', dictionary.saved_successfully)
        if (this.state.isModalMemberLabel)
          this.toggleSelectLabel()
      }
      this.setState({ isLoadingLabelAction: false })
    })
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  onChangeInput(event) {
    let { name, value } = event.target
    this.setState({ [name]: value })
  }

  editAliasName = () => {
    let { alias_name } = this.state
    let { store_language: { dictionary } } = this.props
    let { store_business: { current } } = this.props
    let { memberBadgeCode } = this.props
    this.setState({ isUpdatingAliasName: true });
    return this.props.editAliasName({
      business_code: current.business_code,
      member_badge_code: memberBadgeCode,
      name: alias_name
    }, (err) => {
      if (err) {
        this.toastShow('error', dictionary.error_occurred)
      } else {
        this.toastShow('success', dictionary.saved_successfully)
      }
      this.setState({ isUpdatingAliasName: false });
    })
  }

  updateCustomFormValue = () => {
    let { store_language: { dictionary } } = this.props
    let { store_business: { current } } = this.props
    let { store_custom_form: { profile_custom } } = this.props
    let { memberBadgeCode } = this.props
    let update_data = profile_custom && profile_custom.map((val, index) => {
      let stateName = `customForm_${index + 1}`
      return { form_id: val.form_id, value: this.state[stateName] }
    })
    this.props.editCustomFormValue({
      business_code: current.business_code,
      member_badge_code: memberBadgeCode,
      value: update_data
    }, (err) => {
      if (err) {
        this.toastShow('error', dictionary.error_occurred)
      } else {
        this.toastShow('success', dictionary.saved_successfully)
      }
      this.setState({ isOpenEditCustomValue: false })
    })
  }

  toggleEditCustomFormValue = () => {
    let { store_custom_form: { profile_custom, form_setting } } = this.props
    if (!this.state.isOpenEditCustomValue) {
      profile_custom.map((val, index) => {
        let formData = form_setting.find(form => form.id === val.form_id)
        let value = val.value || ''
        if (formData.type === "select" && value) {
          let option = formData.options_list.find(op => op.title === value)
          value = option ? option.title : ''
        }
        return this.setState({ [`customForm_${index + 1}`]: value })
    })
    }
    this.setState({ isOpenEditCustomValue: !this.state.isOpenEditCustomValue })
  }

  clickHistorySend(member_badge_code) {
    if (member_badge_code)
      return this.props.history.push(
        { 
          pathname: `/send/${member_badge_code}`,
          number: member_badge_code
         })
  }

  clickHistoryPull(member_badge_code) {
    if (member_badge_code)
      return this.props.history.push({ 
        pathname: `/pull/${member_badge_code}`,
        number: member_badge_code
       })
  }

  validateBtnCustomForm() {
    let { store_custom_form: { form_setting } } = this.props
    let resultForm = [];
    form_setting.length > 0 && form_setting.map((form, index) => {
      if (form.is_required && !_.isUndefined(this.state[`customForm_${index + 1}`])){
        return resultForm.push(this.state[`customForm_${index + 1}`] && this.state[`customForm_${index + 1}`].trim())
      } else {
        return resultForm.push(true)
      }
    });

    return resultForm.every(val => val);
  }

  toggleToolTip(){
    this.setState({ isOpenToolTip : !this.state.isOpenToolTip})
  }

  toggleShowMoreFormValue = () => {
    this.setState({ showMoreFormValue: !this.state.showMoreFormValue })
  }

  render() {
    let { memberBadgeCode } = this.props
    let { store_business: { permission, label: { member_label } } } = this.props
    let { store_members: { userInfo } } = this.props
    let { store_language: { dictionary, locale } } = this.props
    let { store_custom_form: { profile_custom, form_setting, loading_update, loading_value } } = this.props
    let permiss_member_label = permission && permission['member_label']
    let permiss_member_name = permission && permission['member_name']
    let permiss_member_send = permission && permission['points-send']
    let permiss_member_pull = permission && permission['points-pull']
    let { alias_name, isUpdatingAliasName } = this.state
    const limit_label = 5

    let this_profile = (userInfo && userInfo[memberBadgeCode]) || {}

    if (_.isEmpty(this_profile))
      return null

    let profileData = {
      point_balance: this_profile && this_profile.point_balance,
      picture_url: (this_profile && this_profile.photo_uri) || default_avatar,
      country_code: (this_profile && this_profile.country_code) || '',
      mobile_number: (this_profile && this_profile.mobile_number) || '',
      member_badge_code: (this_profile && this_profile.member_badge_code) || '',
      displayName: (this_profile && this_profile.hide_business) || ''
        ? dictionary.customer_unsubscribe_business
        : this_profile && this_profile.subscribe_date
          ? `${(this_profile && this_profile.first_name) || ''} ${(this_profile && this_profile.last_name) || ''}`.trim()
          : dictionary.pending_member,
      alias_name: (this_profile && this_profile.alias_name) || '',
      block_noti: (this_profile && this_profile.block_noti_business) || '',
      member_label: (this_profile && this_profile.member_label && member_label)
        ? member_label.filter(f => this_profile.member_label.indexOf(f.tag_id) > -1)
        : null,
      line_id: (this_profile && this_profile.line_id) || '',
      gender: (this_profile && this_profile.gender) || '',
      gender_icon: '',
      date_of_birth: (this_profile && this_profile.date_of_birth) || '',
      email: (this_profile && this_profile.email_verified && this_profile.email) || '',
      is_archive: (this_profile && this_profile.is_archive) || false,
      line_displayname: (this_profile && this_profile.line_displayname) || '',
    }
    //this.setState({ alias_name: profileData.alias_name })

    let labelExist = (profileData.member_label && profileData.member_label.length) || 0
    if (profileData.gender === 'male') {
      profileData.gender = dictionary.gender_male
      profileData.gender_icon = 'fas fa-mars'
    } else if (profileData.gender === 'female') {
      profileData.gender = dictionary.gender_female
      profileData.gender_icon = 'fas fa-venus'
    } else {
      profileData.gender = dictionary.gender_unspecified
      profileData.gender_icon = 'fas fa-venus-mars'
    }
    return <div>
      <SearchResultContainer>
        <ProfileContainer>
          <ProfileSection>
            {
              this.state.isLoading
                ? <ImageProfileSection>
                  <Loading size={'35px'} margin={'0px'} padding={'0px'} />
                </ImageProfileSection>
                : <ImageProfileSection src={profileData.picture_url}>
                  {
                    profileData.line_id?
                    <div>
                      <IconLine src={lineLogo} id='line-icon'></IconLine>
                      { profileData?.line_displayname && <Tooltip
                        placement='bottom'
                        isOpen={this.state.isOpenToolTip}
                        target={'line-icon'}
                        toggle={this.toggleToolTip}
                      >
                        LINE : {profileData.line_displayname}
                      </Tooltip> }
                    </div>
                    :null
                  }
                </ImageProfileSection>
            }
            <div className='mx-3 w-100' hidden={this.state.isLoading}>
              {
                profileData.is_archive
                  ? <Title2 bold white className='pb-1'>
                      {dictionary.no_user_found}
                    </Title2>
                  : <Title2 bold white className='pb-1'>
                      {profileData.displayName} 
                      {profileData.alias_name? ' ('+profileData.alias_name+')': null}
                      {
                        (permiss_member_name && permiss_member_name.permission_edit) 
                          ? <IconEdit className="fas fa-pen" onClick={() => { this.props.toggleEditAliasNameModal(); this.setState({ alias_name: profileData.alias_name || '' })}}/> 
                          : null
                      }
                    </Title2>
              }
              <div style={flexStyle}>
                <div style = {{width:150}}>
                  <Title2 bold white className='pb-1'>
                    <Icon className="fas fa-user" />
                    {profileData.member_badge_code}
                  </Title2>
                  <Title2 bold white className='pb-1'>
                    <Icon className={icon.phone} />
                    {`${profileData.country_code}${profileData.mobile_number}`.trim()}
                    {profileData.block_noti ? <i className="fas fa-bell-slash pl-2" /> : null}
                  </Title2>

                </div>
                <div className='ml-1' >
                  <Title2 bold white className='pb-1 d-lg-inline' >
                    <Icon className={profileData.gender_icon} />
                    {profileData.gender}
                  </Title2>
                  <Title2 bold white className='pb-1 d-lg-inline ml-lg-5' style = {{width:90}} >
                    <Icon className='fas fa-birthday-cake' />
                    {moment(profileData.date_of_birth, 'DD-MM-YYYY').format("MM / YYYY")}
                  </Title2>
                  
                  {profileData.line_id && profileData.line_displayname && 
                    <LineDisplayName size='large'>
                      <Title2 bold white className='pb-1'>
                        <Icon className='fab fa-line' />
                        {ellipsis(profileData.line_displayname, 40)}
                      </Title2>
                    </LineDisplayName>
                  }
                  
                </div>
              </div>
              <div>
                { profileData.line_id && profileData.line_displayname && 
                  <LineDisplayName size='small'>
                    <Title2 bold white className='pb-1'>
                      <Icon className='fab fa-line' />
                      {ellipsis(profileData.line_displayname, 40)}
                    </Title2>
                  </LineDisplayName>
                }
              </div>
              <div>
                  { profileData.email &&
                    <Title2 bold white className='pb-1'>
                      <Icon className='fa fa-envelope' />
                      {profileData.email}
                    </Title2>
                  }
              </div>
              { profile_custom && profile_custom.length > 0 && form_setting?.length > 0 && !loading_value ?
                <div className='d-flex justify-content-between border-top pt-1'>
                  <div>
                    {profile_custom.map((data, key) => {
                      let formData = form_setting.find(form => form.id === data.form_id)
                      let title = 
                        locale === "en" && _.isEmpty(formData["en"].title)
                          ? formData["th"].title
                          : formData[locale].title;
                      if (key > 2 && !this.state.showMoreFormValue)
                        return null;

                      return (
                        <div key={key} className='d-flex flex-wrap'>
                          <Title2 white bold className='pl-1'>{`${title}:`}</Title2>
                          <Title2 white className='px-1 text-break'>{data.value || '-'}</Title2>
                        </div>
                      )
                    })}
                    {
                      (profile_custom.length > 3) &&
                      <Title3 blue link className='pl-1' onClick={this.toggleShowMoreFormValue}>
                        {this.state.showMoreFormValue ? dictionary.show_less : dictionary.show_more}
                        <i className={`pl-2 ${this.state.showMoreFormValue ? 'fas fa-angle-up' : 'fas fa-angle-down'}`} />
                      </Title3>
                    }
                  </div>
                  {
                    (permiss_member_name && permiss_member_name.permission_edit) &&
                    <Title2 
                      link 
                      style={{ color: '#007bff', height: '100%' }} 
                      onClick={this.toggleEditCustomFormValue}
                    >{dictionary.edit}</Title2>
                  }
                </div>
                : null
              }
            </div>
          </ProfileSection>
          <Labelcss
            style={{ maxHeight: '100px', overflowY: 'scroll', overflowX: 'hidden' }}>
            {
              (labelExist > 0)
              && profileData.member_label
                .sort((a, b) => a.tag_name.localeCompare(b.tag_name))
                .map((val) => {
                  return <Label
                    formLabel
                    hoverContent={<><i className="fas fa-pen pr-2" />{dictionary.edit}</>}
                    key={val.tag_id}
                    type={val.tag_type}
                    name={val.tag_name}
                    color={val.tag_style.tag_color}
                    pointer={(permiss_member_label && permiss_member_label.permission_edit)}
                    disabled={!(permiss_member_label && permiss_member_label.permission_edit)}
                    onClick={this.toggleSelectLabel}
                    style={{ margin: '5px 5px 5px 0px' }} />
                })
            }
            {
              (permiss_member_label && permiss_member_label.permission_create)
              && (member_label && member_label.length > 0)
              && ((labelExist) < limit_label)
              && <PrimaryBtn
                sm bold
                id='btn-add_label_member'
                style={{ fontSize: 10, marginRight: '5px', backgroundColor: '#FAFAFA', color: theme.primary, minWidth: (labelExist > 0) ? 0 : '' }}
                onClick={this.toggleSelectLabel}>
                {
                  labelExist > 0
                    ? <i className="fas fa-plus" />
                    : <><i className="fas fa-plus pr-1" />{dictionary.member_label}</>
                }
              </PrimaryBtn>
            }
          </Labelcss>
        </ProfileContainer>
        <PointsBox>
          <MemberPointsBalance {...this.props} member={profileData} />
          <ButtonSection>
                {
                  (permiss_member_send  && permiss_member_send.permission_create && !profileData.is_archive) 
                    ? <PrimaryBtn style = {{fontSize: 12, width: 115}}  onClick={() => {this.clickHistorySend(profileData.member_badge_code); window.toggleModalMember();}}><i className={icon.send_points} /> {dictionary.points_send_points}</PrimaryBtn>
                    : null
                }
               {
                  (permiss_member_pull  && permiss_member_pull.permission_create && !profileData.is_archive) 
                    ? <PrimaryBtn style = {{fontSize: 12, width: 115}}  onClick={() => {this.clickHistoryPull(profileData.member_badge_code); window.toggleModalMember();}}><i className={icon.pull_points} /> {dictionary.points_pull_points}</PrimaryBtn>
                    : null
                }
          </ButtonSection>
        </PointsBox> 
      </SearchResultContainer>
      <Modal size="md" centered fade={false} isOpen={this.props.store_members.toggleEditAliasNameModal} toggle={this.props.toggleEditAliasNameModal} >
        <ModalBody className='border-bottom'>
          <div className='w-100 d-flex align-items-center justify-content-between'>
            <Title2 bold>{dictionary.update_alias_name}</Title2>
            <Title2 bold link onClick={this.props.toggleEditAliasNameModal}><i className='fas fa-times' /></Title2>
          </div>
        </ModalBody>
        <ModalBody style={{ backgroundColor: '#7d889a11' }}>
            <div className='w-100'>
              <div className='w-100 pb-2 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-center'>
                  <Title1 bold>{dictionary.alias_name}</Title1>
                </div>
              </div>
              <Input
                type='text'
                className='w-100'
                name='alias_name'
                value={alias_name}
                onChange={this.onChangeInput}
                maxLength='100'
                placeholder={dictionary.alias_name} />
            </div>
        </ModalBody>
        <ModalFooter>
          <PrimaryBtn id='btn-save-alias-name' bold 
            disabled={isUpdatingAliasName || (this_profile && !this_profile.alias_name && !alias_name.length)}
            onClick={this.editAliasName}>
              {
                isUpdatingAliasName ? <i className="fas fa-spinner fa-pulse" /> : dictionary.save
              }
          </PrimaryBtn>
        </ModalFooter>
      </Modal>
      { this.state.isOpenEditCustomValue &&
      <Modal size="md" centered fade={false} isOpen={this.state.isOpenEditCustomValue} >
        <ModalBody>
          <Title2 bold>{dictionary.edit_info_additional_register}</Title2>
        </ModalBody>
        <ModalBody className='py-0'>
            {
              form_setting && form_setting.length > 0 && form_setting.map((data, index) => {
                let title = 
                  locale === "en" && _.isEmpty(data["en"].title)
                    ? data["th"].title
                    : data[locale].title;
                if (_.isUndefined(this.state[`customForm_${index+1}`]))
                  return null;
                return (
                  <div className='w-100' key={index}>
                    <div className='w-100 pt-2 d-flex align-items-end justify-content-between'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Title2 bold danger={data.is_required} white={!data.is_required} className='pr-2'>*</Title2>
                        <Title2 bold>{title}</Title2>
                      </div>
                      {
                        data.type !== "select" &&
                        <Title3 style={{ color: '#A7A7B5' }}>{`${this.state[`customForm_${index+1}`]?.length || 0}/${data.input_limit}`}</Title3>
                      }
                    </div>
                    {data.type === "select" ? (
                      <Input
                        type="select"
                        id='custom_data'
                        name={`customForm_${index+1}`}
                        className="form-control-xs form"
                        value={this.state[`customForm_${index+1}`]}
                        onChange={this.onChangeInput}
                      >
                        <option
                          value={""}
                          disabled
                        >{`-- ${dictionary.choose} --`}</option>
                        {data.options_list.map((op, key) => {
                          return (
                            <option key={key} value={op.title}>
                              {op.title}
                            </option>
                          );
                        })}
                      </Input>
                    ) : (
                      <Input
                        required
                        type={data.type}
                        id='custom_data'
                        name={`customForm_${index+1}`}
                        className="form-control-xs form"
                        value={this.state[`customForm_${index+1}`]}
                        onChange={this.onChangeInput}
                        maxLength={data.input_limit}
                        placeholder={data[locale].placeholder}
                        rows={4}
                        style={{ resize: "none" }}
                      />
                    )}
                  </div>
                )
              })
            }
        </ModalBody>
        <ModalFooter className='border-top-0'>
          <InvertBtn bold onClick={this.toggleEditCustomFormValue}>
            {dictionary.cancel}
          </InvertBtn>
          <SuccessBtn id='btn-save-alias-name' bold 
            disabled={!this.validateBtnCustomForm() || loading_update}
            onClick={this.updateCustomFormValue}>
              {
                loading_update ? <i className="fas fa-spinner fa-pulse" /> : 
                dictionary.save
              }
          </SuccessBtn>
        </ModalFooter>
      </Modal>
      }
      {
        this.state.isModalMemberLabel
        && <LabelSelectModal
          type='member'
          unselect
          limit_label={limit_label}
          isOpen={this.state.isModalMemberLabel}
          toggle={this.toggleSelectLabel}
          modalTitle={dictionary.member_label}
          selected={profileData.member_label || []}
          onChange={this.updateMemberLabel}
          isLoading={this.state.isLoadingLabelAction} />
      }
    </div>
  }
}

const flexStyle = {
  display: 'flex',
}

class MemberPointsBalance extends React.Component {
  render() {
    let { store_language: { dictionary } } = this.props
    let { member: { point_balance } } = this.props

    return <>
      <PointsTitle style={{fontSize: 36, height: 43}}>
        <AnimatedNumber
          value={point_balance}
          duration={700}
          stepPrecision={0}
          formatValue={n => toCurrency(n)} />
      </PointsTitle>
      <PointsSubTitle>{dictionary.points_total.toUpperCase()}</PointsSubTitle>
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_language: state.language,
    store_transactions: state.transactions,
    store_custom_form: state.custom_form
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getMemberProfileBasic,
    getTransactionsWithFilter,
    updateMemberLabel,
    toggleEditAliasNameModal,
    editAliasName,
    getCustomFormSetting,
    getCustomFormValue,
    editCustomFormValue,
    clearMemberFilter
  }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResult))
