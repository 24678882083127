import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { Title2, Title3 } from "../../components/Font";
import { csvToJson } from "../../libs/csv";
import { sumDeciNatural } from "../../libs/sumDeciNatural";
import { csvFileValidator } from "../../libs/validator";
import { importCronjobsFile } from "../../redux/actions/action_jobs";
import ModalTypeImport from "./ModalTypeImport";
import { toCurrency } from "../../libs/currency";
import { getPointKeys, getMemberCardKeys } from "./ImportColumn";
import { getProducts } from "../../redux/actions/action_product";
import { ERROR } from "../../libs/utils";

const MAX_LENGTH = process.env.REACT_APP_MAX_IMPORT_LENGTH;

function ImportCsvFileBtn({ type, customRoute }) {
	const [fileName, setFileName] = useState("");
	const [error, setError] = useState("");
	const [toggle, setToggle] = useState(false);
	const [pointType, setPointType] = useState("");
	const csvFile = useRef();
	const dispatch = useDispatch();
	const dictionary = useSelector((state) => state.language.dictionary);
	const feature = useSelector((state) => state.packages.packages.package_data.feature);
	const store_packages = useSelector((state) => state.packages);
	const subscriptionItems = _.get(store_packages,'packages.package_data.items_id', null)
	const points_conversion = useSelector((state) => state.business.current.points_conversion);
	const business_code = useSelector((state) => state.business.current.business_code);
	const business = useSelector((state) => state.business.current); 
	const { form_setting } = useSelector((state) => state.custom_form);
	let customForm = business && business.custom_form ? form_setting : [];
	const [productList, setProductList] = useState();

	useEffect(() => {
		dispatch(
			getProducts({ business_code, excludeArchive: true }, (err, product) => {
				setProductList(product);
			})
		);
	}, [business_code, dispatch]);

	const toggleModal = () => {
		setToggle(!toggle);
	};

	const onClickSelectPointType = (event, type) => {
		event.preventDefault();
		csvFile.current.value = "";
		csvFile.current.click();
		setPointType(type);
		toggleModal();
	};

	const onClickSelectFile = (event) => {
		event.preventDefault();
		csvFile.current.value = "";
		csvFile.current.click();
	};

	const onChangeSelectFile = (event) => {
		// let type = 'point';
		let { files } = event.target;

		if (!files.length) return;
		let file = files[0];

		dispatch(importCronjobsFile([]));
		setFileName(file.name);

		let fileSize = file.size / 1024 / 1024;
		if (fileSize > 5) {
			setError(dictionary.file_error_larger_size);
		} else {
			if (!csvFileValidator(file)) {
				setError(dictionary.file_error_wrong_type);
			} else {
				csvToJson(file)
					.then((data) => {
						if (!data.length) {
							setError(dictionary.file_error_empty);
						} else if (data.length > MAX_LENGTH) {
							setError(String.format(dictionary.file_error_data, toCurrency(MAX_LENGTH)));
						} else if (
							!Boolean(Object.keys(data[0]).find((elem) => elem.toUpperCase().includes("SKU"))) &&
							pointType === "product"
						) {
							setError(dictionary.file_error_import_point_type);
						} else {
							let preData =
								type === "point"
									? preparePointDataFormat(data, pointType, points_conversion, productList, customRoute, customForm)
									: prepareMemberCardDataFormat(data, customForm);
							dispatch(importCronjobsFile(preData));
							setError(false);
						}
					})
					.catch((err) => {
						ERROR("error", err);
						setError(dictionary.file_error);
					});
			}
		}
	};

	return (
		<div>
			<ShowFileError dictionary={dictionary} />
			<ImportBarInput className="d-flex justify-content-between align-items-center">
				<FileNameSection fileName={fileName} />
				{type === "point" ? (
					<ImportBarButton id="import-point-btn" onClick={toggleModal}>
						<Title3 white bold>
							{dictionary.browse_file}
						</Title3>
					</ImportBarButton>
				) : (
					<ImportBarButton id="import-membercard-btn" onClick={onClickSelectFile}>
						<Title3 white bold>
							{dictionary.browse_file}
						</Title3>
					</ImportBarButton>
				)}
			</ImportBarInput>
			<FileErrorSection error={error} />
			<input type="file" ref={csvFile} accept=".csv" className="d-none" onChange={onChangeSelectFile.bind(this)} />
			<ModalTypeImport
				subscription={feature}
				subscriptionItems={subscriptionItems}
				title={dictionary.choose_import_type}
				dictionary={dictionary}
				isOpen={toggle}
				toggle={toggleModal}
				onClick={(event, type) => onClickSelectPointType(event, type)}
			/>
		</div>
	);
}

const ShowFileError = ({ dictionary }) => {
	return (
		<div className="mt-3 mb-2">
			<Title2 className="ml-2 mb-1"> {`- ${dictionary.limit_file_size}`} </Title2>
			<Title2 className="ml-2 mb-1">
				{" "}
				{`- ${String.format(dictionary.limit_import_records, toCurrency(MAX_LENGTH))}`}{" "}
			</Title2>
		</div>
	);
};

const FileErrorSection = ({ error }) => {
	return (
		error && (
			<Title3 bold danger className="mt-3 mb-2 text-center">
				{" "}
				*** {error} ***
			</Title3>
		)
	);
};

const FileNameSection = ({ fileName }) => {
	return (
		<Title2 className="text-left" style={{ margin: "0px 20px 0px 20px", width: "50vw", maxWidth: "400px" }}>
			{fileName}
		</Title2>
	);
};

const preparePointDataFormat = (data, type, points_conversion, productList, customRoute, customForm) => {
	const keys = getPointKeys(type, customRoute, customForm);
	const info = [];
	const sameReciptNo = {};
	data.forEach((item, index) => {
		//console.log("data", _.omitBy(item, _.isEmpty));
		if (!_.isEmpty(_.omitBy(item, _.isEmpty))) {
			//remove empty row
			let temp = Object.values(item);
			let zipObj = _.zipObject(keys, temp);
			if (type === "product") {
				if (sameReciptNo[zipObj.receipt_no]) {
					sameReciptNo[zipObj.receipt_no].push({ sku: zipObj.sku, index });
				} else {
					sameReciptNo[zipObj.receipt_no] = [];
					sameReciptNo[zipObj.receipt_no].push({ sku: zipObj.sku, index });
				}
			}
			let zipdata =
				type === "amount"
					? Object.assign({}, zipObj, {
							amount: parseFloat(zipObj.amount) ? parseFloat(zipObj.amount).toFixed(2) : zipObj.amount,
							points:
								zipObj.amount && !isNaN(zipObj.amount)
									? sumDeciNatural(
											Math.floor(parseFloat(zipObj.amount) / parseInt(points_conversion[0])) *
												parseInt(points_conversion[1])
									  ).toString()
									: zipObj.amount,
					  })
					: type === "product"
					? calculateProductPoint(zipObj, productList, points_conversion, sameReciptNo, index)
					: zipObj;
			if (zipdata.gender) {
				zipdata.gender = zipdata.gender.toLowerCase();
			}
			info.push(zipdata);
			if (type === "product") {
				if (sameReciptNo[zipObj.receipt_no].length > 1) {
					let searchReceipt = info.filter((f) => {
						return f.receipt_no.toLowerCase().localeCompare(zipObj.receipt_no.trim().toLowerCase()) === 0;
					});
					let searchReceiver = searchReceipt.filter((f) => {
						return f.receiver.toLowerCase().localeCompare(zipObj.receiver.trim().toLowerCase()) === 0;
					});
					let sameProduct = sameReciptNo[zipObj.receipt_no].filter((f) => {
						return f.sku.toLowerCase().localeCompare(zipObj.sku.trim().toLowerCase()) === 0;
					});
					if (sameProduct.length > 1) {
						sameProduct.forEach((item) => {
							Object.assign(info[item.index], { sku_check: false });
						});
					}
					if (searchReceipt.length !== searchReceiver.length) {
						searchReceipt.forEach((item) => {
							Object.assign(info[item.index], { receipt_check: false });
						});
					}
				} else if (zipObj.receipt_no === "") {
					sameReciptNo[zipObj.receipt_no].forEach((item) => {
						Object.assign(info[item.index], { receipt_check: false });
					});
				} else if (zipObj.sku === "") {
					sameReciptNo[zipObj.receipt_no].forEach((item) => {
						Object.assign(info[item.index], { sku_check: false });
					});
				}
			}
		}
	});
	return Object.assign({}, { list: info, type: type });
};

const calculateProductPoint = (zipObj, productList, points_conversion, sameReciptNo, index) => {
	let productListWithSearch =
		productList.length > 0 && zipObj.sku.trim()
			? productList.filter((f) => {
					return f.product_code.toLowerCase().localeCompare(zipObj.sku.trim().toLowerCase()) === 0;
			  })
			: [];

	return Object.assign({}, zipObj, {
		amount: zipObj.amount,
		points:
			productListWithSearch.length > 0 && zipObj.amount
				? productListWithSearch[0].points_ratio
					? (
							(Math.floor(parseFloat(zipObj.amount)) / parseInt(productListWithSearch[0].points_ratio[0])) *
							parseInt(productListWithSearch[0].points_ratio[1])
					  ).toFixed(2)
					: (
							(Math.floor(parseFloat(zipObj.amount)) / parseInt(points_conversion[0])) *
							parseInt(points_conversion[1])
					  ).toFixed(2)
				: "0",
		sku_check: productListWithSearch.length > 0 ? true : false,
		type: "product",
		product_detail: productListWithSearch,
		index: index,
	});
};

const prepareMemberCardDataFormat = (data, customForm) => {
	const keys = getMemberCardKeys(customForm);
	const info = [];
	data.forEach((item) => {
		//console.log("data", _.omitBy(item, _.isEmpty));
		if (!_.isEmpty(_.omitBy(item, _.isEmpty))) {
			//remove empty row
			let temp = Object.values(item);
			let zipObj = _.zipObject(keys, temp);
			if (zipObj.gender) {
				zipObj.gender = zipObj.gender.toLowerCase();
			}
			info.push(zipObj);
		}
	});
	//console.log("prepareMemberCardDataFormat", data, keys, info);
	return Object.assign({}, { list: info });
};

const ImportBarInput = styled.div`
	width: 75vw;
	height: 35px;
	max-width: 600px;
	border-radius: 7px;
	background-color: #f4f4f4;
`;

const ImportBarButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 110px;
	height: 35px;
	color: #ffffff;
	cursor: pointer;
	padding: 5px 15px;
	border-radius: 0px 5px 5px 0px;
	border-color: #1e7e34;
	background-color: ${(props) => props.theme.green};
	&:hover {
		opacity: 0.7;
	}
`;

const mapStateToProps = (state) => {
	return {
		store_members: state.members,
		store_business: state.business,
		store_jobs: state.jobs,
		store_language: state.language,
		store_packages: state.packages,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators(
		{
			importCronjobsFile,
			getProducts,
		},
		dispatch
	);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportCsvFileBtn));
