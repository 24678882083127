import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SuccessBtn } from '../../components/Button'
import { updateCronjobStaus } from '../../redux/actions/action_jobs'
import CreditsUseModal from '../../components/CreditsUse'
import { toast } from 'react-toastify'
import { ToastBar } from '../../components/ToastComponent/Toast.style'
import { Title2 } from '../../components/Font'

class SendJobs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: false,
      credit: 0
    }
  }

  toggleUsedCredit = () => {
    this.setState({ toggle: !this.state.toggle })
  }

  setUsedCredit = (credit) => {
    this.setState({ credit: credit })
  }

  startJobs = (event) => {
    this.setState({ toggle: false })
    this.jobAction(event)
  }

  jobAction(event) {
    let { store_business: { current }, type } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_usage: { usage_record } } = this.props
    let overTx = usage_record.isOverTrans
    let { id, method } = event.currentTarget.dataset
    this.props.updateCronjobStaus(current.business_code, id, method, type, this.state.credit, overTx, (err) => {
      if (!err)
        this.props.getCronjobData()
      else {
        if (err.error && err.error.code === 'pointspot/credit-not-enough')
          this.toastShow('error', dictionary.credit_not_enough)
        else if (err.error && err.error.code === 'pointspot/transaction-not-enough')
          this.toastShow('error', dictionary.transaction_over_quota)
      }
    })
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  render() {
    let { status, id, amount, isProcess, store_language: { dictionary }, isLoadingGetOverTrans } = this.props
    let { store_usage: { usage_record } } = this.props
    let { store_packages: { subscription: { isNewPackage } } } = this.props;
    let importTx = isNewPackage 
      ? Math.abs(usage_record.tx_remain - amount)
      : amount;

    return (
      status === "stop" && (
        <div className="d-inline-block text-center">
          {(["stop", "pending"].indexOf(status) > -1 && isProcess) || 
          (isLoadingGetOverTrans && isNewPackage) ? (
            <SuccessBtn md bold>
              <i className="fas fa-spinner fa-pulse"></i>
            </SuccessBtn>
          ) : (
            <SuccessBtn
              md
              bold
              id={`btn-start_job`}
              data-id={id}
              data-method="pending"
              onClick={
                usage_record.isOverTrans || (isNewPackage && amount > usage_record.tx_remain)
                  ? this.toggleUsedCredit
                  : this.jobAction.bind(this)
              }
            >
              {dictionary.start_job}
            </SuccessBtn>
          )}
          <CreditsUseModal
            dataId={id}
            dataMethod="pending"
            isModal={true}
            isOpen={this.state.toggle}
            isImport={true}
            amount={importTx}
            toggleOpenCredit={this.toggleUsedCredit}
            confirmCreditsUse={this.startJobs.bind(this)}
            setCredit={this.setUsedCredit}
            title={dictionary.import_confirm}
            path={this.props.match.path}
          />
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs,
    store_usage: state.usage,
    store_packages: state.packages
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    updateCronjobStaus
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendJobs))