import moment from 'moment'
import { isValidPhoneNumber } from 'libphonenumber-js'


export const pointsValidator = ({ points }) => {
  return Boolean(points && points.trim().match(/^\d+$/g))
}
export const pointsDecimalValidator = ({ points }) => {
  return Boolean(points && points.trim().match(/^\d+(\.\d{1,2})?$/g))
}
export const amountValidator = (amount) => {
  return Boolean(amount && amount.trim().match(/^(0|[1-9]\d*)(\.\d{1,2})?$/))
}
export const receiverValidator = (receiver) => {
	return receiver && receiver.trim().length < 10
		? Boolean(receiver && receiver.trim().match(/^((?!(0))\d{6,9})$/g))
    : receiver.trim().length === 10 ? Boolean(receiver && receiver.trim().match(/^(0[16-9]\d{8})$/g))
		: verifyMobilePhone(receiver);
};
export const mobileValidator = (receiver) => {
	return receiver && receiver.trim().length === 10 
    ? Boolean(receiver && receiver.trim().match(/^(0[16-9]\d{8})$/g))
		: verifyMobilePhone(receiver);
};
export const csvFileValidator = ({ name }) => {
  return Boolean(name && name.match(/\.csv$/g))
}
export const verifyPhone = (phone) => {
  return Boolean(phone && phone.trim().match(/^(0[16-9]\d{8}|\+\d{11}|\+\d{10}|0[2-5]\d{7})$/g))
}
export const verifyMobilePhone = (phone) => {
  return isValidPhoneNumber(phone)
};
export const verifyNumber = (phone) => {
  return Boolean(phone && phone.trim().match(/^(\d[0-9]{3,10}?)$/g))
}
export const verifyEmail = (email) => {
  return Boolean(email && email.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g))
}
export const verifyIDcard = (ID) => {
  return Boolean(ID && ID.replace(/[\W\D\s_]+/g, '').match(/^(\d{13})$/g))
}
export const verifyZipcode = (code) => {
  return Boolean(code && code.trim().match(/^(\d{5})$/g))
}
export const verifyPremiumID = (id) => {
  return Boolean(id && id.trim().match(/^[A-Za-z0-9._-]{1,16}$/ig))
}
export const verifyLinkURL = (link) => {
  return Boolean(link && link.trim().match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi))
}
export const verifyProductSKU = (sku) => {
  return Boolean(sku && sku.trim().match(/^([a-zA-Z0-9_-]{1,20})$/g))
}
export const verifyProductName = (name) => {
  return Boolean(name && name.trim().match(/^(.{1,300})$/g))
}
export const verifyCouponCode = (code) => {
  return Boolean(code && code.trim().match(/^[A-Za-z0-9-]{1,32}$/ig))
}
export const verifyDateOfBirth = (dateOfBirth) => {
  return Boolean(dateOfBirth && dateOfBirth.trim().match(/^((0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-\d{4})$/g))
}

export const verifyAge = (dateOfBirth) => {
  var age = moment().diff(moment(dateOfBirth, "DD-MM-YYYY"), 'years')
  return 13 <= age && age <= 100
}

export const validateURLLink = (link) => {
  const invalidPatterns = [
    /^(https:\/\/)?lin\.ee\/.+/,
    /^(https:\/\/)?line\.me\/R\/ti\/p\/(@|%40).+/,
    /^(https:\/\/)?line\.me\/R\/nv\/recommendOA\/(@|%40).+/,
    /^(https:\/\/)?line\.me\/R\/home\/public\/(main|profile)\?id=.+/,
    /^(https:\/\/)?t\.me\/.+/
  ];
  return !invalidPatterns.some(pattern => link.match(pattern));
}

/**
 * To check that array contains the value.
 * @param {Array} array - Source Array
 * @param {*} value - value to check in array. 
 * @returns {Boolean}
 */
export const hasValueInArray = (array = [], value) => {
  return Array.isArray(array) && array.length && Boolean(array.indexOf(value) + 1)
}