import axios from './../axios_request';

import { businessAPI } from '../end_point'
import { ERROR } from '../../libs/utils'
import { MEMBER } from '../actions_const'
import { getCookie } from '../../libs/cookies';
const { GET_MEMBER_TOTAL, START_UPDATE_LABEL_MEMBER, UPDATE_LABEL_MEMBER, UPDATE_ALIAS_NAME } = MEMBER

global.memberRequest = global.memberRequest || {};

const clearGlobalObject = ({ member_badge_code }) => {
  if (member_badge_code)
    global.memberRequest = Object.assign({}, global.memberRequest[member_badge_code], {
      result: false,
      timestamp: new Date().getTime()
    })
  else
    global.memberRequest = {}
  return global.memberRequest
}

export const getTotalMember = (business_code, memberType, callback) => {
  let whereMemberType = !memberType ? '' : `?type=${memberType}`

  return dispatch => {
    axios({
      method: 'get',
      url: businessAPI + '/' + business_code + `/member/count${whereMemberType}`,
      headers: { Authorization: getCookie("_token") }

    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data)
      return dispatch({ type: GET_MEMBER_TOTAL, data: { type: memberType, total: (data && data.total) || 0 } })

    }).catch(err => {
      ERROR('get', memberType, 'Member Count axios catch', err)
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: GET_MEMBER_TOTAL, data: { type: memberType, total: 0 } })

    })
  }
}

export const getMemberProfileBasic = (business_code, member_badge_code, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    if (!Boolean(member_badge_code && member_badge_code !== '' && member_badge_code !== undefined)) {
      return isFunc && callback({
        code: 'pointspot/argument-error',
        message: 'Argument error.'
      })
    }

    member_badge_code = Number.parseInt(member_badge_code, 10)

    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/basic`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      isFunc && callback(null, data.userInfo)
      return dispatch({ type: MEMBER.GET_MEMBER_PROFILE, data: data.userInfo })

    }).catch(err => {
      ERROR('Catch getMemberProfileBasic', business_code, member_badge_code, err.message)
      isFunc && callback(err)
      return dispatch({ type: MEMBER.GET_MEMBER_PROFILE, data: {} })
    })

  }
}

export const getMemberCounterBirthday = (business_code, month = 1, callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/birthday?month=${month}`,
      headers: { Authorization: getCookie("_token") }

    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data.data)
      return dispatch({ type: GET_MEMBER_TOTAL, data: { type: 'month', total: (data.data && data.data.total_member) || 0 } })

    }).catch((err) => {
      ERROR('getMemberCounterBirthday catch', err.message)
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: GET_MEMBER_TOTAL, data: { type: 'month', total: 0 } })
    })
  }
}

export const getMemberBusinessCoupon = (business_code, member_badge_code, filter, limit, offset, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/coupons?filter=${filter}&limit=${limit}&offset=${offset}`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (!data.result)
        return isFunc && callback(!data.result)

      isFunc && callback(null, data.data)
      return dispatch({ type: MEMBER.GET_MEMBER_COUPON, data: data })

    }).catch((err) => {
      ERROR('getMemberBusinessCoupon catch', err.message)
      if (typeof callback === 'function')
        return callback(err)
    })
  }
}

export const cleartMemberBusinessCoupon = () => {
  return dispatch => {
    return dispatch({ type: MEMBER.CLEAR_MEMBER_COUPON })
  }
}

export const restoreMemberInfo = () => {
  return dispatch => {
    clearGlobalObject({})
    return dispatch({ type: MEMBER.RESOTRE_MEMBER_INFOMATION })
  }
}

export const clearMemberFilter = () => {
  return dispatch => {
    return dispatch({ type: MEMBER.CLEAR_MEMBER_FILTER })
  }
}

export const getPersonalMemberCard = ({ business_code, member_badge_code }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/card`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      isFunc && callback(null, data.data)
      return dispatch({ type: MEMBER.GET_PERSONAL_MEMBER_CARD_INFO, data: data.data, member_badge_code })

    }).catch((err) => {
      ERROR('getPersonalMemberCard', err.message)
      if (typeof callback === 'function')
        return callback(err)
    })
  }
}

export const createPersonalMemberCard = ({ business_code, member_badge_code, card_id, locale, overTx, credit }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/card`,
      headers: { Authorization: getCookie("_token") },
      data: { card_id, locale, overTx, credit }
    }).then(({ data }) => {
      if (!data.result && isFunc) return callback(data)
      clearGlobalObject({ member_badge_code })
      isFunc && callback(null, data.data)
      return dispatch({ type: MEMBER.CREATE_PERSONAL_MEMBER_CARD_INFO, data: data.data, member_badge_code })

    }).catch((err) => {
      ERROR('getPersonalMemberCard', err.message)
      if (typeof callback === 'function')
        return callback(err)
    })
  }
}

export const changePersonalMemberCard = ({ business_code, member_badge_code, trans_member_id, card_id, language, overTx, credit }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/card`,
      headers: { Authorization: getCookie("_token") },
      data: { trans_member_id, card_id, language, overTx, credit }
    }).then(({ data }) => {
      if (!data.result && isFunc) return callback(data)
      clearGlobalObject({ member_badge_code })
      isFunc && callback(null, data.data)
      return dispatch({ type: MEMBER.CREATE_PERSONAL_MEMBER_CARD_INFO, data: data.data, member_badge_code })

    }).catch((err) => {
      ERROR('getPersonalMemberCard', err.message)
      if (typeof callback === 'function')
        return callback(err)
    })
  }
}

export const deletePersonalMemberCard = ({ business_code, member_badge_code, card_id }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/card`,
      headers: { Authorization: getCookie("_token") },
      data: { card_id }
    }).then(({ data }) => {
      clearGlobalObject({ member_badge_code })
      isFunc && callback(null, data.data)
      return dispatch({ type: MEMBER.CREATE_PERSONAL_MEMBER_CARD_INFO, data: {}, member_badge_code })

    }).catch((err) => {
      ERROR('getPersonalMemberCard', err.message)
      if (typeof callback === 'function')
        return callback(err)
    })
  }
}

export const getMemberByMemberCard = ({ card_id = 'all', business_code }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/memberCard/${card_id}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result)
        isFunc && callback(null, data)
      else
        isFunc && callback(data.error || 'result error')
    }).catch((err) => {
      return isFunc && callback(err)
    })
  }
}

export const getMemberByLabel = ({ business_code, labels = [] }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/member/labels`,
      headers: { Authorization: getCookie("_token") },
      data: { labels: (labels && labels.length > 0) ? labels : '' }
    }).then(({ data }) => {
      if (data.result)
        return isFunc && callback(null, data.data)
      else
        return isFunc && callback(data.error || 'result error')
    }).catch((err) => {
      return isFunc && callback(err)
    })
  }
}

export const updateMemberLabel = ({ business_code, member_badge_code, member_label }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: START_UPDATE_LABEL_MEMBER })
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/label`,
      headers: { Authorization: getCookie("_token") },
      data: { member_label: member_label }
    }).then(({ data }) => {
      if (!data.result)
        return Promise.reject(data.error || new Error('result error'))

      isFunc && callback(null, data.data)
      return dispatch({ type: UPDATE_LABEL_MEMBER, data: data.data })
    }).catch((err) => {
      isFunc && callback(err)
      return dispatch({ type: UPDATE_LABEL_MEMBER })
    })
  }
}

export const toggleEditAliasNameModal = () => {
  return dispatch => {
    dispatch({ type: MEMBER.TOGGLE_EDIT_ALIAS_NAME_MODAL })
  }
}

export const editAliasName = ({ business_code, member_badge_code, name }, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/member/${member_badge_code}/name`,
      headers: { Authorization: getCookie("_token") },
      data: { alias_name: name }
    }).then(({ data }) => {
      if (typeof callback === 'function')
        callback(data.error, data)

      if (data.result) {
        return dispatch({ type: UPDATE_ALIAS_NAME, data: {member_badge_code, name } })
      }
    }).catch((error) => {
      if (typeof callback === 'function')
        callback(error || new Error('Create Business - Internal Error'));
    })
  }
}

export const sendMemberInformationToEmail = ({ business_code, member_label, member_card_info, start_date, end_date, send_to_email, language = 'th' }, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/member/exportMemberInformation`,
      headers: { Authorization: getCookie("_token") },
      data: {
        member_card_info,
        member_label,
        start_date,
        end_date,
        language,
        send_to_email}
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data)
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
    });
  }
}

export const resetFilterMember = (data) => {
  return dispatch => {
    return dispatch({ type: MEMBER.RESET_FILTER_MEMBER, data: data })
  }
};