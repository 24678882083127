import _ from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container } from "reactstrap"
import { useHistory } from 'react-router-dom'
import ReactPaginate from "react-paginate"

import { PrimaryBtn } from "../../../components/Button"
import CouponManual from "../../../components/CouponManual"
import { Title2, Title3 } from "../../../components/Font"
import { IconInfo } from "../../../components/CardHeaderFilter/CardHeaderFilter.style"
import Loading from "../../../components/Loading"
import { getCampaignList, setTrigger } from "../../../redux/actions/action_automation"
import { AddeCouponBar } from "../../Ecard/Ecoupon/eCoupon.style"
import CampaignEditor from "./CampaignEditor"
import Campaign from "./Campaign"
import { getMemberCardInfo } from "../../../redux/actions/action_business"
import ScrollToTop from "../../../components/ScrollToTop"

const this_menu = 'automation-ecoupon'

export default function AutomationEcardSetting({ business }) {
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state.language.dictionary);
  const campaign = useSelector((state) => state.automation.camapaign);
  const member_card_info = useSelector((state) => state.business.member_card_info);
  const permission = useSelector((state) => state.business.permission);
  const campaignRef = useRef();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [isDuplicate, setDuplicate] = useState(false);
  const { business_code } = business;
  const { total, list, offset, limit } = campaign;

  useEffect(() => {
    refreshCampaignList();
  }, [business_code]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isEmpty(member_card_info) && business_code) {
      dispatch(getMemberCardInfo(business_code));
    }
  }, [business_code]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshCampaignList = useCallback(() => {
    dispatch(getCampaignList(business_code, 0, (err) => {
      setLoading(false);
    }));
  }, [business_code]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleEditForm = () => {
    if (isEditing) {
      campaignRef.current = null;
      setEditing(false)
    }
    setEditing(!isEditing);
  }

  const toggleDuplicateForm = () => {
    if (isDuplicate) {
      campaignRef.current = null;
      setDuplicate(false);
    }
    setDuplicate(!isDuplicate);
  }

  const toggleCreateForm = () => {
    dispatch(setTrigger('daily'));

    if (isEditing) {
      campaignRef.current = null;
      setEditing(false);
    }
    if (isDuplicate) {
      campaignRef.current = null;
      setDuplicate(false);
    }

    setCreating(!isCreating);
  }

  const onCampaignChanged = () => {
    if (isEditing) {
      campaignRef.current = null;
      setEditing(false);
    }
    if (isDuplicate) {
      campaignRef.current = null;
      setDuplicate(false);
    }
      
    if (isCreating) {
      setCreating(false);
    }
    refreshCampaignList();
  }

  const onPageClick = (page) => {
    setLoading(true);
    dispatch(getCampaignList(business_code, (limit * page.selected), (err) => {
      setLoading(false);
    }));
  }

  const onEditClick = (campaign) => {
    campaignRef.current = campaign;
    setEditing(true);
  }

  const onDuplicateClick = (campaign) => {
    const dupCampaign = {...campaign, campaign_id: 0, title: `Copy ${campaign.title}`};
    
    if(campaign?.frequency) dispatch(setTrigger(campaign.frequency));

    dupCampaign.ecard_info = Object.assign({}, dupCampaign.ecard_info, {
      ecard_id: 0,
      image: dupCampaign.ecard_info.link_url,
      path_location: ""
    });
    dupCampaign.ecard_info.target_value = undefined
    campaignRef.current = dupCampaign;
    setDuplicate(true);
  }

  const goToManual = () => {
    window.open("https://manual.pointspot.co/automation/coupon", '_blank');
  };

  const gotoSentReport = () => {
    history.push("/sentReport");
  };

  return (<>
    <AddeCouponBar className="justify-content-between p-3">
      <div>
        <div className="d-flex align-items-baseline">
          <Title2 bold>{dictionary.ecoupon_automation_setting}</Title2>
          <IconInfo className="px-2 fas fa-info-circle" style={{ cursor: 'pointer' }} onClick={goToManual}></IconInfo>
        </div>
        <Title3 secondary>{dictionary.ecoupon_automation_setting_description}</Title3>
      </div>
      {
        (
          <PrimaryBtn onClick={permission[this_menu].permission_create ? toggleCreateForm : null} style={{opacity: permission[this_menu].permission_create ? 1 : 0.25, cursor: permission[this_menu].permission_create ? 'pointer' : 'not-allowed'}}>
            <Title2 white bold><i className='fas fa-plus pr-2' />{dictionary.ecoupon_automation_add_campaign}</Title2>
          </PrimaryBtn> 
        )
      }
    </AddeCouponBar>
    <Container>
      {
        permission[this_menu].permission_export && (
          <div className="my-4 d-flex justify-content-end">
            <Title3 bold link blue style={{ width: 'fit-content' }} onClick={gotoSentReport}>
              {dictionary.report_sent_coupon_history}
            </Title3>
          </div>
        )
      }
      {
        isLoading
          ? <Loading />
          : isCreating
          ? <CampaignEditor key="create-campaign" process="create" business={business} onChanged={onCampaignChanged} onCancel={toggleCreateForm} />
          : isDuplicate
          ? <CampaignEditor key="duplicate-campaign" process="duplicate" business={business} campaign={campaignRef.current} onChanged={onCampaignChanged} onCancel={toggleDuplicateForm} />
          : isEditing
          ? <CampaignEditor key="edit-campaign" process="edit" business={business} campaign={campaignRef.current} onChanged={onCampaignChanged} onCancel={toggleEditForm} />
          : _.isEmpty(list)
            ? <CouponManual type="automation_campaign" className="mb-4" />
            : (
              <>
                <ScrollToTop />
                {
                  list.map((item, index) => <Campaign disabled={!permission[this_menu].permission_create} key={item.campaign_id} data={item} onEditClick={onEditClick} onDuplicateClick={onDuplicateClick} memberCardInfo={member_card_info} refreshCampaign={refreshCampaignList} />)
                }
                {
                  total > limit && (
                    <ReactPaginate
                      pageCount={Math.ceil(total / limit)}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      activeClassName={"active"}
                      pageClassName={"page-items"}
                      containerClassName={"pagination justify-content-center my-3"}
                      forcePage={offset / limit}
                      subContainerClassName={"pages pagination"}
                      onPageChange={onPageClick}
                      nextLabel={<i className="fas fa-chevron-right" />}
                      previousLabel={<i className="fas fa-chevron-left" />}
                    />
                  )
                }
              </>
            )
      }
    </Container>
  </>)
}